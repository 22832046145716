import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import LayoutReducer, { ILayoutState } from './Reducers/Layout/Layout';
import sessionReducer, { ISessionState } from './Reducers/UserContext/Session';
import MsgGroupsReducer, { IMsgGroupsState } from './Reducers/MsgGroups/MsgGroups';
import msgGroupsSaga from './Reducers/MsgGroups/MsgGroupsSaga';
import explorationsSaga from './Reducers/Explorations/ExplorationsSaga';
import ExplorationsReducer, { IExplorationsState } from './Reducers/Explorations/Explorations';
import sessionSaga from './Reducers/UserContext/SessionSaga';

export interface IAppState {
  session: ISessionState;
  layout: ILayoutState;
  msgGroups: IMsgGroupsState;
  explorations: IExplorationsState;
}

const reducers = combineReducers({
  session: sessionReducer,
  layout: LayoutReducer,
  msgGroups: MsgGroupsReducer,
  explorations: ExplorationsReducer
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* rootSaga() {
  yield all([
    fork(sessionSaga),
    fork(msgGroupsSaga),
    fork(explorationsSaga)
  ]);
}

const sagaMiddleware = createSagaMiddleware();

const Store = configureStore({
  reducer: reducers,
  middleware: () => [ sagaMiddleware ]
});

sagaMiddleware.run(rootSaga);

export default Store;
