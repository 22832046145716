import BDText from '../BDText/BDText';

interface ErrorPanelProps {
  message?: string;
  className?: string;
}

export function ErrorPanelBase({ message, className }: ErrorPanelProps): JSX.Element {
  return (
    <div className={className}>
      <BDText textsize="lg" textbold>{message ?? 'UNEXPECTED ERROR OCCURED'}</BDText>
    </div>
  );
}
