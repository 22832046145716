import {
  Suspense,
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Route, Routes, matchPath, useLocation
} from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  IQuickPromptResponse, getLastMsgGroup, getUserLastMsgTime
} from './Chatbot.service';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../../Components/HOC/withProtectedPageWrapper';
import ChatbotForm from './ChatbotForm/ChatbotForm';
import ChatbotSidebar from './ChatbotSidebar/ChatbotSidebar';
import Loader from '../../../Components/Loader/Loader.style';
import ChatbotBookmarks from './ChatbotBookmarks/ChatbotBookmarks';
import ChatbotWelcome from './ChatbotWelcome/ChatbotWelcome.style';
import useSocketIOClient, { socketEventWrapper } from '../../../Hooks/useSocketIO';
import callApiWrapper from '../../../Utils/call-api-wrapper';
import useAction from '../../../Store/utils/useAction';
import { getMsgGroupsAction } from '../../../Store/Reducers/MsgGroups/MsgGroupsActions';

interface IChatbot extends IProtectedPageWrapper{
  className?: string;
}

function ChatbotBase({
  navigate,
  userContext,
  className
}: IChatbot): JSX.Element | null {
  // `didmount` is flag used to make sure useEffect(()=>{}, []) only called once
  const didmount = useRef(false);
  const location = useLocation();
  const getMsgGroups = useAction(getMsgGroupsAction);
  const [ loading, setLoading ] = useState(true);
  const redirectedToWelcomePage = useRef(false);
  const [ socket, socketConnected ] = useSocketIOClient(userContext);

  useEffect(() => {
    if (didmount.current) {
      return;
    }
    didmount.current = true;

    socket.on('quick-prompt', onQuickPromptResponse);

    return () => {
      if (!didmount.current) {
        return;
      }

      socket.off('quick-prompt', onQuickPromptResponse);
      socket.close();

      didmount.current = false;
    };
  }, [ ]);

  useEffect(() => {
    checkLastMsgTime();
  }, [ userContext.tenant ]);

  useEffect(() => {
    if (!didmount.current || socketConnected) {
      return;
    }

    socket.connect();
  }, [ didmount.current, socketConnected ]);

  const checkLastMsgTime = useCallback(callApiWrapper(async () => {
    const lastMsgTime = await getUserLastMsgTime();

    if (!matchPath('/', location.pathname) || redirectedToWelcomePage.current) {
      return;
    }

    const lastMessage = lastMsgTime && moment(lastMsgTime);
    const lastLogin = moment(userContext.createdAt);
    const isLoginToday = lastLogin.isSame(moment(), 'day');
    const isLastMsgToday = lastMessage && lastMessage.isSame(moment(), 'day');
    const isLastMessageBeforeLogin = lastMessage && lastMessage.isBefore(lastLogin);
    if ((isLoginToday && isLastMessageBeforeLogin) || (!isLoginToday && !isLastMsgToday)) {
      navigate('/');
    } else {
      const lastMsgGroup = await getLastMsgGroup();
      if (!lastMsgGroup) {
        return;
      }
      navigate(`/chatbot/${lastMsgGroup.id}`);
    }
    redirectedToWelcomePage.current = true;
  }, setLoading, navigate), [ location.pathname, userContext.tenant ]);

  const onSuggestionClick = useCallback((question: string) => {
    socket.emit('quick-prompt', {
      data: { question }
    });
  }, []);

  const onQuickPromptResponse = useCallback(socketEventWrapper<IQuickPromptResponse>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. Messages payload not found');
      return;
    }

    const { data: { msgGroup } } = data;
    getMsgGroups();
    navigate(`/chatbot/${msgGroup.id}`, { state: { quickPrompt: true } });
  }), []);

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className={className}>
      <ChatbotSidebar userContext={userContext} />

      <div className="d-flex-col-center-center w-100 h-100" style={{ overflow: 'hidden', position: 'relative' }}>
        <Suspense fallback={Loader}>
          <Routes>
            <Route
              key="chatbot"
              path="/chatbot/:msgGroupId"
              element={<ChatbotForm userContext={userContext} socket={socket} />}
            />
            <Route
              key="bookmarks"
              path="/bookmarks"
              element={<ChatbotBookmarks userContext={userContext} socket={socket} />}
            />
            <Route
              key="welcome"
              path="/*"
              element={<ChatbotWelcome userContext={userContext} onSuggestionClick={onSuggestionClick} />}
            />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default withProtectedPageWrapper(ChatbotBase);
