import { generateColor } from '../../../../Utils/color';

export interface ISunburstChartData {
  id: string;
  name: string;
  color: string;
  children?: ISunburstChartData[];
  value: number;
  childrenTotalValue: number;
  valueRef?: number;
}

const getParentOfChatbotDataToSunburstChartData = (root: ISunburstChartData, parents: string[]): ISunburstChartData | undefined => {
  const [ rootParentId, ...subParents ] = parents;
  if (root.name !== rootParentId) {
    return undefined;
  }

  const parent = subParents.reduce((currentParent, parentId, _, arr): any => {
    if (!currentParent.children) {
      arr.splice(0);
      return undefined;
    }
    const checkparent = (currentParent.children as ISunburstChartData[]).find((c) => c.name === parentId);
    if (!checkparent) {
      arr.splice(0);
      return undefined;
    }

    return checkparent;
  }, root) as ISunburstChartData | undefined;

  return parent;
};

const checkParentValueOfChatbotDataToSunburstChartData = (children: ISunburstChartData[]): void => {
  children.forEach((child) => {
    if (child.childrenTotalValue === child.value) {
      child.valueRef = undefined;
    } else {
      child.valueRef = child.value - child.childrenTotalValue;
    }

    if (child.children?.length) {
      checkParentValueOfChatbotDataToSunburstChartData(child.children);
    }
  });
};

export const chatbotDataToSunburstChartData = (rawData: Record<string, any>[]): [ISunburstChartData, Record<string, any>] => {
  const sortedData = rawData.sort((a, b) => a.level - b.level);
  const [ , ...levels ] = [ ...new Set(sortedData.map((d) => d.level)) ];
  const [ rawRoot, ...rawChildren ] = sortedData.sort((a, b) => a.level - b.level);
  const legend: Record<string, any> = { [rawRoot.event]: generateColor() };
  const root: ISunburstChartData = {
    id: rawRoot.id,
    name: rawRoot.event,
    color: legend[rawRoot.event],
    children: [],
    childrenTotalValue: 0,
    value: rawRoot.value
  };

  levels.forEach((level) => {
    const levelChildren = rawChildren.filter((d) => d.level === level);
    levelChildren.forEach((child) => {
      const parent = getParentOfChatbotDataToSunburstChartData(root, child.parent);
      if (!parent) {
        return;
      }
      if (!parent.children) {
        parent.children = [];
      }
      if (!legend[child.event]) {
        legend[child.event] = generateColor();
      }
      parent.childrenTotalValue += child.value;
      parent.children.push({
        id: child.id,
        name: child.event,
        color: legend[child.event],
        value: child.value,
        childrenTotalValue: 0,
        valueRef: child.value
      });
    });
  });

  if (root.children?.length) {
    checkParentValueOfChatbotDataToSunburstChartData(root.children);
  }

  return [ root, legend ];
};
