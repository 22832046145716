import { PropsWithChildren } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';
import { ThemeColors } from '../../Themes/Themes';

export interface IBDButton extends ButtonProps {
  textalign?: 'left' | 'center' | 'right';
  transparent?: true | string;
  className?: string;
}

const Container = styled(Button)<IBDButton>`
  display: flex;
  justify-content: ${(props) => (props.textalign === 'left' ? 'flex-start' : props.textalign === 'right' ? 'flex-end' : 'center')};
  align-items: center;
  position: relative !important;
  font-weight: 500;

  &.btn {
    border-radius: 10px;
  }

  &.btn-${(props) => props.variant ?? 'primary'} {
    ${(props) => {
    const variant = props.variant ?? 'primary';
    const themeColorId = variant.split('-')[variant.split('-').length - 1];
    const variantColor = props.theme.colors[themeColorId as ThemeColors] ?? undefined;
    if (!variantColor) {
      return;
    }

    if (variant.includes('outline')) {
      return `
        color: ${variantColor} !important;
        border-color: ${variantColor} !important;
        background-color: ${props.transparent ? 'transparent' : 'white'} !important;

        :hover {
          background-color: ${variantColor} !important;
          color: white !important;
        }
      `;
    }

    if (props.transparent) {
      return `
        background-color: transparent !important;
        border-color: transparent !important;
        color: ${props.variant ? variantColor : props.theme.colors.default} !important;

        :hover {
          background-color: ${props.theme.main.primaryBg}CC !important;
          border-color: ${props.theme.main.primaryBg} !important;
        }
      `;
    }

    if (variant === 'light') {
      return `
        background-color: ${variantColor} !important;
        border-color: ${variantColor} !important;
        color: ${props.theme.colors.primary} !important;

        :hover {
          background-color: ${props.theme.colors.primary}BB !important;
          border-color: ${props.theme.colors.primary} !important;
          color: white !important;
        }
      `;
    }

    return `
      background-color: ${variantColor} !important;
      border-color: ${variantColor} !important;
      color: ${variant === 'light' ? props.theme.colors.primary : 'white'} !important;

      :hover {
        background-color: ${variantColor}BB !important;
        border-color: ${variantColor} !important;
      }
    `;
  }}}
`;

function BDButton({ children, ...props }: PropsWithChildren<IBDButton>): JSX.Element {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default BDButton;
