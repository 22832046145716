import { ChartTypes } from '../../../Components/DataVisualization/DataVisualization.service';
import {
  callProtectedMainAPI,
  getMainEndpoint
} from '../../../Utils/call-api';
import { IExplore, IExplorePayload } from '../../../Utils/exploration';
import { IMetadataField } from '../../../Utils/metadata';
import { IDataExploration, IPrompt } from '../../Explore/Explore.service';

export interface IMessageGroup {
  id: string;
  user: string;
  name: string;
  isFavorite?: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  archived?: boolean;
}

export interface IInsight {
  insightId: string
  processId?: string
  text?: string;
  data: Record<string, any>[];
  visualization?: IMessageDataVizConfig;
}

export interface IMessageSuggestions {
  text: string;
  suggestions: string[];
}

export interface IMessagePopulated extends Omit<IMessage, 'messageGroup'> {
  messageGroup: Partial<IMessageGroup>;
}

export interface IMessage {
  _id?: string;
  id: string;
  messageGroup: string;
  owner: string;
  sender: string;
  userPromptMsgId?: string;
  message?: string;
  prompt?: IPrompt;
  variables?: Record<string, any>;
  insight?: IInsight;
  suggestions?: IMessageSuggestions;
  bookmarkId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IMessageDataVizConfig {
  type: ChartTypes;
  title?: string;
  xtitle?: string;
  xdatakey?: IMetadataField;
  xIsDate?: boolean;
  ytitle?: string;
  ydatakeys?: IMetadataField[];
  legend?: boolean;
  columns?: IMetadataField[];
  referencePoints?: Record<string, number[]>;
}

export interface IChatbotMessage extends Omit<IMessage, 'id'> {
  id?: string;
  tempId?: string;
  sending?: boolean;
}

export interface IBookmarkedMessage extends IMessage {
  messageGroupName: string;
  answers: IMessage[];
}

export interface IUserSummary {
  popularQuestions?: string[];
  lastBookmarks: IDataExploration[];
}

export interface IQuickPromptResponse {
  msgGroup: IMessageGroup;
  promptMsg: IMessage;
}

export interface ICreateEditModalState {
  show: boolean;
  isEdit?: boolean;
  values?: Record<string, any>
}

export const msgGroupFormFields: IMetadataField[] = [
  {
    id: 'id',
    label: 'ID',
    type: { value: 'ID' },
  },
  {
    id: 'name',
    label: 'Name',
    type: { value: 'STRING' },
    required: true
  }
];

export const getUserMessageGroups = async (): Promise<IMessageGroup[]> => callProtectedMainAPI(
  getMainEndpoint('/message-groups'),
);

export const createMessageGroup = async (payload: Record<string, any>): Promise<IMessageGroup> => callProtectedMainAPI(
  getMainEndpoint('/message-groups', 'POST'),
  { ...payload }
);

export const editMessageGroup = async (payload: Record<string, any>): Promise<IMessageGroup> => callProtectedMainAPI(
  getMainEndpoint('/message-groups', 'PATCH'),
  { ...payload }
);

export const deleteMessageGroup = async (groupId: string): Promise<IMessageGroup> => callProtectedMainAPI(
  getMainEndpoint(`/message-groups/${groupId}`, 'DELETE')
);

export const getUserLastMsgTime = async (): Promise<string> => callProtectedMainAPI(getMainEndpoint('/messages/last-msg-time'));

export const getPopularMsgs = async (): Promise<string[]> => callProtectedMainAPI(getMainEndpoint('/messages/popular'));

export const exploreBookmarks = async (payload: IExplorePayload): Promise<IExplore<IBookmarkedMessage>> => callProtectedMainAPI(
  getMainEndpoint('/messages/bookmarks/explore', 'POST'),
  payload
);

export const getLastMsgGroup = async (): Promise<IMessageGroup | null> => callProtectedMainAPI(getMainEndpoint('/message-groups/user/last'));
