import styled from 'styled-components';
import { LG_BREAKPOINT, MD_BREAKPOINT, SM_BREAKPOINT } from '../../Utils/common';
import AuthBase from './AuthBase';

const Auth = styled(AuthBase)`
  background-color: #080710;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-form-container {
    position: relative;
    width: 50%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255,0.13);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 1.6rem 2rem 0.4rem 2rem;
  
    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      width: 70%;
      max-width: none;
    }
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      width: 80%;
      max-width: none;
      padding: 30px 25px;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
      max-width: none;
      height: 100%;
      padding: 20px;
    }

    .auth-form-inputs {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;
    }

    * {
      font-family: 'Poppins',sans-serif;
      color: #ffffff;
      letter-spacing: 0.5px;
      outline: none;
      border: none;
    }

    h3 {
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
    }
    h4 {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    }

    label {
      display: block;
      font-size: 16px;
      font-weight: 700;

      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        font-size: 14px;
      }
    }

    input {
      display: block;
      width: 100%;
      background-color: rgba(255,255,255,0.07);
      border-radius: 3px;
      margin-top: 8px;
      font-weight: 300;
      padding: 0.6rem 1rem;
      font-size: 16px;
      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        padding: 0.4rem 0.8rem;
      }
    }
    input.tenant-form {
      margin: 0;
      padding: 0.4rem 0.8rem;
      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        padding: 0.4rem 0.8rem;
      }
    }
    input[type="file"] {
      height: auto;
    }

    .validation-error {
      font-weight: 500;
      font-size: small;
      color: #ff3636;
      height: 21px;
    }



    .submit-button {
      width: 100%;
      background-color: #ffffff;
      color: #080710;
      font-weight: 600;
      border-radius: 5px;
      cursor: pointer;
      font-size: 18px;
      padding: 15px 0;
      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        padding: 10px 0;
        font-size: 16px;
      }
      @media screen and (max-width: ${SM_BREAKPOINT}px) {
        padding: 8px 0;
        font-size: 16px;
      }
    }

    .auth-form-footer {
      margin-bottom: 1rem;
      a {
        font-weight: bold;
        text-decoration: none;
        span {
          color: lightblue;
          cursor: pointer;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .alternate-signin-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      button {
        color: #2c3e50;
        padding: 0.4rem 0.6rem;
        border-radius: 8px;;
        img {
          width: 20px;
          height: 20px;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .auth-form-container.tenant-form {
    width: 80%;
    max-width: 800px;
    padding: 32px;

    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      width: 90%;
      max-width: none;
    }
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      width: 100%;
      height: 100%;
      max-width: none;
    padding: 24px 32px;
    }
  }
`;

export default Auth;
