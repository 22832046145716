import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@mui/icons-material';
import Sidebar from '../../../Components/Sidebar/Sidebar.style';
import { SidebarBody } from '../../../Components/Sidebar/Sidebar';
import BDButton from '../../../Components/BDButton/BDButton';
import Icon from '../../../Components/Icon/Icon';
import OverflowContainer from '../../../Components/OverflowContainer/OverflowContainer';
import { IUserContext } from '../../../Types';

export interface ISettingsSidebar {
  userContext: IUserContext;
}

function SettingsSidebar({ userContext }: ISettingsSidebar): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Sidebar userContext={userContext} defaultFooter>
      <SidebarBody>
        <div className="d-flex-row-start-center w-100 my-3 mb-4">
          <BDButton
            className="d-flex-row-center-center ps-1 pe-0  me-2"
            variant="outline-primary"
            size="sm"
            onClick={() => navigate('/')}
          >
            <Icon icon={ArrowLeft} className="me-1" />
          </BDButton>
          Settings
        </div>

        <OverflowContainer containerClassName="p-0" className="px-3">
          <BDButton
            className="w-100 py-2"
            variant={matchPath(location.pathname, '/settings/users') ? 'primary' : 'secondary'}
            transparent
            size="sm"
            textalign="left"
            onClick={() => navigate('/settings/users')}
          >
            User Management
          </BDButton>
          <BDButton
            className="w-100 py-2"
            variant={matchPath(location.pathname, '/settings/roles') ? 'primary' : 'secondary'}
            transparent
            size="sm"
            textalign="left"
            onClick={() => navigate('/settings/roles')}
          >
            Roles
          </BDButton>
          <BDButton
            className="w-100 py-2"
            variant={matchPath(location.pathname, '/settings/billings') ? 'primary' : 'secondary'}
            transparent
            size="sm"
            textalign="left"
            onClick={() => navigate('/settings/billings')}
          >
            Billings
          </BDButton>
        </OverflowContainer>
      </SidebarBody>
    </Sidebar>
  );
}

export default SettingsSidebar;
