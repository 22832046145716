/* eslint-disable react/button-has-type */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Components/Loader/Loader.style';
import UserAvatar from '../../Components/UserAvatar/UserAvatar.style';
import { IUserContext } from '../../Types';
import { sizeBreakpointToValue } from '../../Utils/common';

interface ISlackVerifyForm {
  userContext: IUserContext;
  slackUserId: string;
  channel: string;
  loading: boolean;
  onSubmit: (values: Record<string, any>) => void;
}

function SlackVerifyButton({
  loading, userContext, slackUserId, channel, onSubmit,
}: ISlackVerifyForm): JSX.Element {
  return (
    <div className="auth-form-container p-5 text-white">
      <div className="d-flex flex-row justify-content-center align-items-center mb-4">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <UserAvatar user={userContext.user} size="xxl" className="mb-2" />
          {userContext.user.fullname}
        </div>
        <FontAwesomeIcon icon={faArrowRight} size="2x" className="mx-4" />
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            className="mb-2"
            style={{
              width: sizeBreakpointToValue('xxl'),
              height: sizeBreakpointToValue('xxl')
            }}
            src="/images/slack.png"
            alt="/images/slack.png"
          />
          Slack
        </div>
      </div>
      <button
        className="submit-button mt-3 mb-2"
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          onSubmit({
            slackUserId,
            channel,
            tenant: userContext.tenant.id,
            accessToken: userContext.accessToken
          });
        }}
      >
        Connect to Slack
      </button>

      {loading && <Loader />}
    </div>
  );
}

export default SlackVerifyButton;
