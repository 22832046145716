import { useEffect, useState } from 'react';
import { Delete, Edit, PersonAddAlt } from '@mui/icons-material';
import moment from 'moment';
import ErrorPanel from '../../../../Components/ErrorPanel/ErrorPanel.style';
import { checkCapability, getTenantRoles } from '../../../../Services/roles';
import {
  addUser, deleteUser, changeUserRole, getTenantUsers
} from '../../../../Services/users';
import { IAddUserPayload, IRole, IUser } from '../../../../Types';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import BDButton from '../../../../Components/BDButton/BDButton';
import BDSearchInput from '../../../../Components/BDTextInput/BDSearchInput';
import BDContainer from '../../../../Components/BDContainer/BDContainer';
import Icon from '../../../../Components/Icon/Icon';
import BDBadge from '../../../../Components/BDBadge/BDBadge';
import DataTable, { DataTableColumnRenderer, IDataTableColumn } from '../../../../Components/DataTable/DataTable';
import { IExplore } from '../../../../Utils/exploration';
import Loader from '../../../../Components/Loader/Loader.style';
import { swal } from '../../../../Utils/swal';
import BDHeader, { BDHeaderLeft, BDHeaderRight } from '../../../../Components/BDHeader/BDHeader';
import BDText from '../../../../Components/BDText/BDText';
import ResponsiveContainer, { IRCChildProps } from '../../../../Components/ResponsiveContainer/ResponsiveContainer';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../../../Components/HOC/withProtectedPageWrapper';
import CreateEditModal from '../../../../Components/BDForm/BDCreateEditModal/BDCreateEditModal';
import { IMetadataField } from '../../../../Utils/metadata';
import AddExistingUser from './AddExistingUser';

export type IUsers = IProtectedPageWrapper

interface IUserFormData extends IAddUserPayload {
  id?: string;
}

function Users({
  userContext, loading, setLoading, navigate
}: IUsers): JSX.Element {
  const { tenant, role } = userContext;
  const [ roles, setRoles ] = useState<IRole[]>([]);
  const [ exploration, setExploration ] = useState<IExplore<IUser>>({
    pagination: { page: 1, limit: 25, pageCount: 1 },
    query: {
      fullname: '',
      email: '',
    },
    data: []
  });
  const [ showCreateEditUserModal, setShowCreateEditModal ] = useState(false);
  const [ showAddExistingUserModal, setShowAddExistingUserModal ] = useState(false);
  const [ createEditModalData, setCreateEditModalData ] = useState<IUserFormData | null>(null);

  const isAdmin = checkCapability(role.capabilities, 'adminCenter', 'read');

  const columns: IDataTableColumn[] = [
    { id: 'fullname', label: 'Fullname' },
    { id: 'email', label: 'Email' },
    {
      id: 'role',
      label: 'Role',
      selector: (row) => row.roleName,
      renderer: ({ value }) => (
        <div style={{ display: 'inline-block' }}>
          <BDBadge className="d-flex-row-center-center" text={value} badgecolor="muted" />
        </div>
      ),
      align: 'center'
    },
    {
      id: 'lastLogin',
      label: 'Last login',
      formatter: (value) => moment(value).format('MMMM Do YYYY'),
      align: 'center'
    },
    {
      id: 'action',
      renderer: ({ row }: DataTableColumnRenderer) => (
        <div className="d-flex-row-end-center">
          <BDButton variant="warning" className="me-2" onClick={() => onEditUserClick(row as IUser)}>
            <Icon icon={Edit} />
          </BDButton>
          <BDButton
            variant="danger"
            onClick={() => onDeleteUserClick(row as IUser)}
          >
            <Icon icon={Delete} />
          </BDButton>
        </div>
      ),
      align: 'right'
    },
  ];

  const userFormFields: IMetadataField[] = [
    {
      id: 'id',
      label: 'ID',
      type: { value: 'ID' },
    },
    {
      id: 'fullname',
      label: 'Fullname',
      type: { value: 'STRING' },
      required: true,
      noneditable: 'hide'
    },
    {
      id: 'email',
      label: 'Email',
      type: { value: 'EMAIL' },
      required: true,
      noneditable: 'hide'
    },
    {
      id: 'role',
      label: 'Role',
      type: {
        value: 'ENUM',
        options: roles.map((r) => ({ id: r.id, label: r.name }))
      },
      required: true
    },
    {
      id: 'password',
      label: 'Password',
      type: { value: 'STRING' },
      required: true,
      noneditable: 'static'
    }
  ];

  useEffect(() => {
    if (!isAdmin) {
      return;
    }

    getUserList({
      pagination: { page: exploration.pagination.page, limit: exploration.pagination.limit },
      query: exploration.query
    });
  }, []);

  const getUserList = callApiWrapper(async (payload: IExplore<IUser>): Promise<void> => {
    const roleList = roles.length ? [ ...roles ] : await getTenantRoles(tenant.id);
    const { data, pagination } = await getTenantUsers(payload);
    setExploration({
      ...exploration,
      pagination,
      data
    });
    setRoles(roleList);
  }, setLoading, navigate);

  const submitAddUser = callApiWrapper(async (values: Record<string, any>): Promise<void> => {
    await addUser({ ...values });
    setShowCreateEditModal(false);
    getUserList({
      pagination: { page: exploration.pagination.page, limit: exploration.pagination.limit },
      query: exploration.query
    });
  }, setLoading, navigate, true);

  const submitEditUser = callApiWrapper(async (values: Record<string, any>): Promise<any> => {
    await changeUserRole({ user: values.id, role: values.role });
    setShowCreateEditModal(false);
    getUserList({
      pagination: { page: exploration.pagination.page, limit: exploration.pagination.limit },
      query: exploration.query
    });

    return values;
  }, setLoading, navigate, true);

  const submitDeleteUser = callApiWrapper(async (userId: string): Promise<void> => {
    await deleteUser(userId);
    getUserList({
      pagination: { page: exploration.pagination.page, limit: exploration.pagination.limit },
      query: exploration.query
    });
  }, setLoading, navigate, true);

  const onSearchSubmit = (value: string): void => {
    const currentExplore = {
      ...exploration,
      pagination: {
        ...exploration.pagination,
        page: 1,
      },
      query: {
        fullname: value,
        email: value,
      },
    };
    setExploration(currentExplore);
    getUserList({
      pagination: { page: currentExplore.pagination.page, limit: currentExplore.pagination.limit },
      query: currentExplore.query,
    });
  };

  const onAddUserClick = (): void => {
    setCreateEditModalData({
      fullname: '',
      email: '',
      role: roles[0].id,
      tenant: tenant.id,
      password: 'butterdata1!',
    });
    setShowCreateEditModal(true);
  };

  const onSubmitCreateEdit = (values: Record<string, any>): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: () => (values.id ? submitEditUser(values) : submitAddUser(values)),
      onCancelCallback: () => setShowCreateEditModal(false)
    });
  };

  const onDeleteUserClick = (userToDelete: IUser): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: () => submitDeleteUser(userToDelete.id),
      onCancelCallback: () => setShowCreateEditModal(false)
    });
  };

  const onEditUserClick = (userToEdit: IUser): void => {
    setCreateEditModalData(userToEdit as IUserFormData);
    setShowCreateEditModal(true);
  };

  const onPageChange = (page: number): void => {
    getUserList({
      pagination: { page, limit: exploration.pagination.limit },
      query: exploration.query
    });
  };

  if (!isAdmin) {
    return <ErrorPanel message="You dont have authority to access this page" />;
  }

  return (
    <>
      <BDContainer>
        {loading && <Loader overlay="#FFFFFF99" />}

        <BDHeader withpadding>
          <BDHeaderLeft>
            <BDText textbold textsize="md">User Management</BDText>
          </BDHeaderLeft>
          <BDHeaderRight>
            <BDButton
              className="me-2"
              disabled={loading}
              variant="primary"
              onClick={onAddUserClick}
            >
              <Icon icon={PersonAddAlt} className="me-2" />
              {' '}
              Create new user
            </BDButton>
            <BDButton
              disabled={loading}
              variant="success"
              onClick={() => setShowAddExistingUserModal(true)}
            >
              <Icon icon={PersonAddAlt} className="me-2" />
              {' '}
              Add existing user
            </BDButton>
          </BDHeaderRight>
        </BDHeader>

        <BDContainer withpaddingx withpaddingy>
          <BDSearchInput
            className="mb-4"
            noborder
            fullwidth
            submitsearch={onSearchSubmit}
            clearBtn
            submitOnClear
            disabled={loading}
          />

          <ResponsiveContainer>
            {({ width, height }) => (
              <DataTable
                width={width}
                height={height}
                columns={columns}
                // data={explore.data.map((u) => ({ ...u, roleName: roles.find((r) => r.id === u.role)?.name }))}
                data={exploration.data}
                pagination={exploration.pagination}
                onPageChange={onPageChange}
              />
            )}
          </ResponsiveContainer>
        </BDContainer>
      </BDContainer>

      <CreateEditModal
        active={showCreateEditUserModal}
        loading={loading}
        onClose={() => setShowCreateEditModal(false)}
        title={`${createEditModalData?.id ? 'Edit' : 'Add new'} user`}
        fields={userFormFields}
        initialValues={{ ...createEditModalData } ?? {}}
        isEditing={!!createEditModalData?.id}
        onSubmit={onSubmitCreateEdit}
      />

      <AddExistingUser
        userContext={userContext}
        tenantUsers={exploration.data}
        roles={roles}
        active={showAddExistingUserModal}
        onClose={() => setShowAddExistingUserModal(false)}
        onSubmitSuccess={() => getUserList({
          pagination: {
            page: 1,
            limit: exploration.pagination.limit
          },
          query: exploration.query
        })}
      />
    </>
  );
}

export default withProtectedPageWrapper(Users);
