/* eslint-disable react/button-has-type */
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import * as yup from 'yup';
import validateFormValues from '../../Utils/validate-schema';
import Loader from '../../Components/Loader/Loader.style';

const setPasswordValidationSchema = yup.object({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmationNewPassword: yup.string().required(),
});

interface ISetPassword {
  loading: boolean;
  onSubmit: (values: Record<string, any>) => void;
}

function SetPassword({ loading, onSubmit }: ISetPassword): JSX.Element {
  return (
    <Formik
      initialValues={{}}
      validate={validateFormValues(setPasswordValidationSchema)}
      onSubmit={onSubmit}
    >
      <Form className="auth-form-container">
        <h3>Set Password</h3>

        <div className="auth-form-inputs">
          <label className="mt-3">Old Password</label>
          <Field type="password" placeholder="Old Password" name="oldPassword" />
          <label className="validation-error mt-0"><ErrorMessage name="oldPassword" /></label>

          <label className="mt-3">New Password</label>
          <Field type="password" placeholder="New Password" name="newPassword" />
          <label className="validation-error mt-0"><ErrorMessage name="newPassword" /></label>

          <label className="mt-3">Confirm Password</label>
          <Field type="password" placeholder="Confirm Password" name="confirmationNewPassword" />
          <label className="validation-error mt-0"><ErrorMessage name="confirmationNewPassword" /></label>
        </div>

        <button className="submit-button mt-3 mb-4" type="submit">Submit</button>

        {loading && <Loader />}
      </Form>
    </Formik>
  );
}

export default SetPassword;
