import styled from 'styled-components';
import { ErrorPanelBase } from './ErrorPanel';

const ErrorPanel = styled(ErrorPanelBase)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-weight: 700;
`;
ErrorPanel.displayName = 'ErrorPanel';

export default ErrorPanel;
