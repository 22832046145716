import { Field } from 'formik';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';
import classNames from 'classnames';
import BDText from '../../BDText/BDText';

export interface IBDFormSelect {
  name: string;
  label?: string;
  value?: any;
  options: { id: string; label: string}[];
  required?: boolean;
  infoTooltip?: { icon: any; text: string};
  error?: string;
  hideError?: boolean;
  disabled?: boolean;
  selectClassName?: string;
  widthAuto?: boolean;
  className?: string;
}

function BDFormSelectBase({
  name,
  label,
  value,
  options,
  required,
  infoTooltip,
  error,
  hideError,
  disabled,
  selectClassName,
  className
}: IBDFormSelect): JSX.Element {
  return (
    <div className={classNames(className, 'mb-1 me-2')}>
      <div className="bdselect-label-container">
        <BDText textsemibold>{label}</BDText>
        {required && (
          <span className="ms-1 text-danger">*</span>
        )}
        {infoTooltip && (
          <Tooltip title={infoTooltip.text} className="ms-2">
            <IconButton>
              <infoTooltip.icon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Field className={classNames('form-control', selectClassName)} as="select" type="text" name={name} value={value} disabled={disabled}>
        {options.map((o) => (
          <option key={o.id} value={o.id}>{o.label}</option>
        ))}
      </Field>
      {!hideError && <BDText textcolor="danger">{error ?? ' '}</BDText>}
    </div>
  );
}

const BDFormSelect = styled(BDFormSelectBase)<IBDFormSelect>`
  ${(props) => (props.widthAuto ? '' : 'width: 100%;')}

  .bdselect-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.4rem 0;
    font-weight: normal;
  }

  select {
    width: 100%;
    min-width: 120px;
    padding: 0.6rem 1rem 0.6rem 1rem;

    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.colors.muted}EE;
    outline: none;
    box-shadow: 0 0 0 ${(props) => props.theme.colors.primary}EE;
    transition: all ease 0.15s;

    :focus {
      border-color: ${(props) => props.theme.colors.primary}EE;
      box-shadow: 0 0 1px 4px ${(props) => props.theme.colors.primary}99;
    }
  }
`;

export default BDFormSelect;
