import moment from 'moment';

export enum TimePresetEnum {
  'CURRENT_WEEk'='__CURRENT_WEEk__',
  'CURRENT_MONTH'='__CURRENT_MONTH__',
  'CURRENT_QUARTER'='__CURRENT_QUARTER__',
  'CURRENT_YEAR'='__CURRENT_YEAR__',
  'PREVIOUS_WEEK'='__PREVIOUS_WEEK__',
  'PREVIOUS_MONTH'='__PREVIOUS_MONTH__',
  'PREVIOUS_QUARTER'='__PREVIOUS_QUARTER__',
  'PREVIOUS_YEAR'='__PREVIOUS_YEAR__',
  'LAST_365_DAYS'='__LAST_365_DAYS__',
  'CUSTOM'='__CUSTOM__'
}

export const TimePresetOptions: { id: TimePresetEnum, label: string }[] = [
  { id: TimePresetEnum.CURRENT_WEEk, label: 'Current week' },
  { id: TimePresetEnum.CURRENT_MONTH, label: 'Current month' },
  { id: TimePresetEnum.CURRENT_QUARTER, label: 'Current quarter' },
  { id: TimePresetEnum.CURRENT_YEAR, label: 'Current year' },
  { id: TimePresetEnum.PREVIOUS_WEEK, label: 'Previous week' },
  { id: TimePresetEnum.PREVIOUS_MONTH, label: 'Previous month' },
  { id: TimePresetEnum.PREVIOUS_QUARTER, label: 'Previous quarter' },
  { id: TimePresetEnum.PREVIOUS_YEAR, label: 'Previous year' },
  { id: TimePresetEnum.LAST_365_DAYS, label: 'Last 365 days' },
  { id: TimePresetEnum.CUSTOM, label: 'Custom' },
];

export interface ITimePreset {
  tag: TimePresetEnum;
  start: string;
  end: string;
}

export const getTimePresetValueByTag = (tag: TimePresetEnum): ITimePreset => {
  let startDate;
  let endDate;
  const currentDate = moment();
  const currentMondayDate = currentDate.clone().startOf('week').add(1, 'day');

  switch (tag) {
    case '__CURRENT_WEEk__':
      startDate = currentMondayDate;
      endDate = currentDate.clone().startOf('isoWeek').add(6, 'days');
      break;
    case '__CURRENT_MONTH__':
      startDate = currentDate.clone().startOf('month');
      endDate = currentDate.clone().endOf('month');
      break;
    case '__CURRENT_QUARTER__':
      startDate = currentDate.clone().startOf('quarter');
      endDate = currentDate.clone().endOf('quarter');
      break;
    case '__CURRENT_YEAR__':
      startDate = currentDate.clone().startOf('year');
      endDate = currentDate.clone().endOf('year');
      break;
    case '__PREVIOUS_WEEK__':
      startDate = currentDate.clone().startOf('isoWeek').subtract(7, 'days');
      endDate = currentDate.clone().startOf('isoWeek').subtract(1, 'days');
      break;
    case '__PREVIOUS_MONTH__':
      startDate = currentDate.clone().subtract(1, 'months').startOf('month');
      endDate = currentDate.clone().subtract(1, 'months').endOf('month');
      break;
    case '__PREVIOUS_QUARTER__':
      // eslint-disable-next-line no-case-declarations
      const previousQuarterStartMonth = currentDate.clone().subtract(3, 'months').startOf('quarter').month();
      // eslint-disable-next-line no-case-declarations
      const previousQuarterEndMonth = previousQuarterStartMonth + 2;
      startDate = currentDate.clone().subtract(1, 'quarters').startOf('quarter').month(previousQuarterStartMonth);
      endDate = currentDate.clone().subtract(1, 'quarters').endOf('quarter').month(previousQuarterEndMonth);
      break;
    case '__PREVIOUS_YEAR__':
      startDate = currentDate.clone().subtract(1, 'year').startOf('year');
      endDate = currentDate.clone().subtract(1, 'year').endOf('year');
      break;
    case '__LAST_365_DAYS__':
      startDate = currentDate.clone().subtract(2, 'months').subtract(365, 'days');
      endDate = currentDate.clone().subtract(1, 'months').endOf('month');
      break;
    default:
      startDate = moment();
      endDate = moment();
      break;
  }

  return {
    tag,
    start: startDate.format('YYYY-MM-DD'),
    end: endDate.format('YYYY-MM-DD')
  };
};
