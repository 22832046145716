import { IUser } from '../../Types';
import {
  callMainAPI,
  callProtectedMainAPI,
  getAuthEndpoint,
  getSlackBotEndpoint,
  getMainEndpoint
} from '../../Utils/call-api';

export const signIn = async (payload: any): Promise<any> => callMainAPI(
  getAuthEndpoint('/login', 'POST'),
  payload,
);

export const slackVerify = async (payload: any): Promise<any> => callMainAPI(
  getSlackBotEndpoint('/verify', 'POST'),
  payload,
);

export const signUp = async (payload: any): Promise<any> => callMainAPI(
  getAuthEndpoint('/register', 'POST'),
  payload,
);

export const signInGoogle = async (payload: any): Promise<any> => callMainAPI(
  getAuthEndpoint('/login-google', 'POST'),
  payload
);

export const setPassword = async (payload: any): Promise<IUser> => callProtectedMainAPI(
  getMainEndpoint('/users/password', 'PATCH'),
  payload
);

export const registerTenant = (payload: Record<string, any>): Promise<any> => callMainAPI(
  getAuthEndpoint('/register/tenant', 'POST'),
  { ...payload },
);
