import { IMetadataField } from '../../Utils/metadata';
import ExploreController from './ExploreController';
import ExploreContent from './ExploreContent';
import { IInsight } from '../Home/Chatbot/Chatbot.service';

export interface IExploration {
  variableFields?: IMetadataField[];
  variables?: Record<string, any>;
  insights: IInsight[];
  insightLoadings: { [insightId: string]: boolean };
  onVariablesChange?(values: Record<string, any>): void;
  getInsights(values: Record<string, any>): void;
}

function Exploration({
  variableFields,
  variables,
  insights,
  insightLoadings,
  onVariablesChange,
  getInsights,
}: IExploration): JSX.Element {
  return (
    <>
      {variableFields?.length && variables ? (
        <ExploreController
          fields={variableFields}
          initialValues={variables}
          onSubmit={getInsights}
          onChange={onVariablesChange}
        />
      ) : null}

      {insights && (
        <ExploreContent
          insights={insights}
          insightLoadings={insightLoadings}
        />
      )}
    </>
  );
}

export default Exploration;
