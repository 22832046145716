import { SizeBreakpoints, IUser } from '../../Types';

export interface IUserAvatar {
  size: SizeBreakpoints | number;
  user: IUser;
  showUsername?: boolean;
  showEmail?: boolean;
  className?: string;
}

export const UserAvatarBase = ({
  user,
  showUsername,
  showEmail,
  className
}: IUserAvatar): JSX.Element => {
  const errorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = '/images/default-avatar.png';
  };

  // TODO
  // avatar src based on user

  return (
    <div className="d-flex flex-row align-items-center">
      <div className={`${className} rounded-circle me-2`}>
        <img
          className="rounded-circle border-0"
          src="/images/default-avatar.png"
          alt="/images/default-avatar.png"
          onError={errorHandler}
        />
      </div>
      {(showUsername || showEmail) && (
        <div className="d-flex flex-column justify-content-center align-items-start h-100">
          <div>{user.fullname.length < 14 ? user.fullname : `${user.fullname.substring(0, 14)}...`}</div>
          <div className="fw-normal" style={{ fontSize: '12px' }}>{user.email.length < 14 ? user.email : `${user.email.substring(0, 14)}...`}</div>
        </div>
      )}
    </div>
  );
};
