import { useSelector } from 'react-redux';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { selectUserContext, selectUserContextLoading } from '../../Store/Reducers/UserContext/SessionSelector';
import { IUserContext } from '../../Types';
import Loader from '../Loader/Loader.style';

export interface IProtectedPageWrapper {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  navigate: NavigateFunction;
  userContext: IUserContext;
}

function withProtectedPageWrapper<P extends IProtectedPageWrapper>(Component: React.ComponentType<P>) {
  return (props: Omit<P, 'userContext' | 'loading' | 'setLoading' | 'navigate'>) => {
    const [ loading, setLoading ] = useState(false);
    const userContext = useSelector(selectUserContext);
    const userContextLoading = useSelector(selectUserContextLoading);
    const navigate = useNavigate();

    if (!userContext) {
      return <Loader />;
    }

    return (
      <Component
        {...props as P}
        loading={loading || userContextLoading}
        setLoading={setLoading}
        navigate={navigate}
        userContext={userContext}
      />
    );
  };
}

export default withProtectedPageWrapper;
