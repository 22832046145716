import { ReactElement } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { IPagination } from '../../Utils/exploration';
import BDText from '../BDText/BDText';
import DataTablePagination from './DataTablePagination';

export interface DataTableColumnRenderer {
  id: string;
  value: any;
  row: Record<string, any>;
}

export interface IDataTableColumn {
  id: string;
  label?: string;
  type?: 'string' | 'number' | 'date';
  selector?(row: Record<string, any>): any;
  formatter?(value: any): any;
  renderer?: (props: DataTableColumnRenderer) => ReactElement<DataTableColumnRenderer>;
  align?: 'left' | 'center' | 'right';
}

export interface IDataTable {
  columns: IDataTableColumn[];
  data: Record<string, any>[];
  pagination?: IPagination;
  onPageChange?(page: number): void;
  width?: number;
  height?: number;
}

function DataTable({
  columns, data, pagination, onPageChange,
  width, height
}: IDataTable): JSX.Element {
  function onChangePageClick(page: number): void {
    if (!onPageChange || page === pagination?.page) {
      return;
    }

    onPageChange(page);
  }

  const rowCell = (id: string, value: any, row: Record<string, any>, c: IDataTableColumn): any => {
    const rawValue = c.selector ? c.selector(row) : value;

    if (c.renderer) {
      return c.renderer({ id, value: rawValue, row });
    }
    if (c.formatter) {
      return c.formatter(rawValue);
    }

    return rawValue;
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ width, height: height ? pagination ? height - 52 : height : undefined }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((c) => (
                <TableCell key={c.id} align={c.align ?? 'left'}>
                  <BDText textbold>{c.label}</BDText>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                {columns.map((c) => (
                  <TableCell key={c.id} align={c.align ?? 'left'}>
                    {rowCell(c.id, row[c.id], row, c)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && onPageChange && (
        <DataTablePagination
          {...pagination}
          onPageClick={onChangePageClick}
        />
      )}
    </>
  );
}

export default DataTable;
