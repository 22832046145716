import moment from 'moment';
import { IMetadataField, IMetadataFieldType } from '../../Utils/metadata';

const CommonCharts = [ 'line', 'bar' ] as const;
export type CommonChartTypes = typeof CommonCharts[number];
export type ChartTypes = 'sunburst' | CommonChartTypes;
export const isCommonChartType = (type: string): type is CommonChartTypes => CommonCharts.includes(type as CommonChartTypes);

export interface IDataVizConfig {
  type: ChartTypes;
  title?: string;
  // xtitle?: string;
  // xField?: IMetadataField,
  // ytitle?: string;
  // yFields?: IMetadataField[];
  legend?: boolean;
  referencePoints?: Record<string, number[]>;
}

export const DEFAULT_STROKE_COLORS = [
  '#A6CEE3',
  '#B2DF8A',
  '#D970BB',
  '#D9BC70',
  '#D770D9',
  '#707BD9',
  '#72D970',
  '#D97070',
  '#FFFFFF',
  '#FFFFFF',
];

export const getDefaultColor = (i: number): string => DEFAULT_STROKE_COLORS[i >= DEFAULT_STROKE_COLORS.length ? i - DEFAULT_STROKE_COLORS.length : i];

export const isDateFormat = (value: any): boolean => {
  try {
    if (!moment(value).isValid()) {
      return false;
    }

    return true;
  } catch (err) {
    return false;
  }
};

export const isAllFieldSameType = (fields: IMetadataField[]): IMetadataField | false => {
  if (!fields.length) {
    return false;
  }

  if (fields.length === 1) {
    return fields[0];
  }
  return fields.slice(0).reduce((acc: IMetadataField | undefined, curr, i, arr) => {
    if (!acc) {
      acc = curr;
      return acc;
    }

    if (curr.type.value !== acc.type.value) {
      acc = undefined;
      arr.splice(1); // escape from reduce loop
    }

    return acc;
  }, undefined) ?? false;
};

export const dateFormatter = (date: any): string => moment.utc(date).format('YYYY-M-D');
export const datetimeFormatter = (date: any): string => moment.utc(date).format('YYYY-M-D hh:mm:ss');
export const percentFormatter = (value: number): string => `${Math.round((value + Number.EPSILON) * 100) / 100}%`;

export const getFieldLabel = (value: string): string => {
  const splitted = value.split('_');
  if (splitted.length <= 1) {
    return value;
  }
  const type = splitted[splitted.length - 1].toUpperCase();

  if (type !== 'DATE' && type !== 'DATETIME' && type !== 'PERCENT') {
    return value;
  }

  return splitted.slice(0, splitted.length - 1).join('_');
};

export const getColumnType = (column: string): IMetadataFieldType => {
  const splitted = column.split('_');
  if (splitted.length <= 1) {
    return {
      value: 'NUMBER'
    };
  }
  const type = splitted[splitted.length - 1].toUpperCase();
  if (type === 'DATE' || type === 'DATETIME' || type === 'PERCENT') {
    return {
      value: type
    };
  }

  return {
    value: 'NUMBER'
  };
};

export const getFieldFormatter = (field: IMetadataField): ((value: any) => any) | undefined => {
  switch (field.type.value) {
    case 'DATE':
      return dateFormatter;
    case 'DATETIME':
      return datetimeFormatter;
    case 'PERCENT':
      return percentFormatter;
    default:
      return undefined;
  }
};
