import { Modal } from 'react-bootstrap';
import { generateFormFields } from '../BDForm.service';
import BDButton from '../../BDButton/BDButton';
import { IMetadataField } from '../../../Utils/metadata';
import BDForm, { IFormProps } from '../BDForm';
import BDText from '../../BDText/BDText';
import Loader from '../../Loader/Loader.style';

export interface ICreateEditModal {
  active: boolean;
  loading?: boolean;
  title: string;
  fields: IMetadataField[];
  initialValues?: Record<string, any>;
  isEditing?: boolean;
  onClose: () => void;
  onSubmit: (values: Record<string, any>) => void;
}

function CreateEditModal({
  active,
  loading,
  title,
  fields,
  initialValues,
  isEditing,
  onClose,
  onSubmit
}: ICreateEditModal): JSX.Element {
  const fieldElements = ({
    values, errors
  }: IFormProps): React.ReactNode => (
    <>
      {loading && <Loader className="rounded-3" overlay="#FFFFFF99" size={16} />}
      <Modal.Body>
        {generateFormFields({
          fields,
          values,
          errors,
          isEditing
        })}
      </Modal.Body>
      <Modal.Footer className="py-2">
        <BDButton variant="success" type="submit">
          Save
        </BDButton>
        <BDButton variant="secondary" onClick={onClose}>
          Cancel
        </BDButton>
      </Modal.Footer>
    </>
  );

  return (
    <Modal
      show={active}
      onHide={onClose}
      backdrop="static"
      centered
    >
      <Modal.Header className="py-2">
        <BDText textbold textsize="md">{title}</BDText>
      </Modal.Header>
      <BDForm
        title={title}
        fields={fields}
        isEditing={isEditing}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
      >
        {fieldElements}
      </BDForm>
    </Modal>
  );
}

export default CreateEditModal;
