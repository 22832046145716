import {
  CapabilityActions, CapabilityTypes, ICapabilities, IRole
} from '../Types';
import { callProtectedMainAPI, getMainEndpoint } from '../Utils/call-api';

export const checkCapability = (capabilities: ICapabilities, type: CapabilityTypes, action: CapabilityActions): boolean => {
  if (!capabilities[type]) {
    return false;
  }

  return capabilities[type][action];
};

export const getTenantRoles = async (tenantId: string): Promise<IRole[]> => {
  const endpoint = getMainEndpoint(`/roles/tenant/${tenantId}`, 'GET');
  const rolesResponse = await callProtectedMainAPI<IRole[]>(endpoint, { tenantId });
  return rolesResponse;
};
