/* eslint-disable react/destructuring-assignment */
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { toast } from 'react-toastify';
import { Socket } from 'socket.io-client';
import Spinner from '../../../../Components/Spinner/Spinner';
import { IUserContext } from '../../../../Types';
import { IExplore } from '../../../../Utils/exploration';
import { IBookmarkedMessage } from '../Chatbot.service';
import { socketEventWrapper } from '../../../../Hooks/useSocketIO';
import OverflowContainer from '../../../../Components/OverflowContainer/OverflowContainer';
import ChatbotContainer from '../ChatbotContainer/ChatbotContainer.style';
import BDSearchInput from '../../../../Components/BDTextInput/BDSearchInput';
import Separator from '../../../../Components/Separator/Separator';
import BDText from '../../../../Components/BDText/BDText';
import BookmarkedMessage from './BookmarkedMessage/BookmarkedMessage.style';

const PAGE_LIMIT = 10;

export interface IChatbotBookmarks {
  userContext: IUserContext;
  socket: Socket;
  className?: string
}

function ChatbotBookmarks({ socket }: IChatbotBookmarks): JSX.Element {
  const didmount = useRef(false);
  const [ loading, setLoading ] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [ lastContainerHeight, setLastContainerHeight ] = useState(0);

  // WITH PAGINATION
  // only use "exploration" for rendering, use explorationRef to access the value
  const [ exploration, _setExploration ] = useState<(IExplore<(IBookmarkedMessage)>)>({
    pagination: {
      page: 1,
      limit: PAGE_LIMIT,
      pageCount: 1
    },
    data: []
  });
  const explorationRef = useRef<IExplore<(IBookmarkedMessage)>>({ ...exploration });
  const setExploration = (newData: IExplore<(IBookmarkedMessage)>): void => {
    explorationRef.current = newData;
    _setExploration(explorationRef.current);
  };

  const onBookmarksResponse = useCallback(socketEventWrapper<IExplore<IBookmarkedMessage>>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. Messages payload not found');
      return;
    }

    const { data: explorationResult } = data;

    // if first page, must be no data, so overwrite all the state fields
    if (explorationResult?.pagination.page === 1) {
      setExploration(explorationResult);
      return;
    }

    // if not first page, insert new data from first element
    // backend query the messages in 'desc' order and reverse it then send it to UI (it become 'asc' ordered, because UI render it as 'asc' order)
    const newExploration: IExplore<IBookmarkedMessage> = { ...explorationResult };
    newExploration.data = [
      ...explorationRef.current.data,
      ...explorationResult.data,
    ];
    setExploration(newExploration);
  }, setLoading), []);

  const onUnbookmarkResponse = useCallback(socketEventWrapper<{userPromptMsgId: string; bookmark: boolean}>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. [unbookmark-message] response: Message payload not found');
      return;
    }

    socket.emit('get-bookmarked-messages', {
      data: {
        pagination: {
          page: 1,
          limit: PAGE_LIMIT
        },
      }
    });
  }), []);

  const onScroll = (): void => {
    if (!containerRef.current || loading) {
      return;
    }

    const { scrollTop } = containerRef.current;

    if (scrollTop >= lastContainerHeight && explorationRef.current.pagination.page < explorationRef.current.pagination.pageCount) {
      setLastContainerHeight(containerRef.current.scrollHeight);
      setLoading(true);
      socket.emit('get-bookmarked-messages', {
        data: {
          pagination: {
            page: explorationRef.current.pagination.page + 1,
            limit: PAGE_LIMIT
          }
        }
      });
    }
  };

  useEffect(() => {
    if (didmount.current) {
      return;
    }
    didmount.current = true;

    socket.on('get-bookmarked-messages', onBookmarksResponse);
    socket.on('bookmark-message', onUnbookmarkResponse);

    return () => {
      if (!didmount.current) {
        return;
      }

      socket.off('get-bookmarked-messages', onBookmarksResponse);
      socket.off('bookmark-message', onUnbookmarkResponse);

      didmount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!didmount.current) {
      return;
    }

    if (!socket.connected) {
      socket.connect();
      return;
    }

    socket.emit('get-bookmarked-messages', {
      data: {
        pagination: {
          page: 1,
          limit: PAGE_LIMIT
        },
      }
    });
  }, [ didmount.current, socket.connected ]);

  const onSearchSubmit = (value: string): void => {
    socket.emit('get-bookmarked-messages', {
      data: {
        exploration: {
          pagination: {
            page: 1,
            limit: PAGE_LIMIT
          },
          query: {
            text: value
          }
        }
      }
    });
  };

  const unbookmarkMsg = (userPromptMsgId: string): void => {
    socket.emit('bookmark-message', { data: { userPromptMsgId, bookmark: false } });
  };

  return (
    <ChatbotContainer>
      <div className="w-100 px-5 py-2">
        <BDSearchInput
          className="mt-3 mb-4"
          fullwidth
          submitsearch={onSearchSubmit}
          clearBtn
          submitOnClear
          disabled={loading}
        />
        <Separator className="pt-2 pb-3" />
        <BDText className="mb-2" textsize="md" textbold>Recent Saved Conversation</BDText>
      </div>

      <OverflowContainer ref={containerRef} className="px-5" onScroll={() => onScroll()}>
        {exploration.data.map((bookmarkedMessage) => (
          <BookmarkedMessage containerClassName="mb-3" msg={bookmarkedMessage} unbookmarkMsg={unbookmarkMsg} key={bookmarkedMessage.id} />
        ))}

        {loading && (
          <div className="align-self-center p-3">
            <Spinner />
          </div>
        )}
      </OverflowContainer>
    </ChatbotContainer>
  );
}

export default ChatbotBookmarks;
