import styled from 'styled-components';
import { IMetadataField } from '../../../Utils/metadata';
import { getFieldFormatter } from '../DataVisualization.service';

const Container = styled.div`
  background-color: #222f3e;
  padding: 0.6rem 0.8rem;
`;
const FieldContainer = styled.p`
  margin: 0;
  color: ${(props) => props.color ?? 'white'};
`;

interface ITooltipRenderer {
  active?: any;
  payload?: any;
  label?: string;
  labelFormatter?: (value: any) => any;
  fields: IMetadataField[];
}

const TooltipRenderer = ({
  active,
  payload,
  label,
  fields,
  labelFormatter
}: ITooltipRenderer): JSX.Element | null => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  return (
    <Container>
      <p className="mb-2 text-white">{labelFormatter ? labelFormatter(label) : label}</p>
      {payload.map((p: any) => {
        const field = fields.find((f) => f.id === p.name);
        if (!field) {
          return null;
        }
        const formatter = getFieldFormatter(field);

        return (
          <FieldContainer key={field.id} color={p.color}>
            {field.label}
            :
            {' '}
            {formatter ? formatter(p.value) : p.value}
          </FieldContainer>
        );
      })}
    </Container>
  );
};

export default TooltipRenderer;
