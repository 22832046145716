import styled from 'styled-components';
import BDText from '../../../BDText/BDText';
import OverflowContainer from '../../../OverflowContainer/OverflowContainer';

const Shape = styled.div<{bg: string}>`
  background-color: ${(props) => props.bg}AA;
  height: 20px;
  width: 20px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export default function SunburstChartLegend({ fields }: { fields: Record<string, string>}): JSX.Element {
  return (
    <OverflowContainer widthauto className="mt-3">
      {Object.keys(fields).map((f) => (
        <div key={f} className="d-flex-row-start-center mb-2">
          <Shape bg={fields[f]} />
          <BDText textsemibold textsize="xs" textcolor="secondary">{f}</BDText>
        </div>
      ))}
    </OverflowContainer>
  );
}
