import classNames from 'classnames';
import moment from 'moment';
import { Fragment, useState } from 'react';
import {
  Bookmark, ExpandLess, ExpandMore, Help
} from '@mui/icons-material';
import { IBookmarkedMessage } from '../../Chatbot.service';
import BDText from '../../../../../Components/BDText/BDText';
import Icon from '../../../../../Components/Icon/Icon';
import BDButton from '../../../../../Components/BDButton/BDButton';
import ChatbotDataVisualization from '../../ChatbotDataVisualization/ChatbotDataVisualization';

export interface IBookmarkedMessageComponentProps {
  msg: IBookmarkedMessage;
  unbookmarkMsg?(userPromptMsgId: string): void;
  unexpandable?: boolean | 'true';
  hideChartWhenCollapsed?: boolean;
  width?: number;
  collapsedHeight?: number;
  containerClassName?: string;
  className?: string;
}

function BookmarkedMessageBase({
  msg,
  unbookmarkMsg,
  unexpandable,
  hideChartWhenCollapsed,
  containerClassName,
  className
}: IBookmarkedMessageComponentProps): JSX.Element {
  const [ fullview, setFullview ] = useState(false);
  const question = msg.answers.find((m) => m.id === m.userPromptMsgId);
  const answers = msg.answers.filter((m) => m.id !== question?.id);

  const onShowMoreLessClick = (e: any): void => {
    e.preventDefault();
    setFullview(!fullview);
  };

  return (
    <div className={classNames(className, containerClassName, fullview ? 'fullview' : '', unexpandable || answers.length <= 1 ? 'unexpandable' : '')}>
      <BDText className="mb-2" textcolor="primary">{msg.messageGroupName}</BDText>
      <BDText className="mb-2" textcolor="secondary" textsize="xs" textlight>{moment.utc(msg.createdAt).local().format('MMMM DD YYYY').toString()}</BDText>
      <BDText className="mb-2" textcolor="success" textbold>
        <Icon icon={Help} className="me-2" />
        {question?.message}
      </BDText>

      {answers.length ? !fullview ? (
        <>
          {answers[0].message && <BDText>{answers[0].message}</BDText>}

          {!hideChartWhenCollapsed && answers[0].insight && answers[0].insight.visualization && (
            <ChatbotDataVisualization data={answers[0].insight.data} config={answers[0].insight.visualization} height={240} />
          )}
        </>
      ) : answers.map((answer, i) => (
        <Fragment key={i}>
          {answer.insight?.text && <BDText>{answer.insight?.text}</BDText>}

          {fullview && answer.insight && answer.insight.visualization && (
            <ChatbotDataVisualization data={answer.insight.data} config={answer.insight.visualization} height={240} />
          )}
        </Fragment>
      )) : null}

      {!unexpandable && answers.length > 1 && (
        <BDButton className="showmore-btn py-0 px-1 rounded-2" variant="success" size="sm" transparent onClick={onShowMoreLessClick} style={{ marginLeft: '-4px' }}>
          {fullview ? 'Show less' : 'Show more'}
          <Icon icon={fullview ? ExpandLess : ExpandMore} />
        </BDButton>
      )}

      {unbookmarkMsg && (
        <BDButton className="btn btn-link unbookmark-btn py-0 p-1" variant="primary" size="sm" transparent onClick={() => unbookmarkMsg(msg.userPromptMsgId as string)}>
          <Icon icon={Bookmark} />
        </BDButton>
      )}
    </div>
  );
}

export default BookmarkedMessageBase;
