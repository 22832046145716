import classNames from 'classnames';
import moment from 'moment';
import { Fragment, useState } from 'react';
import {
  ExpandLess,
  ExpandMore,
  Help
} from '@mui/icons-material';
import BDText from '../../../../../Components/BDText/BDText';
import Icon from '../../../../../Components/Icon/Icon';
import { IDataExploration } from '../../../../Explore/Explore.service';
import BDButton from '../../../../../Components/BDButton/BDButton';
import ChatbotDataVisualization from '../../ChatbotDataVisualization/ChatbotDataVisualization';

export interface ISavedInsightsCard {
  savedInsights: IDataExploration;
  unbookmarkMsg?(userPromptMsgId: string): void;
  unexpandable?: boolean | 'true';
  hideChartWhenCollapsed?: boolean;
  width?: number;
  collapsedHeight?: number;
  containerClassName?: string;
  className?: string;
}

function SavedInsightsCardBase({
  savedInsights,
  unexpandable,
  hideChartWhenCollapsed,
  containerClassName,
  className
}: ISavedInsightsCard): JSX.Element {
  const [ fullview, setFullview ] = useState(false);

  const onShowMoreLessClick = (e: any): void => {
    e.preventDefault();
    setFullview(!fullview);
  };

  return (
    <div className={classNames(className, containerClassName, fullview ? 'fullview' : '', unexpandable || savedInsights.insights.length <= 1 ? 'unexpandable' : '')}>
      {/* <BDText className="mb-2" textcolor="primary">{msg.messageGroupName}</BDText> */}
      <BDText className="mb-2" textcolor="secondary" textsize="xs" textlight>{moment.utc(savedInsights.createdAt).local().format('MMMM DD YYYY').toString()}</BDText>
      <BDText className="mb-2" textcolor="success" textbold>
        <Icon icon={Help} className="me-2" />
        {savedInsights.name}
      </BDText>

      {savedInsights.insights.length ? !fullview ? (
        <>
          {savedInsights.insights[0].text && <BDText>{savedInsights.insights[0].text}</BDText>}

          {!hideChartWhenCollapsed && savedInsights.insights[0] && savedInsights.insights[0].visualization && (
            <ChatbotDataVisualization data={savedInsights.insights[0].data} config={savedInsights.insights[0].visualization} height={240} />
          )}
        </>
      ) : savedInsights.insights.map((insight, i) => (
        <Fragment key={i}>
          {insight.text && <BDText>{insight.text}</BDText>}

          {fullview && insight.visualization && (
            <ChatbotDataVisualization data={insight.data} config={insight.visualization} height={240} />
          )}
        </Fragment>
      )) : null}

      {!unexpandable && savedInsights.insights.length > 1 && (
        <BDButton className="showmore-btn py-0 px-1 rounded-2" variant="success" size="sm" transparent onClick={onShowMoreLessClick} style={{ marginLeft: '-4px' }}>
          {fullview ? 'Show less' : 'Show more'}
          <Icon icon={fullview ? ExpandLess : ExpandMore} />
        </BDButton>
      )}
    </div>
  );
}

export default SavedInsightsCardBase;
