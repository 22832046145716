import { ThemeTypes } from '../../../Themes/Themes';
import { IReducerAction, IReducerActionWithoutPayload } from '../../../Types';
import ActionTypes from './LayoutActionTypes';

export function ChangeTheme(themeId: ThemeTypes): IReducerAction<{theme: string}> {
  return {
    type: ActionTypes.CHANGE_THEME,
    payload: { theme: themeId },
  };
}

export function ShowChatbotSidebar(): IReducerActionWithoutPayload {
  return {
    type: ActionTypes.SHOW_CHATBOT_SIDEBAR,
  };
}

export function HideChatbotSidebar(): IReducerActionWithoutPayload {
  return {
    type: ActionTypes.HIDE_CHATBOT_SIDEBAR,
  };
}

export function ShowChatbotSidebarToggler(): IReducerActionWithoutPayload {
  return {
    type: ActionTypes.SHOW_CHATBOT_SIDEBAR_TOGGLER,
  };
}

export function HideChatbotSidebarToggler(): IReducerActionWithoutPayload {
  return {
    type: ActionTypes.HIDE_CHATBOT_SIDEBAR_TOGGLER,
  };
}
