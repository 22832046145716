import styled from 'styled-components';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../../Utils/common';
import ChatbotMessageBase, { IChatbotMessageComponent } from './ChatbotMessage';

const ChatbotMessage = styled(ChatbotMessageBase)<IChatbotMessageComponent>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-size: 1rem;
  @media screen and (max-width: ${MD_BREAKPOINT}px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: ${SM_BREAKPOINT}px) {
    font-size: 0.8rem;
  }

  .msg-container {
    position: relative;

    background-color: ${(props) => props.theme.main.secondaryBg}99;
    ${(props) => (props.msg.sending ? 'color: gray;' : '')}
    ${(props) => (props.msg.insight ? 'width: 70%;' : '')}
    max-width: 70%;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 #00000027;
    word-wrap: break-word;

    padding: 0.6rem 0.8rem 0.6rem 0.8rem;

    .msg-body {
      .msg-insight {
        position: relative;
        
        .preview-mode {
          position: absolute;
          left: 24px;
          bottom: 24px;
        }
      }
    }

    .msg-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      ${(props) => (props.msg.insight ? 'padding-top: 0;' : 'padding-top: 0.8rem;')}
      color: ${(props) => props.theme.main.primaryColor}88;
      button {
        margin-left: -0.4rem;
        padding: 0 0.2rem 0 0;
        color: ${(props) => props.theme.main.primaryColor}88;
        border-radius: 4px;
      }
    }


    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      ${(props) => (props.msg.insight ? 'width: 80%;' : '')}
      max-width: 80%;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      ${(props) => (props.msg.insight ? 'width: 90%;' : '')}
      max-width: 90%;
    }
  }
  
  .bot-msg {
    align-self: flex-start !important;
  }

  .suggestions-container {
    background-color: none;
    color: #202020;

    .suggestions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      
      .suggestion-item {
        background-color: #b5e2b0;
        box-shadow: 0 0 4px 0 #00000057;
        font-size: 1rem;
        width: auto;

        cursor: pointer;

        :hover {
          box-shadow: 0 0 6px 0px #6f6f6f;
        }
      }
    }
  }

  .reactions-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .icon-wrapper {
      cursor: pointer;
      margin-right: 20px;

      .icon {
        margin-right: 5px;
      }
    }
  }
`;

export const ChatbotMessageSeparator = styled.div`
  align-self: center;
  margin: 12px 0 18px 0;
  font-size: 0.8rem;
`;

export default ChatbotMessage;
