import TenantForm from './TenantForm';

interface ICreateTenantPage{
  className?: string;
}

function CreateTenantPageBase({ className }: ICreateTenantPage): JSX.Element {
  return (
    <div className={className}>
      <TenantForm />
    </div>
  );
}

export default CreateTenantPageBase;
