import { Formik, FormikProps } from 'formik';
import { generateInitialValues, generateFormSchema } from './BDForm.service';
import FormContainer from './BDFormContainer';
import { IMetadataField } from '../../Utils/metadata';

export interface IBDForm {
  title?: string;
  fields: IMetadataField[];
  isEditing?: boolean;
  initialValues?: Record<string, any>;
  onChange?(values: Record<string, any>): void;
  onSubmit(values: Record<string, any>): void;
  onCancel?(): void;
  children: (formProps: IFormProps) => React.ReactNode;
  className?: string;
}

export type IFormProps = FormikProps<Record<string, any>>

function BDForm({
  onChange, className, children, ...props
} : IBDForm): JSX.Element {
  const {
    isEditing, fields, initialValues, onSubmit
  } = props;
  const defautValues = initialValues ?? generateInitialValues(fields);

  return (
    <Formik
      enableReinitialize
      initialValues={defautValues}
      validationSchema={generateFormSchema(fields, isEditing)}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <FormContainer
          {...props}
          {...formProps}
          onChange={onChange}
          className={className}
        >
          {children(formProps)}
        </FormContainer>
      )}
    </Formik>
  );
}

export default BDForm;
