import {
  HTMLProps, LegacyRef, RefObject, forwardRef
} from 'react';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import { Clear } from '@mui/icons-material';
import BDText from '../BDText/BDText';

export interface IBDTextInput extends HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
  required?: boolean;
  infoTooltip?: { icon: any; text: string};
  icon?: any;
  clearBtn?: boolean;
  onClear?(): void;
  inputClassName?: string;
  noborder?: boolean;
  fullwidth?: boolean;
}

const Container = styled.div<IBDTextInput>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: ${(props) => (props.fullwidth ? '100%' : '600px')};

  .bdtextinput-label-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.4rem 0;
    font-weight: normal;
  }

  .bdtextinput-input-container {
    position: relative;
    width: 100%;

    .bdtextinput-cosmetic-container {
      position: absolute;
      top: 0;
      height: 100%;
      width: 2.6rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bdtextinput-icon {
      left: 0;
    }

    .bdtextinput-clear-btn {
      right: 0;
      border-radius: 0 8px 8px 0;

      :hover {
        cursor: pointer;
        background-color: #00000033;
      }
    }

    input {
      width: 100%;
      min-width: 120px;
      padding: 0.6rem ${(props) => (props.clearBtn ? '2.4rem' : '1rem')} 0.6rem ${(props) => (props.icon ? '2.2rem' : '1rem')};

      border-radius: 8px;
      border: ${(props) => (props.noborder ? 'none' : `2px solid ${props.theme.colors.muted}EE`)};
      outline: none;
      box-shadow: 0 0 0 ${(props) => props.theme.colors.primary}EE;
      transition: all ease 0.15s;

      :focus {
        ${(props) => (props.noborder ? '' : `border-color: ${props.theme.colors.primary}EE`)};
        box-shadow: 0 0 1px 4px ${(props) => props.theme.colors.primary}99;
      }
    }
  }
`;
Container.displayName = 'BDTextInputContainer';

function BDTextInput({
  label,
  error,
  required,
  infoTooltip,
  icon: TextInputIcon,
  clearBtn,
  onClear,
  className,
  inputClassName,
  noborder,
  fullwidth,
  ...props
}: IBDTextInput, ref?: LegacyRef<HTMLInputElement>): JSX.Element {
  const onClearClick = (): void => {
    try {
      if (!ref) {
        return;
      }

      const currentRef = (ref as RefObject<HTMLInputElement>).current;
      if (!currentRef) {
        return;
      }

      if (currentRef.value !== '') {
        onClear?.();
      }
      currentRef.value = '';
    } catch (err) {
      // eslint-disable-next-line no-empty
    }
  };

  return (
    <Container
      className={className}
      label={label}
      icon={TextInputIcon}
      fullwidth={fullwidth}
      clearBtn={clearBtn}
      noborder={noborder}
    >
      {label && (
        <div className="bdtextinput-label-container">
          <BDText textsemibold>{label}</BDText>
          {required && (
            <span className="ms-1 text-danger">*</span>
          )}
          {infoTooltip && (
            <Tooltip title={infoTooltip.text} className="ms-2">
              <IconButton>
                <infoTooltip.icon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}

      <div className="bdtextinput-input-container">
        {TextInputIcon && (
          <div className="bdtextinput-cosmetic-container bdtextinput-icon">
            <TextInputIcon />
          </div>
        )}

        {clearBtn && (
          <div className="bdtextinput-cosmetic-container bdtextinput-clear-btn" onClick={onClearClick}>
            <Clear />
          </div>
        )}
        <input {...props} ref={ref} className={inputClassName} />
        {error && <BDText textcolor="danger" textsize="xs" style={{ whiteSpace: 'pre-wrap' }}>{error}</BDText>}
      </div>
    </Container>
  );
}

export default forwardRef(BDTextInput);
