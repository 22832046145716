import { Pagination } from '@mui/material';
import styled from 'styled-components';
import { IPagination } from '../../Utils/exploration';
import BDContainer from '../BDContainer/BDContainer';

export interface IDataTablePagination extends IPagination {
  onPageClick(page: number): void;
}

const Container = styled(BDContainer)`
  margin-top: 1px;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000023,0px 1px 3px 0px #0000001e;
`;

function DataTablePagination({ page, pageCount, onPageClick }: IDataTablePagination): JSX.Element {
  return (
    <Container className="px-2 py-2" heightauto direction="row" justify="end" align="center" style={{ backgroundColor: 'white' }}>
      <Pagination
        count={pageCount}
        defaultPage={page}
        siblingCount={2}
        size="small"
        onChange={(_, p) => onPageClick(p)}
      />
    </Container>
  );
}

export default DataTablePagination;
