import styled from 'styled-components';
import { EmptyPanelBase } from './EmptyPanel';

const EmptyPanel = styled(EmptyPanelBase)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-weight: 700;
`;
EmptyPanel.displayName = 'EmptyPanel';

export default EmptyPanel;
