import { ColorBreakpointNames } from '../Types';

export enum ThemeTypes {
  PRIMARY = 'PRIMARY',
  DARK ='DARK',
}

export interface IThemeSidebar {
  bg: string;
  color: string;
  img?: string;
}

export interface IThemeMain {
  primaryBg: string;
  primaryBg10: string;
  secondaryBg: string;
  invertBg: string;
  primaryColor: string;
  secondaryColor: string;
  invertColor: string;
}

export const ThemeColorNames = [ 'default', ...ColorBreakpointNames ] as const;
export type ThemeColors = typeof ThemeColorNames[number];

export type IThemeColors = Record<ThemeColors, string>

export interface ITheme {
  id: ThemeTypes;
  colors: IThemeColors;
  main: IThemeMain;
  sidebar: IThemeSidebar;
}

export const primaryTheme: ITheme = {
  id: ThemeTypes.PRIMARY,
  colors: {
    default: '#212529',
    primary: '#DFAD11',
    secondary: '#555555',
    success: '#17960C',
    danger: '#BD271E',
    warning: '#DAAB06',
    info: '#0984e3',
    light: '#FFFFFF',
    dark: '#1F1F1F',
    white: '#FFFFFF',
    muted: '#bbbbbb'
  },
  main: {
    primaryBg: '#E5E5E5',
    primaryBg10: '#FEFBF1',
    secondaryBg: '#FFFFFF',
    invertBg: '#1F1F1F',
    primaryColor: '#212529',
    secondaryColor: '#212529',
    invertColor: '#FFFFFF'
  },
  sidebar: {
    bg: '#FFFFFF',
    color: '#212529',
    img: '/images/butter-data-banner.png',
  }
};

export const darkTheme: ITheme = {
  id: ThemeTypes.DARK,
  colors: {
    default: '#F0C43B',
    primary: '#F0C43B',
    secondary: '#194B1D',
    success: '#17960C',
    danger: '#BD271E',
    warning: '#DAAB06',
    info: '#0984e3',
    light: '#1F1F1F',
    dark: '#FFFFFF',
    white: '#FFFFFF',
    muted: '#545454'
  },
  main: {
    primaryBg: '#333333',
    primaryBg10: '#FEFBF1',
    secondaryBg: '#333333',
    invertBg: '#E5E5E5',
    primaryColor: '#FFFFFF',
    secondaryColor: '#FFFFFF',
    invertColor: '#333333',
  },
  sidebar: {
    bg: '#222f3e',
    color: 'white',
    img: '/images/butter-data-banner.png',
  }
};

const Themes: Record<ThemeTypes, ITheme> = {
  [ThemeTypes.PRIMARY]: primaryTheme,
  [ThemeTypes.DARK]: darkTheme,
};

export const getTheme = (themeId: ThemeTypes): ITheme => Themes[themeId];

export default Themes;
