import styled from 'styled-components';
import { useMemo } from 'react';
import DataVisualization from '../../../../Components/DataVisualization/DataVisualization';
import { IMessageDataVizConfig } from '../Chatbot.service';
import { CHATBOT_PREVIEW_MODE_LIMIT, IChatbotDataVisualization } from './ChatbotDataVisualization';
import { chatbotDataToSunburstChartData } from './ChatbotSunburstChart.service';

export interface IChatbotSunburstChart extends IChatbotDataVisualization{
  data: Record<string, any>[];
  config: IMessageDataVizConfig;
  width?: number;
  height?: number;
  className?: string;
}

interface IContainerProps {
  width?: number;
  height?: number;
}

const Container = styled.div<IContainerProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '240px')};
  margin-bottom: 0.4rem;
`;

function ChatbotSunburstChart({
  data, config, previewMode, width, height
}: IChatbotSunburstChart): JSX.Element | null {
  const [ sunburstData, sunburstLegends ] = useMemo(() => {
    const rawData = previewMode ? data.slice(0, CHATBOT_PREVIEW_MODE_LIMIT) : data;
    return chatbotDataToSunburstChartData(rawData);
  }, [ data ]);

  return (
    <Container width={width} height={height && width && height < width ? 450 : height}>
      <DataVisualization
        data={[ sunburstData, sunburstLegends ]}
        config={config}
        height={height && width && height < width ? 450 : height}
      />
    </Container>
  );
}

export default ChatbotSunburstChart;
