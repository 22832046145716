import styled from 'styled-components';
import { SizeBreakpoints } from '../../Types';
import { ThemeColors } from '../../Themes/Themes';
import { invertColor } from '../../Utils/color';
import { TextSizeBreakpointValues } from '../BDText/BDText';

interface IBDBadge {
  text: string;
  badgecolor?: ThemeColors;
  badgesize?: SizeBreakpoints;
  className?: string;
}

const Container = styled.div<Omit<IBDBadge, 'text'>>`
  background-color: ${(props) => (props.badgecolor ? props.theme.colors[props.badgecolor as ThemeColors] : '#b2bec3')};
  color: ${(props) => (props.badgecolor ? invertColor(props.theme.colors[props.badgecolor as ThemeColors], true) : 'black')};
  font-size: ${(props) => (props.badgesize ? TextSizeBreakpointValues[props.badgesize] : TextSizeBreakpointValues.sm)};
  font-weight: 700;
  border-radius: ${(props) => (props.badgesize ? TextSizeBreakpointValues[props.badgesize] : TextSizeBreakpointValues.sm)}px;
  padding: 0.4rem 1rem;
`;

function ContentWrapper({
  text,
  badgecolor,
  badgesize,
  className
}: IBDBadge): JSX.Element {
  return (
    <Container className={className} badgecolor={badgecolor} badgesize={badgesize}>
      {text}
    </Container>
  );
}

export default ContentWrapper;
