import { createSlice } from '@reduxjs/toolkit';
import { ITenant, IUserContext } from '../../../Types';
import storageService from '../../../Utils/storage-service';

export interface ISessionState {
  userContext?: IUserContext | null;
  tenants: ITenant[];
  loading?: boolean;
  error?: string;
}

const initialState: ISessionState = {
  tenants: []
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession(state, action) {
      const storage = storageService('local');
      storage.setItem('session', JSON.stringify({ ...action.payload }));
      state.userContext = action.payload;
    },
    deleteSession(state) {
      const storage = storageService('local');
      storage.setItem('session', null);
      state.userContext = null;
    },
    setUserTenants(state, action) {
      state.tenants = action.payload;
    },
    setSessionLoading(state, action) {
      state.loading = action.payload;
    },
    setSessionError(state, action) {
      state.error = action.payload;
    }
  }
});

export const {
  setSession, deleteSession, setUserTenants, setSessionLoading, setSessionError
} = sessionSlice.actions;

export default sessionSlice.reducer;
