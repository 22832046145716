import Themes, { ITheme, getTheme } from '../../../Themes/Themes';
import { IReducerAction } from '../../../Types';
import LayoutActionTypes from './LayoutActionTypes';

export interface IChatbotSidebarState {
  toggler: boolean;
  hidden: boolean;
}

export interface ILayoutState {
  theme: ITheme,
  chatbotSidebar: IChatbotSidebarState;
}

const layoutDefaultState: ILayoutState = {
  theme: Themes.PRIMARY,
  chatbotSidebar: {
    toggler: false,
    hidden: true
  },
};

// eslint-disable-next-line default-param-last
const LayoutReducer = (state: ILayoutState = layoutDefaultState, action: IReducerAction<any>): any => {
  const newState = JSON.parse(JSON.stringify(state));

  if (action.type === LayoutActionTypes.CHANGE_THEME) {
    const theme = getTheme(action.payload?.themeId);
    if (theme) {
      newState.theme = theme;
    }
    return newState;
  }

  if (action.type === LayoutActionTypes.SHOW_CHATBOT_SIDEBAR) {
    newState.chatbotSidebar.hidden = false;
    return newState;
  }
  if (action.type === LayoutActionTypes.HIDE_CHATBOT_SIDEBAR) {
    newState.chatbotSidebar.hidden = true;
    return newState;
  }
  if (action.type === LayoutActionTypes.SHOW_CHATBOT_SIDEBAR_TOGGLER) {
    newState.chatbotSidebar.toggler = true;
    return newState;
  }
  if (action.type === LayoutActionTypes.HIDE_CHATBOT_SIDEBAR_TOGGLER) {
    newState.chatbotSidebar.toggler = false;
    return newState;
  }

  return state;
};

export default LayoutReducer;
