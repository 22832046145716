import { useEffect, useState } from 'react';

export enum SizeBreakpoints {
  XS,
  SM,
  MD,
  LG,
  XL,
}

export interface IScreenSize {
  width: number;
  height: number;
  breakpoint: SizeBreakpoints;
}

export interface IWithScreenSize {
  screenSize: IScreenSize;
}

export const getWidthThreshold = (width: number): SizeBreakpoints => (
  width < 576 ? SizeBreakpoints.XS
    : width < 768 ? SizeBreakpoints.SM
      : width < 992 ? SizeBreakpoints.MD
        : width < 1200 ? SizeBreakpoints.LG
          : SizeBreakpoints.XL
);

function withScreenSize<P extends IWithScreenSize>(Component: React.ComponentType<P>) {
  return (props: Omit<P, 'screenSize'>) => {
    const [ screenSize, setScreenSize ] = useState<IScreenSize>({
      width: window.innerWidth,
      height: window.innerHeight,
      breakpoint: getWidthThreshold(window.innerWidth)
    });

    const onResize = (): void => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getWidthThreshold(window.innerWidth)
      });
    };

    useEffect(() => {
      window.addEventListener('resize', onResize);
      return () => { window.removeEventListener('resize', onResize); };
    });

    return (
      <Component
        {...props as P}
        screenSize={screenSize}
      />
    );
  };
}

export default withScreenSize;
