/* eslint-disable arrow-body-style */
import { PulseLoader } from 'react-spinners';
import { LoaderSizeMarginProps } from 'react-spinners/helpers/props';
import { withTheme } from 'styled-components';
import { ITheme, ThemeColors } from '../../Themes/Themes';

export interface ISpinner extends LoaderSizeMarginProps {
  Component?: (props: any) => JSX.Element | null;
  theme: ITheme;
  color?: ThemeColors;
}

const Spinner = ({
  Component, theme, color, size, ...props
}: ISpinner): JSX.Element => {
  const SpinnerComponent = Component ?? PulseLoader;

  return (
    <SpinnerComponent
      {...props}
      color={color ? theme.colors[color as ThemeColors] ?? theme.colors.default : theme.colors.default}
      size={size ?? 14}
    />
  );
};

export default withTheme(Spinner);
