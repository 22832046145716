import { Field, FieldAttributes, FieldProps } from 'formik';

export interface IFormFieldProps extends FieldAttributes<any> {
  key?: string;
  label?: string;
  required?: boolean;
  error?: string;
  hideError?: boolean;
  onChange?(values: Record<string, any>): void;
}

function BDFormField({
  name, component: FieldComponent, ...props
}: IFormFieldProps): JSX.Element {
  return (
    <Field name={name}>
      {(p: FieldProps) => (
        <FieldComponent {...props} {...p} />
      )}
    </Field>
  );
}

export default BDFormField;
