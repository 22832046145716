import { IUser } from '../Types';
import { callProtectedMainAPI, getMainEndpoint } from '../Utils/call-api';
import { IExplore } from '../Utils/exploration';

export const getTenantUsers = async (payload: IExplore<IUser>): Promise<IExplore<IUser>> => {
  const endpoint = getMainEndpoint('/tenants/users/explore', 'POST');
  const response = await callProtectedMainAPI<IExplore<IUser>>(endpoint, payload);
  return response;
};

export const getAllUsers = async (): Promise<IUser[]> => {
  const endpoint = getMainEndpoint('/users');
  const response = await callProtectedMainAPI<IUser[]>(endpoint);
  return response;
};

export const addUser = async (payload: Record<string, any>): Promise<IUser> => {
  const endpoint = getMainEndpoint('/tenants/users', 'POST');
  const response = await callProtectedMainAPI<IUser>(endpoint, payload);
  return response;
};

export interface IAddExistingUserPayload {
  user: string;
  tenant: string;
  role: string;
}
export const addExistingUser = async (payload: IAddExistingUserPayload): Promise<IUser> => {
  const endpoint = getMainEndpoint('/tenants/users', 'PATCH');
  const response = await callProtectedMainAPI<IUser>(endpoint, payload);
  return response;
};

export const changeUserRole = async ({ user, role }: Record<string, any>): Promise<IUser> => {
  const endpoint = getMainEndpoint('/tenants/users/role', 'PATCH');
  const response = await callProtectedMainAPI<IUser>(endpoint, { user, role });
  return response;
};

export const deleteUser = async (userId: string): Promise<string> => {
  const endpoint = getMainEndpoint(`/users/${userId}`, 'DELETE');
  const response = await callProtectedMainAPI<string>(endpoint, { userId });
  return response;
};
