import styled from 'styled-components';
import ExploreSidebarItemBase, { IExploreSidebarItem } from './ExploreSidebarItem';

const ExploreSidebarItem = styled(ExploreSidebarItemBase)<IExploreSidebarItem>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  background-color: transparent;
  position: relative;
  word-wrap:break-word;
  text-align: justify;

  padding: 0.9rem ${(props) => (props.active ? '3.8rem' : '1rem')} 0.9rem 1rem;

  ${(props) => props.active && `
    text-shadow: 0px 0px 6px #FFFFFF;
    background-color: #F7F7F7;
  `}

  :hover {
    text-shadow: 0px 0px 6px #FFFFFF;
    cursor: pointer;
    background-color: #DDDDDD !important;
  }

  .explore-sidebar-item-actions {
    position: absolute;
    right: 10px;
    top: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 100%;
  }
`;
ExploreSidebarItem.displayName = 'ExploreSidebarItem';

export default ExploreSidebarItem;
