import { SvgIconComponent } from '@mui/icons-material';
import { withTheme } from 'styled-components';
import { ITheme, ThemeColors } from '../../Themes/Themes';

interface IIcon {
  icon: SvgIconComponent;
  color?: ThemeColors;
  theme: ITheme;
  className?: string;
  style?: Record<string, any>;
}

function Icon({
  icon: IconElement,
  color,
  theme: { colors },
  ...props
}: IIcon): JSX.Element {
  return (
    <IconElement htmlColor={color ? colors[color] : undefined} {...props} />
  );
}

export default withTheme(Icon);
