import { Text } from 'recharts';

interface XTickRenderer {
  x?: any;
  y?: any;
  payload?: any;
  formatter?: (value: any) => any;
}
const ChartTickRenderer = ({
  x, y, payload, formatter
}: XTickRenderer): JSX.Element | null => {
  if (!payload || !payload.value) {
    return null;
  }

  return (
    <Text
      fontSize="12px"
      width="12px"
      x={x}
      y={y}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {formatter ? formatter(payload.value) : payload.value}
    </Text>
  );
};

export default ChartTickRenderer;
