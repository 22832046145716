/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  takeLatest, all, fork, call, put
} from 'redux-saga/effects';
import { ITenant } from '../../../Types';
import { createTenant, getTenants as fetchTenants, switchTenant } from '../../../Services/tenants';
import {
  createNewTenant, switchTenant as switchTenantAction, ICreateNewTenantAction, ISwitchTenantAction, getUserTenants
} from './SessionActions';
import { sagaWrapper, sagaWrapperWithLoading } from '../../utils/saga-utils';
import { getMsgGroupsAction } from '../MsgGroups/MsgGroupsActions';
import { setSession, setSessionLoading, setUserTenants } from './Session';

function* setLoading(loading: boolean) {
  yield call(setSessionLoading, loading);
}
function* getTenants() {
  const tenants: ITenant[] = yield fetchTenants();
  yield put(setUserTenants(tenants));
}

function* submitCreateTenant(action: ICreateNewTenantAction) {
  const { payload, navigate } = action.payload;
  const session: ITenant[] = yield createTenant({ ...payload });
  yield put(setSession(session));
  navigate('/');
}

function* submitSwitchTenant(action: ISwitchTenantAction) {
  const { tenantId, navigate } = action.payload;
  const session: ITenant[] = yield switchTenant(tenantId);
  yield put(setSession(session));
  yield put(getMsgGroupsAction());
  navigate('/');
}

function* getUserTenantsSaga() {
  yield takeLatest(
    getUserTenants.toString(),
    function* () { yield sagaWrapper(getTenants); }
  );
}

function* createNewTenantSaga() {
  yield takeLatest(
    createNewTenant.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitCreateTenant, setLoading, action); }
  );
}

function* switchActiveTenantSaga() {
  yield takeLatest(
    switchTenantAction.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitSwitchTenant, setLoading, action); }
  );
}

function* userContextSaga() {
  yield all([
    fork(getUserTenantsSaga),
    fork(createNewTenantSaga),
    fork(switchActiveTenantSaga)
  ]);
}

export default userContextSaga;
