import {
  HTMLProps, LegacyRef, PropsWithChildren, RefObject, forwardRef
} from 'react';
import styled from 'styled-components';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../Utils/common';

export interface IContentWrapperProps extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'> {
  heightauto?: boolean;
  direction?: 'row' | 'col';
  justify?: 'start' | 'center' | 'end';
  align?: 'start' | 'center' | 'end';
  overflow?: 'auto hidden' | 'hidden auto' | 'auto';
  withpaddingx?: boolean;
  withpaddingy?: boolean;
}

const Div = styled.div<IContentWrapperProps>`
  width: 100%;
  height: ${(props) => (props.heightauto ? '' : '100%')};
  overflow: ${(props) => props.overflow ?? 'hidden'};
  position: relative;

  display: flex;
  flex-direction: ${(props) => (props.direction === 'row' ? 'row' : 'column')};
  justify-content: ${(props) => (props.justify === 'start' ? 'flex-start' : props.justify === 'center' ? 'center' : props.justify === 'end' ? 'flex-end' : 'flex-start')};
  align-items: ${(props) => (props.align === 'start' ? 'flex-start' : props.align === 'center' ? 'center' : props.align === 'end' ? 'flex-end' : 'flex-start')};

  ${(props) => (props.withpaddingx ? `
    padding-left: 2rem;
    padding-right: 2rem;
    
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  ` : '')}
  ${(props) => (props.withpaddingy ? `
    padding-top: 1rem;
    padding-bottom: 1rem;
    
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  ` : '')}
`;

function BDContainer(
  {
    children,
    className,
    ...props
  }: PropsWithChildren<IContentWrapperProps>,
  ref?: LegacyRef<HTMLDivElement>
): JSX.Element {
  return (
    <Div {...props} className={className} ref={ref as RefObject<HTMLDivElement>}>
      {children}
    </Div>
  );
}

export default forwardRef(BDContainer);
