import {
  useRef, LegacyRef, RefObject, forwardRef
} from 'react';
import { Search } from '@mui/icons-material';
import BDTextInput, { IBDTextInput } from './BDTextInput';

interface IBDSearchInput extends IBDTextInput {
  submitsearch?(value: string): void;
  submitOnClear?: boolean;
}

function BDSearchInput({
  submitsearch, submitOnClear, placeholder, ...props
}: IBDSearchInput, ref?: LegacyRef<HTMLInputElement>): JSX.Element {
  const defaultRef = useRef<HTMLInputElement>(null);

  const onKeyDown = (e: any): void => {
    try {
      if (!ref && !defaultRef) {
        return;
      }

      const currentRef = (ref as RefObject<HTMLInputElement> | null ?? defaultRef).current;
      if (!currentRef) {
        return;
      }

      if (e.key !== 'Enter') {
        return;
      }

      if (!e.shiftKey) {
        e.preventDefault();
        submitsearch?.(e.target.value);
      }
    } catch (err) {
      // eslint-disable-next-line no-empty
    }
  };

  const onClearClick = (): void => {
    if (submitOnClear) {
      submitsearch?.('');
    }
  };

  return (
    <BDTextInput
      {...props}
      ref={(ref as RefObject<HTMLInputElement> | null) ?? defaultRef}
      placeholder={placeholder ?? 'Search'}
      icon={Search}
      clearBtn
      onClear={onClearClick}
      onKeyDown={onKeyDown ?? onKeyDown}
    />
  );
}

export default forwardRef(BDSearchInput);
