/* eslint-disable react/button-has-type */
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import validateFormValues from '../../Utils/validate-schema';
import Loader from '../../Components/Loader/Loader.style';
import callApiWrapper from '../../Utils/call-api-wrapper';
import { registerTenant } from './Auth.service';
import useAction from '../../Store/utils/useAction';
import { setSession } from '../../Store/Reducers/UserContext/Session';

const registerTenantValidationSchema = yup.object({
  name: yup.string().required(),
  shortName: yup.string().required(),
  profile: yup.string().optional(),
  adminEmail: yup.string().email().required(),
  adminFullname: yup.string().required(),
  adminPassword: yup.string().required(),
  adminConfirmationPassword: yup.string().required(),
});

interface ITenantForm {
  loading: boolean;
  setLoading: (value: boolean) => void;
  className?: string;
}

function TenantForm({ loading, setLoading }: ITenantForm): JSX.Element {
  const navigate = useNavigate();
  const setSessionState = useAction(setSession);
  const [ credentialData, setCredentialData ] = useState<any>(null);
  const [ credentialError, setCredentialError ] = useState<string | null>(null);

  const onSubmit = callApiWrapper(async (values) => {
    if (!credentialData) {
      setCredentialError('The credential file is required');
      return;
    }

    const session = await registerTenant({ ...values, credentialData });
    setSessionState(session);
    navigate('/');
  }, setLoading);

  const onFileChange = (event: any): void => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (e: any): void => {
      setCredentialError(null);
      try {
        const data = JSON.parse(e.target.result);
        setCredentialData(data);
        setCredentialError(null);
      } catch (err) {
        setCredentialError('Please choose valid json file');
      }
    };
  };

  return (
    <Formik
      initialValues={{}}
      validate={validateFormValues(registerTenantValidationSchema)}
      onSubmit={onSubmit}
    >
      <Form className="auth-form-container tenant-form">
        <h4>Tenant data</h4>

        <div className="auth-form-inputs">
          <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column me-3 w-100">
              <label className="mt-1">Tenant name</label>
              <Field className="tenant-form" type="text" placeholder="name" name="name" />
              <label className="validation-error mt-0"><ErrorMessage name="name" /></label>
            </div>

            <div className="d-flex flex-column w-100">
              <label className="mt-1">Short name</label>
              <Field className="tenant-form" type="text" placeholder="short name" name="shortName" />
              <label className="validation-error mt-0"><ErrorMessage name="shortName" /></label>
            </div>
          </div>

          <label className="mt-1">Tenant profile</label>
          <Field className="tenant-form" type="text" placeholder="tenant profile" name="profile" />
          <label className="validation-error mt-0"><ErrorMessage name="profile" /></label>

          <label className="mt-1">Tenant credential file</label>
          <input className="form-control" type="file" onChange={onFileChange} accept=".json" />
          <label className="validation-error mt-0">
            {credentialError}
          </label>

          <label className="mt-2">Admin fullname</label>
          <Field className="tenant-form" type="text" placeholder="admin fullname" name="adminFullname" />
          <label className="validation-error mt-0"><ErrorMessage name="adminFullname" /></label>

          <label className="mt-1">Admin email</label>
          <Field className="tenant-form" type="text" placeholder="tenant short name" name="adminEmail" />
          <label className="validation-error mt-0"><ErrorMessage name="adminEmail" /></label>

          <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column me-3 w-100">
              <label className="mt-1">Admin password</label>
              <Field className="tenant-form" type="password" placeholder="Password" name="adminPassword" />
              <label className="validation-error mt-0"><ErrorMessage name="adminPassword" /></label>
            </div>

            <div className="d-flex flex-column w-100">
              <label className="mt-1">Confirm Password</label>
              <Field className="tenant-form" type="password" placeholder="Confirm Password" name="adminConfirmationPassword" />
              <label className="validation-error mt-0"><ErrorMessage name="adminConfirmationPassword" /></label>
            </div>
          </div>

        </div>

        <button className="submit-button mt-2 py-2" type="submit">CREATE TENANT</button>

        {loading && <Loader />}
      </Form>
    </Formik>
  );
}

export default TenantForm;
