export interface IUser {
  id: string;
  fullname: string;
  email: string;
  role: string;
  tenant: string;
  slackId?: string;
  defaultPassword?: boolean;
  lastLogin?: Date;
}

export interface IAddUserPayload extends Omit<IUser, 'id' | 'slackId' | 'defaultPassword' | 'lastLogin'> {
  password: string;
}

export type CapabilityTypes = 'adminCenter'

export type CapabilityActions = 'read' | 'create' | 'update' | 'delete'

export type ICapability = Record<CapabilityActions, boolean>

export type ICapabilities = Record<CapabilityTypes, ICapability>

export interface IRole {
  id: string;
  name: string;
  desc: string;
  capabilities: ICapabilities;
  tenant: string;
  default?: boolean;
}

export interface ITenant {
  id: string;
  name: string;
  shortName: string;
  profile: Record<string, Record<string, any>>;
}

export interface IUserContext {
  user: IUser;
  role: IRole;
  tenant: ITenant;
  accessToken: string;
  refreshToken: string;
  createdAt: Date;
  expiresIn: number;
}

export interface IReducerActionWithPayload<T> {
  type: string;
  payload: T;
}

export interface IReducerActionWithoutPayload {
  type: string;
}

export type IReducerAction<T=undefined> = T extends any ? IReducerActionWithPayload<T> : IReducerActionWithoutPayload

export interface IRouteItem {
  path: string;
  name: string;
  component?: React.LazyExoticComponent<() => JSX.Element>;
}

export const SizeBreakpointNames = [ 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl' ] as const;
export type SizeBreakpoints = typeof SizeBreakpointNames[number];

export const ColorBreakpointNames = [ 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'muted', 'white' ] as const;
export type ColorBreakpoints = typeof ColorBreakpointNames[number];
