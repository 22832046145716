import { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route, Routes, useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader.style';
import { selectUserContext } from '../../Store/Reducers/UserContext/SessionSelector';
import callApiWrapper from '../../Utils/call-api-wrapper';
import { signInWithGoogle } from '../../Utils/firebase';
import TenantForm from './TenantForm';
import {
  setPassword,
  signIn,
  signInGoogle,
  slackVerify,
} from './Auth.service';
import SetPassword from './SetPasswordForm';
import SignIn from './SignInForm';
import SlackVerify from './SlackVerify';
import useAction from '../../Store/utils/useAction';
import { setSession } from '../../Store/Reducers/UserContext/Session';

interface IAuthRootLayoutBase {
  className?: string;
}

function AuthBase({ className }: IAuthRootLayoutBase): JSX.Element {
  const navigate = useNavigate();
  const setSessionState = useAction(setSession);
  const [ loading, setLoading ] = useState(false);
  const userContext = useSelector(selectUserContext);

  const onSignInCallback = callApiWrapper(async (values) => {
    const session = await signIn(values);
    setSessionState(session);
    if (session.user.defaultPassword === 'true') {
      navigate('/auth/set-password');
    } else {
      navigate('/');
    }
  }, setLoading);

  const onSlackVerifyCallback = callApiWrapper(async (values, redirectToHome = true) => {
    const session = await slackVerify(values);
    setSessionState(session);
    if (redirectToHome) {
      toast.success('Your account has connected to Slack');
      navigate('/');
    }
  }, setLoading);

  const onSetPassword = callApiWrapper(async (values) => {
    if (!userContext) {
      return;
    }

    const payload = {
      ...values,
      id: userContext.user.id
    };
    await setPassword(payload);
    navigate('/');
  }, setLoading);

  // const onSignUpCallback = generateCallApiHandler(async (values) => {
  //   const session = await signUp(values);
  //   toast.info('Account succesfully created');
  //   dispatch(SetUserSession(session));
  //   navigate('/');
  // }, setLoading);

  const onSignInGoogle = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await signInWithGoogle();
      if (!result.user || !result.user.providerData.length) {
        throw new Error('Unexpected error happen when fetching user data');
      }

      onLoginGoogle(result.user, result.user.stsTokenManager, result.user.providerData[0]);
    } catch (err: any) {
      if (err instanceof Error && err.message && err.message !== '') {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onLoginGoogle = async (
    userData: Record<string, any>,
    token: any,
    providerData: any
  ): Promise<void> => {
    try {
      setLoading(true);
      const payload = {
        email: userData.email,
        fullname: userData.displayName,
        token,
        providerData
      };
      const session = await signInGoogle(payload);
      setSessionState(session);
      if (session.user.defaultPassword === 'true') {
        navigate('/auth/set-password');
      } else {
        navigate('/');
      }
    } catch (err: any) {
      if (err instanceof Error && err.message && err.message !== '') {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={className}>
      <Suspense fallback={Loader}>
        <Routes>
          <Route
            key="signin"
            path="signin"
            element={<SignIn loading={loading} onSubmit={onSignInCallback} onSigninGoogle={onSignInGoogle} />}
          />
          {/* <Route
            key="signup"
            path="signup"
            element={<SignUp loading={loading} onSubmit={onSignUpCallback} onSigninGoogle={onSignInGoogle} />}
          /> */}
          <Route
            key="set-password"
            path="set-password"
            element={<SetPassword loading={loading} onSubmit={onSetPassword} />}
          />
          <Route
            key="register-tenant"
            path="register-tenant"
            element={<TenantForm loading={loading} setLoading={setLoading} />}
          />
          <Route
            key="slack"
            path="slack"
            element={(
              <SlackVerify
                userContext={userContext}
                loading={loading}
                onSubmit={onSlackVerifyCallback}
              />
            )}
          />
          <Route key="*" path="/*" element={<Navigate to="signin" />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AuthBase;
