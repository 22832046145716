import BDForm, { IFormProps } from '../../Components/BDForm/BDForm';
import { IMetadataField } from '../../Utils/metadata';
import { generateFormFields } from '../../Components/BDForm/BDForm.service';
import BDButton from '../../Components/BDButton/BDButton';
import OverflowContainer from '../../Components/OverflowContainer/OverflowContainer';

export interface IExploreController {
  fields: IMetadataField[];
  initialValues?: Record<string, any>;
  onChange?(values: Record<string, any>): void;
  onSubmit(values: Record<string, any>): void;
  previewMode?: boolean;
}

function ExploreController({
  fields,
  initialValues,
  onChange,
  onSubmit
}: IExploreController): JSX.Element {
  const fieldElements = ({ values }: IFormProps): React.ReactNode => (
    <OverflowContainer direction="horizontal" className="align-items-end px-4">
      {generateFormFields({ fields, values })}
      <BDButton size="sm" variant="success" type="submit" style={{ whiteSpace: 'nowrap', marginBottom: '0.37rem', padding: '0.6rem' }}>
        Update data
      </BDButton>
    </OverflowContainer>
  );

  return (
    <div className="w-100 mb-3">
      <BDForm
        className="overflow-hidden w-100"
        fields={fields}
        initialValues={initialValues}
        onChange={onChange}
        onSubmit={onSubmit}
      >
        {fieldElements}
      </BDForm>
    </div>
  );
}

export default ExploreController;
