import { createAction } from '@reduxjs/toolkit';
import { IReducerAction } from '../../../Types';

export const getMsgGroupsAction = createAction('getMsgGroupsAction');

export type ICreateMsgGroupAction = IReducerAction<{ name: string}>;
export const createMsgGroup = createAction('createMsgGroup');

export type IEditMsgGroupAction = IReducerAction<{ id: string; name: string; }>;
export const editMsgGroupAction = createAction('editMsgGroupAction');

export type IDeleteMsgGroupAction = IReducerAction<string>
export const deleteMsgGroupAction = createAction('deleteMsgGroupAction');

export type ISetMsgGroupIsFavoriteAction = IReducerAction<{ id: string; isFavorite: boolean; }>
export const setMsgGroupIsFavorite = createAction('setMsgGroupIsFavorite');
