import {
  Suspense, useState, useEffect, useRef, useCallback, useMemo
} from 'react';
import {
  Route, Routes, matchPath, useLocation
} from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from '@mui/material';
import Loader from '../../Components/Loader/Loader.style';
import BDContainer from '../../Components/BDContainer/BDContainer';
import ExploreSidebar from './ExploreSidebar';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../Components/HOC/withProtectedPageWrapper';
import Page404 from '../404/Page404.style';
import Explorer from './Explorer';
import BDText from '../../Components/BDText/BDText';
import BDTextInput from '../../Components/BDTextInput/BDTextInput';
import BDButton from '../../Components/BDButton/BDButton';
import callApiWrapper from '../../Utils/call-api-wrapper';
import { getMainEndpoint } from '../../Utils/call-api';
import {
  IDataExploration, createDataExploration, deleteDataExploration, updateDataExploration
} from './Explore.service';
import { swal } from '../../Utils/swal';
import { useFetchMainAPI } from '../../Hooks/useFetchMainAPI';

export type IExplore = IProtectedPageWrapper

function Explore({
  userContext, loading, setLoading, navigate
}: IExplore): JSX.Element {
  const location = useLocation();
  const [ saving, setSaving ] = useState(false);
  const [ showSaveModal, setShowSaveModal ] = useState<boolean>(false);
  const [ savePayload, setSavePayload ] = useState<Record<string, any> | undefined>(undefined);
  const explorationNameInputRef = useRef<HTMLInputElement>(null);
  const endpoint = useMemo(() => getMainEndpoint('/data-explorations'), [ ]);
  const { data: explorations, refetch } = useFetchMainAPI<IDataExploration[]>({ endpoint, navigate });

  useEffect(() => {
    if (!explorations?.length) {
      return;
    }

    if (
      !matchPath('/explore/exploration/:explorationId', location.pathname)
      && !matchPath('/explore/preview/:messageId', location.pathname)
    ) {
      navigate(`/explore/exploration/${explorations[0].id}`, { replace: true });
    }
  }, [ explorations ]);

  const saveExploration = useCallback(callApiWrapper<IDataExploration>(async (values: Record<string, any>) => {
    const savedExploration = await createDataExploration(values);
    refetch();
    navigate(`/explore/exploration/${savedExploration.id}`);
    closeCreateEditExplorationModal();

    return savedExploration;
  }, setLoading, navigate), [ savePayload ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateExploration = useCallback(callApiWrapper<IDataExploration>(async (values: Partial<IDataExploration>) => {
    await updateDataExploration({
      id: values.id,
      variables: values.variables,
      insights: values.insights
    });
    toast.success('Exploration saved');
  }, setSaving, navigate), [ savePayload ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateExplorationName = useCallback(callApiWrapper<IDataExploration>(async (id: string, name: string) => {
    const msgInsight = await updateDataExploration({ id, name });
    refetch();
    navigate(`/explore/exploration/${msgInsight.id}`);
    closeCreateEditExplorationModal();

    return msgInsight;
  }, setLoading, navigate), [ savePayload ]);

  const deleteExploration = useCallback(callApiWrapper<string>(async (exp: IDataExploration) => {
    await deleteDataExploration(exp.id);

    if (!explorations || !explorations.length) {
      return;
    }

    const currentExplorations = [ ...explorations ].filter((e) => e.id !== exp.id);
    refetch();
    if (!currentExplorations.length) {
      return;
    }
    navigate(`/explore/exploration/${currentExplorations[0].id}`);
  }, setLoading, navigate), [ explorations ]);

  const showCreateEditExplorationModal = (values: Record<string, any>): void => {
    setSavePayload({ ...values });
    setShowSaveModal(true);
  };

  const closeCreateEditExplorationModal = (): void => {
    setSavePayload(undefined);
    setShowSaveModal(false);
  };

  useEffect(() => {
    if (!explorationNameInputRef.current || !showSaveModal || !savePayload) {
      return;
    }

    explorationNameInputRef.current.value = (savePayload).name;
  }, [ showSaveModal, savePayload ]);

  const preSave = (): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: async () => {
        if (!savePayload || !explorationNameInputRef.current) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...payload } = savePayload;
        // const exp = id ? await updateExplorationName(id, explorationNameInputRef.current.value) : await saveExploration({
        //   ...payload,
        //   name: explorationNameInputRef.current.value
        // });
        const exp = await saveExploration({
          ...payload,
          name: explorationNameInputRef.current.value
        });
        if (!exp) {
          throw new Error('Failed to save exploration');
        }

        return exp;
      }
    });
  };

  const preDelete = (exp: IDataExploration): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: () => deleteExploration(exp)
    });
  };

  const isExploring = matchPath('/explore/exploration/:explorationId', location.pathname);
  const content = isExploring && !explorations?.length ? (
    <div className="d-flex-col-center-center w-100 h-100">
      <BDText textsemibold>
        Explore data from
        {' '}
        <Link href="/">chatbot</Link>
        {' '}
        conversation
      </BDText>
    </div>
  ) : (
    <BDContainer style={{ overflow: 'hidden' }}>
      {saving && <Loader overlay="#FFFFFF88" />}

      <Suspense fallback={Loader}>
        <Routes>
          <Route
            key="exploration"
            path="exploration/:explorationId"
            element={(
              <Explorer
                onSaveClick={showCreateEditExplorationModal}
              />
            )}
          />
          <Route
            key="preview"
            path="preview/:messageId"
            element={(
              <Explorer
                previewMode
                onSaveClick={showCreateEditExplorationModal}
              />
            )}
          />
          <Route key="*" path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </BDContainer>
  );

  return (
    <>
      <BDContainer direction="row">
        {loading && <Loader />}

        <ExploreSidebar
          userContext={userContext}
          explorations={explorations ?? []}
          refreshExplorations={refetch}
          onEditExplorationNameClick={showCreateEditExplorationModal}
          onDeleteExplorationClick={preDelete}
        />

        {content}
      </BDContainer>

      <Modal
        show={showSaveModal}
        onHide={closeCreateEditExplorationModal}
        backdrop="static"
        centered
      >
        <Modal.Header className="py-2">
          <BDText textbold textsize="md">Save exploration</BDText>
        </Modal.Header>
        <Modal.Body>
          <BDText textsemibold className="mb-2">Exploration name</BDText>
          <BDTextInput ref={explorationNameInputRef} title="Exploration name" />
        </Modal.Body>
        <Modal.Footer>
          <BDButton variant="success" onClick={preSave}>
            Save
          </BDButton>
          <BDButton variant="secondary" onClick={closeCreateEditExplorationModal}>
            Cancel
          </BDButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withProtectedPageWrapper(Explore);
