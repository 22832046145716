import { createAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { IReducerAction } from '../../../Types';

export const getUserTenants = createAction('getUserTenants');

export type ICreateNewTenantAction = IReducerAction<{ payload: Record<string, any>, navigate: NavigateFunction}>;
export const createNewTenant = createAction('createNewTenant');

export type ISwitchTenantAction = IReducerAction<{ tenantId: string, navigate: NavigateFunction }>;
export const switchTenant = createAction('switchTenant');
