import { IDataVizConfig, isCommonChartType } from './DataVisualization.service';
import CommonChart, { ICommonChart, ICommonChartConfig } from './CommonChart';
import SunburstChart, { ISunburstChartDataValue } from './Charts/SunburstChart/SunburstChart';
import DataVizDataTable from './Charts/DataVizDataTable';

export interface IDataVisualization {
  data: Record<string, any>[];
  config: IDataVizConfig | ICommonChartConfig;
  width?: number;
  height?: number;
}

function DataVisualization(props: IDataVisualization): JSX.Element {
  const { data, config: { type } } = props;

  if (type === 'sunburst') {
    return (
      <SunburstChart {...props} data={data as ISunburstChartDataValue} />
    );
  }

  if (isCommonChartType(type)) {
    return (
      <CommonChart {...props as ICommonChart} />
    );
  }

  return (
    <DataVizDataTable data={data} />
  );
}

export default DataVisualization;
