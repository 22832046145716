import {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BDContainer from '../../Components/BDContainer/BDContainer';
import { callProtectedMainAPI, getMainEndpoint } from '../../Utils/call-api';
import { IMetadataField } from '../../Utils/metadata';
import Loader from '../../Components/Loader/Loader.style';
import { IDataExploration } from './Explore.service';
import callApiWrapper from '../../Utils/call-api-wrapper';
import ExplorationHeader from './ExplorationHeader';
import Exploration from './Exploration';
import { IInsight } from '../Home/Chatbot/Chatbot.service';
import useSocketIOClient, { socketEventWrapper } from '../../Hooks/useSocketIO';
import { IUserContext } from '../../Types';

export interface IExplorationInsights {
  userContext: IUserContext;
  loading?: boolean,
  setLoading?(loading: boolean): void;
  previewMode?: boolean,
  explorationId: string,
  onSaveClick?(payload: Partial<IDataExploration>): void;
}

function ExploreInsights({
  userContext,
  loading,
  setLoading,
  previewMode,
  explorationId,
  onSaveClick,
}: IExplorationInsights): JSX.Element {
  const didmount = useRef(false);
  const navigate = useNavigate();
  const [ socket, socketConnected ] = useSocketIOClient(userContext);

  const explorationRef = useRef<IDataExploration | null>(null);
  const [ exploration, _setExploration ] = useState<IDataExploration | null>(null);
  const setExploration = useCallback((exp: IDataExploration) => {
    explorationRef.current = exp;
    _setExploration(exp);
  }, []);

  const [ variableFields, setVariableFields ] = useState<IMetadataField[] | undefined>(undefined);
  const [ variables, setVariables ] = useState<Record<string, any> | undefined>(undefined);
  const [ insightLoadings, _setInsightLoadings ] = useState<{ [insightId: string]: boolean }>({});
  const insightLoadingsRef = useRef<{ [insightId: string]: boolean }>({});
  const setAllInsightLoadings = useCallback((value: boolean) => {
    setInsightLoadings(Object.keys(insightLoadings).reduce((acc: Record<string, any>, curr) => { acc[curr] = value; return acc; }, {}));
  }, [ exploration ]);
  const setInsightLoadings = useCallback((loadings: { [insightId: string]: boolean }) => {
    insightLoadingsRef.current = loadings;
    _setInsightLoadings(loadings);
  }, [ exploration ]);
  const setInsightLoading = useCallback((insightId: string, isLoading: boolean) => {
    if (insightLoadingsRef.current[insightId] === undefined) {
      return;
    }

    insightLoadingsRef.current[insightId] = isLoading;
    _setInsightLoadings(insightLoadingsRef.current);
  }, [ exploration ]);

  useEffect(() => {
    if (didmount.current) {
      return;
    }
    didmount.current = true;

    socket.on('get-insight', onGetInsightResponse);

    return () => {
      if (!didmount.current) {
        return;
      }

      socket.off('get-insight', onGetInsightResponse);
      socket.close();

      didmount.current = false;
    };
  }, [ ]);

  useEffect(() => {
    if (!socketConnected) {
      socket.connect();
    }
  }, [ socketConnected ]);

  useEffect(() => {
    getInitialData();
  }, [ explorationId, previewMode ]);

  const getInitialData = useCallback(callApiWrapper<IDataExploration>(async () => {
    const ep = getMainEndpoint(`/data-explorations/${previewMode ? 'preview/' : ''}${explorationId}`);
    const explorationData = await callProtectedMainAPI<IDataExploration>(ep);
    const fields = await callProtectedMainAPI<IMetadataField[]>(getMainEndpoint(`/data-explorations/fields/${explorationData.prompt.id}`));
    setExploration(explorationData);
    setVariableFields(fields);
    setVariables({ ...explorationData.variables });
    setInsightLoadings(explorationData.insights.reduce((acc: Record<string, any>, curr) => {
      acc[curr.insightId] = false;
      return acc;
    }, {}));
  }, setLoading, navigate), [ explorationId, previewMode ]);

  const onGetInsightResponse = useCallback(socketEventWrapper<IInsight[]>(async (data) => {
    if (!explorationRef.current) {
      return;
    }

    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. Messages payload not found');
      setAllInsightLoadings(false);
      return;
    }

    const { data: newInsights } = data;
    const currentInsights = [ ...explorationRef.current.insights ];
    newInsights.forEach((insight) => {
      const currentInsight = currentInsights.find((i) => i.insightId === insight.insightId);
      if (!currentInsight) {
        return;
      }

      currentInsight.data = insight.data;
      currentInsight.text = insight.text;
      setInsightLoading(insight.insightId, false);
    });
    setExploration({
      ...explorationRef.current,
      insights: [ ...currentInsights ]
    });
  }), [ ]);

  const onVariablesChange = (values: Record<string, any>): void => {
    setVariables({ ...values });
  };

  const getInsights = (vars: Record<string, any>): void => {
    if (!exploration?.prompt.processes || !exploration.insights) {
      return;
    }

    exploration.prompt.processes.forEach((processConfig) => {
      socket.emit('get-insight', {
        data: {
          prompt: exploration.prompt,
          variables: vars,
          processConfig
        }
      });
    });
    setInsightLoadings(exploration.insights.reduce((acc: Record<string, any>, curr) => {
      acc[curr.insightId] = true;
      return acc;
    }, {}));
  };

  const onSaveClickHandler = (): void => {
    if (!exploration || !prompt) {
      if (!prompt) {
        toast.error('Old data, now prompt data found. Please send new prompt to chatbot and explore the response');
      }
      return;
    }

    onSaveClick?.({
      id: exploration.id,
      name: exploration.name ?? '',
      userPromptId: exploration.userPromptId,
      prompt: exploration.prompt,
      variables: { ...variables },
      insights: exploration.insights
    });
  };

  return (
    <BDContainer>
      {loading && <Loader />}

      <ExplorationHeader
        explorationName={exploration?.name ?? ''}
        previewMode={previewMode}
        onSaveClick={onSaveClickHandler}
      />

      {exploration?.insights && (
        <Exploration
          variableFields={variableFields}
          variables={variables}
          insights={exploration.insights}
          insightLoadings={insightLoadings}
          onVariablesChange={onVariablesChange}
          getInsights={getInsights}
        />
      )}
    </BDContainer>
  );
}

export default ExploreInsights;
