import { useMemo } from 'react';
import {
  CartesianGrid,
  Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis
} from 'recharts';
import ChartTickRenderer from '../ChartComponents/ChartTickRenderer';
import { getDefaultColor } from '../DataVisualization.service';
import ReferenceLineLabel from '../ChartComponents/ReferenceLineLabel';
import { ICommonChart } from '../CommonChart';

export interface ILineChartComponent extends ICommonChart {
  xTickFormatter?: (value: any) => any;
  yTickFormatter?: (value: any) => any;
  legend?: JSX.Element;
  tooltip?: JSX.Element;
}

function LineChartComponent({
  data,
  config,
  width,
  height,
  xTickFormatter,
  yTickFormatter,
  legend,
  tooltip
}: ILineChartComponent): JSX.Element {
  const { xField, yFields, referencePoints } = config;

  const xAxis = (
    <XAxis
      dataKey={xField.id}
      tickFormatter={xTickFormatter}
      tick={<ChartTickRenderer formatter={xTickFormatter} />}
      color="white"
    />
  );

  const ydatakeysWithColor = useMemo(() => yFields.reduce((acc: Record<string, any>, curr, currIndex) => {
    acc[curr.id] = getDefaultColor(currIndex);
    return acc;
  }, {}), [ yFields ]);

  const lines = Object.keys(ydatakeysWithColor).map((datakey, index) => (
    <Line
      key={index}
      type="monotone"
      dataKey={datakey}
      strokeWidth={2}
      stroke={ydatakeysWithColor[datakey]}
      dot={false}
      activeDot={{ r: 4 }}
      label="haha"
    />
  ));

  const referencePointElements = useMemo(() => {
    if (!referencePoints) {
      return null;
    }

    return Object.entries(referencePoints).map(([ fieldId, dataIndexs ]) => {
      const yfield = yFields.find((f) => f.id === fieldId);
      if (!yfield) {
        return null;
      }

      return dataIndexs.map((i) => {
        const dataIndex = i >= 0 ? i : data.length + i;
        if (!data[dataIndex]) {
          return null;
        }

        const xValue = data[dataIndex][xField.id];
        const yValue = data[dataIndex][yfield.id];

        return (
          <ReferenceLine
            segment={[
              { x: xValue, y: 0 },
              { x: xValue, y: yValue },
            ]}
            stroke={ydatakeysWithColor[yfield.id]}
            strokeDasharray="3 3"
            strokeWidth={3}
            label={<ReferenceLineLabel value={yValue} bg={ydatakeysWithColor[yfield.id]} />}
          />
        ); });
    });
  }, [ referencePoints ]);

  return (
    <ResponsiveContainer width={width ?? '100%'} height={height ?? '100%'}>
      <LineChart
        data={data}
        margin={{
          top: 15, right: 20, left: -10, bottom: 25
        }}
      >
        {xAxis}
        <YAxis
          color="white"
          tick={<ChartTickRenderer formatter={yTickFormatter} />}
          tickMargin={14}
        />
        {lines}
        {referencePointElements}
        {legend}
        {tooltip}
        <CartesianGrid strokeDasharray="3 3" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineChartComponent;
