import styled from 'styled-components';
import ChatbotWelcomeBase from './ChatbotWelcome';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../../Utils/common';

const ChatbotWelcome = styled(ChatbotWelcomeBase)`
  width: 100%;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: ${MD_BREAKPOINT}px) {
    padding: 2rem;
  }
  @media screen and (max-width: ${SM_BREAKPOINT}px) {
    padding: 1rem;
  }

  .suggestions {
    width: 100%;
    margin-bottom: 2rem;

    label {
      margin-bottom: 0.6rem;
    }

    .suggestions-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      
      > * {
        margin: 0 1rem 0 0;
      }
    }

    .recent-bookmarks {
      width: 100%;
      position: relative;
      overflow: auto hidden;
      scrollbar-color: #80808098 transparent;
      scrollbar-width: thin;
      flex-wrap: nowrap;
    }
  }
`;
ChatbotWelcome.displayName = 'ChatbotWelcome';

export default ChatbotWelcome;
