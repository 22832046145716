/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import {
  useCallback, useEffect, useRef, useState,
  useMemo
} from 'react';
import { toast } from 'react-toastify';
import { Send } from '@mui/icons-material';
import styled from 'styled-components';
import { Socket } from 'socket.io-client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../Components/Loader/Loader.style';
import Spinner from '../../../../Components/Spinner/Spinner';
import { IUserContext } from '../../../../Types';
import { IExplore } from '../../../../Utils/exploration';
import {
  IChatbotMessage, IMessageGroup
} from '../Chatbot.service';
import ChatbotMessage, { ChatbotMessageSeparator } from '../ChatbotMessage/ChatbotMessage.style';
import { socketEventWrapper } from '../../../../Hooks/useSocketIO';
import Icon from '../../../../Components/Icon/Icon';
import BDButton from '../../../../Components/BDButton/BDButton';
import OverflowContainer from '../../../../Components/OverflowContainer/OverflowContainer';
import BDTextInput from '../../../../Components/BDTextInput/BDTextInput';
import ChatbotContainer from '../ChatbotContainer/ChatbotContainer.style';
import Page404 from '../../../404/Page404.style';
import ChatbotHeader from '../ChatbotHeader/ChatbotHeader';
import { useFetchMainAPI } from '../../../../Hooks/useFetchMainAPI';
import { getMainEndpoint } from '../../../../Utils/call-api';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import { deleteDataExploration, quckSaveInsights } from '../../../Explore/Explore.service';

const PAGE_LIMIT = 18;

export interface IChatbotForm {
  userContext: IUserContext;
  socket: Socket;
}

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  button {
    height: 100%;
    aspect-ratio: 1;

    color: ${(props) => props.theme.colors.primary};

    :hover {
      background-color: ${(props) => props.theme.colors.primary} !important;
      color: white;
    }
  }
`;

function ChatbotForm({
  userContext,
  socket,
}: IChatbotForm): JSX.Element {
  const { user } = userContext;
  const didmount = useRef(false);

  const navigate = useNavigate();
  const { msgGroupId } = useParams();
  const { state } = useLocation();
  const quickPrompt = state?.quickPrompt;
  window.history.replaceState({}, document.title); // clear the state so on page reload it will not threated as waiting for response anymore (quick prompt)
  const [ initialLoading, setInitialLoading ] = useState(true);
  const endpoint = useMemo(() => getMainEndpoint(`/message-groups/${msgGroupId}`), [ msgGroupId ]);
  const {
    data: msgGroup,
    loading: msgGroupLoading,
    setLoading: setMsgGroupLoading,
    error
  } = useFetchMainAPI<IMessageGroup>({ endpoint, navigate });

  const [ waitingForResponse, setWaitingForResponse ] = useState<boolean>(!!quickPrompt);
  const endScrollRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [ lastMessagesContainerHeight, setLastMessagesContainerHeight ] = useState(0);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [ saving, setSaving ] = useState(false);

  // WITH PAGINATION
  // only use "exploration" for rendering, use explorationRef to access the value
  const [ exploration, _setExploration ] = useState<(IExplore<(IChatbotMessage | string)>)>({
    pagination: {
      page: 1,
      limit: PAGE_LIMIT,
      pageCount: 1
    },
    data: []
  });
  const explorationRef = useRef<IExplore<(IChatbotMessage | string)>>({ ...exploration });
  const setExploration = (newData: IExplore<(IChatbotMessage | string)>): void => {
    explorationRef.current = newData;

    // extract messages from previous state that may contain date separator (string)
    const messageOnly = newData.data.filter((m) => typeof m !== 'string') as IChatbotMessage[];

    // extract unique dates from the messages
    let dates = Array.from(new Set(messageOnly.map((msg) => moment.utc(msg.createdAt).local().format('MMMM DD YYYY').toString())));

    // set new data by adding separator based on unique dates
    explorationRef.current.data = messageOnly.reduce((acc: (IChatbotMessage | string)[], curr) => {
      const mdate = moment.utc(curr.createdAt).local().format('MMMM DD YYYY').toString();
      const check = dates.find((d) => d === mdate);
      if (check) {
        acc.push(mdate);
        dates = dates.filter((d) => d !== check);
      }
      acc.push(curr);
      return acc;
    }, []);

    _setExploration(explorationRef.current);
  };

  const onMessagesResponse = useCallback(socketEventWrapper<IExplore<IChatbotMessage>>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. [get-messages] response: Messages payload not found');
      return;
    }

    const { data: explorationResult } = data;

    // if first page, must be no data, so overwrite all the state fields
    if (explorationResult?.pagination.page === 1) {
      setExploration(explorationResult);
      return;
    }

    // if not first page, insert new data from first element
    // backend query the messages in 'desc' order and reverse it then send it to UI (it become 'asc' ordered, because UI render it as 'asc' order)
    const newExploration: IExplore<IChatbotMessage | string> = { ...explorationResult };
    newExploration.data = [
      ...explorationResult.data,
      ...explorationRef.current.data,
    ];
    setExploration(newExploration);
    setMsgGroupLoading(false);
  }), []);

  const onSendMessageResponse = useCallback(socketEventWrapper<IChatbotMessage>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. [send-message] response: Message payload not found');
      return;
    }

    const { id, tempId } = data.data;
    const currentMessage = explorationRef.current.data;
    const sendingMessage = currentMessage.find((m) => {
      if (typeof m === 'string') {
        return false;
      }

      return m.tempId === tempId && m.sending;
    }) as IChatbotMessage;
    if (!sendingMessage) {
      return;
    }

    delete sendingMessage.tempId;
    sendingMessage.sending = false;
    sendingMessage.id = id;
    setExploration({
      ...explorationRef.current,
      data: currentMessage
    });
  }), []);

  const onChatbotResponse = useCallback(socketEventWrapper<IChatbotMessage[]>((data) => {
    if (!data || data.error || !data.data) {
      toast.error(data?.error ?? 'Unexpected error. [post-message] response: Messages payload not found');
      setWaitingForResponse(false);
      return;
    }

    const { data: response } = data;

    setExploration({
      ...explorationRef.current,
      data: [
        ...explorationRef.current.data,
        ...response
      ]
    });

    scrollToBottom();
    inputRef.current?.focus();
  }), []);

  const onChatbotResponseDone = useCallback(socketEventWrapper<IChatbotMessage[]>(() => {
    setWaitingForResponse(false);
  }), []);

  const quickSaveInsights = useCallback(callApiWrapper(async (userPromptId: string) => {
    const explorationId = await quckSaveInsights(userPromptId);
    const currentMessage = explorationRef.current.data;
    const savedInsights = currentMessage.filter((m) => {
      if (typeof m === 'string') {
        return false;
      }

      return m.userPromptMsgId === userPromptId;
    }) as IChatbotMessage[];

    savedInsights.forEach((m) => {
      m.bookmarkId = explorationId;
    });

    setExploration({
      ...explorationRef.current,
      data: currentMessage
    });
  }, setSaving, navigate), [ explorationRef.current ]);

  const deleteInsights = useCallback(callApiWrapper(async (bookmarkId: string) => {
    await deleteDataExploration(bookmarkId);

    const currentMessage = explorationRef.current.data;
    const savedInsights = currentMessage.filter((m) => {
      if (typeof m === 'string') {
        return false;
      }

      return m.bookmarkId === bookmarkId;
    }) as IChatbotMessage[];
    if (!savedInsights?.length) {
      return;
    }

    savedInsights.forEach((m) => {
      m.bookmarkId = undefined;
    });

    setExploration({
      ...explorationRef.current,
      data: currentMessage
    });
  }, setSaving, navigate), [ explorationRef.current ]);

  const scrollTo = (to: number, behavior: 'auto' | 'smooth' = 'auto'): void => {
    messagesContainerRef.current?.scrollTo({
      top: to,
      behavior
    });
  };

  const scrollToBottom = (behavior: 'auto' | 'smooth' = 'smooth'): void => {
    setTimeout(() => {
      if (!messagesContainerRef.current) {
        return;
      }
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior
      });
    }, 150);
  };

  const onScroll = (): void => {
    if (!msgGroup || initialLoading || !messagesContainerRef.current || msgGroupLoading) {
      return;
    }
    const { scrollTop } = messagesContainerRef.current;
    if (scrollTop <= 0 && explorationRef.current.pagination.page < explorationRef.current.pagination.pageCount) {
      setLastMessagesContainerHeight(messagesContainerRef.current.scrollHeight);
      setMsgGroupLoading(true);
      socket.emit('get-messages', {
        data: {
          msgGroup: msgGroup.id,
          exploration: {
            pagination: {
              page: explorationRef.current.pagination.page + 1,
              limit: PAGE_LIMIT
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    if (didmount.current) {
      return;
    }
    didmount.current = true;

    socket.on('get-messages', onMessagesResponse);
    socket.on('send-message', onSendMessageResponse);
    socket.on('chatbot-response', onChatbotResponse);
    socket.on('chatbot-response-done', onChatbotResponseDone);

    return () => {
      if (!didmount.current) {
        return;
      }
      socket.off('get-messages', onMessagesResponse);
      socket.off('send-message', onSendMessageResponse);
      socket.off('chatbot-response', onChatbotResponse);

      didmount.current = false;
    };
  }, []);

  useEffect(() => {
    setInitialLoading(true);
  }, [ msgGroupId ]);

  useEffect(() => {
    if (!didmount.current || !msgGroup) {
      setInitialLoading(true);
      return;
    }

    if (!socket.connected) {
      socket.connect();
      return;
    }

    setInitialLoading(true);
    socket.emit('get-messages', {
      data: {
        msgGroup: msgGroup.id,
        exploration: {
          pagination: {
            page: 1,
            limit: PAGE_LIMIT
          }
        }
      }
    });

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [ msgGroup, didmount.current, socket.connected ]);

  useEffect(() => {
    if (!explorationRef.current.data.length) {
      if (initialLoading) {
        setTimeout(() => {
          setInitialLoading(false);
        }, 1000);
      }

      return;
    }

    if (messagesContainerRef.current && lastMessagesContainerHeight && explorationRef.current.pagination.page > 1) {
      const dheight = messagesContainerRef.current.scrollHeight - lastMessagesContainerHeight;
      scrollTo(dheight - 200);
      setLastMessagesContainerHeight(0);
    }

    if (initialLoading) {
      scrollToBottom('auto');
      setTimeout(() => {
        setInitialLoading(false);
      }, 1000);
    }
  }, [ exploration.data ]);

  const onInputKeyDown = (e: any): void => {
    if (e.key !== 'Enter') {
      return;
    }

    if (!e.shiftKey && inputRef.current?.value && inputRef.current?.value !== '') {
      e.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = (e?: any, msg?: string): void => {
    if (!msgGroup) {
      return;
    }

    e?.preventDefault();

    const newMessage: IChatbotMessage = {
      tempId: crypto.randomUUID(),
      owner: user.id,
      sender: user.id,
      messageGroup: msgGroup.id,
      message: msg ?? inputRef.current?.value
    };
    setExploration({
      ...explorationRef.current,
      data: [
        ...explorationRef.current.data,
        { ...newMessage, sending: true }
      ]
    });

    scrollToBottom();

    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setWaitingForResponse(true);
    socket.emit('send-message', { data: newMessage });
  };

  if ((!msgGroup && !msgGroupLoading) || error) {
    return (
      <Page404 />
    );
  }

  return (
    <ChatbotContainer>
      {(initialLoading || msgGroupLoading) && <Loader />}

      {msgGroup && <ChatbotHeader msgGroup={msgGroup} />}

      <OverflowContainer ref={messagesContainerRef} className="px-5" onScroll={() => onScroll()}>
        {saving && <Loader overlay="#FFFFFF50" />}

        {exploration.data.map((msg, index) => (typeof msg === 'string' ? (
          <ChatbotMessageSeparator key={index}>
            {msg}
          </ChatbotMessageSeparator>
        ) : (
          <ChatbotMessage
            key={index}
            msg={msg}
            onSuggestionClick={(question: string) => onSubmit(undefined, question)}
            onSaveInsights={quickSaveInsights}
            onUnsaveInsights={deleteInsights}
          />
        )))}
        <div className="p-3">
          {waitingForResponse && <Spinner />}
        </div>
        <div ref={endScrollRef} />
      </OverflowContainer>

      <InputContainer className="px-5 py-4">
        <BDTextInput
          ref={inputRef}
          className="me-3"
          inputClassName="border-0 py-2"
          noborder
          fullwidth
          style={{ height: '44px' }}
          placeholder="Type your message here"
          onKeyDown={onInputKeyDown}
          disabled={waitingForResponse}
        />
        <BDButton
          type="submit"
          variant="outline-primary"
          className="rounded-circle border-0"
          style={{ padding: '0.4rem', height: '44px' }}
          disabled={waitingForResponse}
          onClick={onSubmit}
        >
          <Icon icon={Send} className="p-0" style={{ marginLeft: '6px' }} />
        </BDButton>
      </InputContainer>
    </ChatbotContainer>
  );
}

export default ChatbotForm;
