import styled from 'styled-components';
import LoaderBase, { ILoaderProps } from './Loader';

const Loader = styled(LoaderBase)<ILoaderProps>`
  width: 100%; 
  height: 100%;
  background: ${(props) => props.overlay ?? props.theme.main.primaryBg};

  position: absolute;
  left: 0;
  top: 0;

  z-index: 20;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;
Loader.displayName = 'Loader';

export default Loader;
