import { ReactFragment, ReactPortal } from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import styled from 'styled-components';

interface BDDropdownContainer extends DropdownProps {
  children: ReactFragment | ReactPortal | boolean | null | undefined;
  noarrow?: boolean;
  transparent?: boolean;
}

const Container = styled(Dropdown)<BDDropdownContainer>`
  > .dropdown-menu {
    right: 0 !important;
    ${(props) => (props.drop === 'up' ? 'bottom: 48px;' : '')}
    margin-top: 8px;
    padding: 0;

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 0.6rem 1rem;

      :first-child {
        padding-top: 0.8rem;
      }
      :last-child {
        padding-bottom: 0.8rem;
      }
    }
  }

  ${(props) => (props.transparent ? `
    > .dropdown-toggle {
      background-color: transparent !important;
      border-color: transparent !important;
      color: ${props.theme.colors.default} !important;

      :hover {
        background-color: ${props.theme.main.primaryBg}CC !important;
        border-color: ${props.theme.main.primaryBg} !important;
      }
    }` : '')
}

  ${(props) => (props.noarrow ? `
    .dropdown-toggle::after {
      display: none !important;
    }
  ` : '')}
`;

function BDDropdownContainer({ children, ...props }: BDDropdownContainer): JSX.Element {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export default BDDropdownContainer;
