import { IMessageDataVizConfig } from '../Chatbot.service';
import ChatbotCommonChart from './ChatbotCommonChart';
import ChatbotSunburstChart from './ChatbotSunburstChart';

export interface IChatbotDataVisualization {
  data: Record<string, any>[];
  config: IMessageDataVizConfig;
  width?: number;
  height?: number;
  previewMode?: boolean;
  className?: string;
}

export const CHATBOT_PREVIEW_MODE_LIMIT = 500;

function ChatbotDataVisualization(props: IChatbotDataVisualization): JSX.Element | null {
  const { config: { type } } = props;

  if (type === 'sunburst') {
    return (
      <ChatbotSunburstChart {...props} />
    );
  }

  return (
    <ChatbotCommonChart {...props} />
  );
}

export default ChatbotDataVisualization;
