import {
  Add, BookmarkBorder, Home, TrendingUp
} from '@mui/icons-material';
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import {
  matchPath, useLocation, useNavigate, useParams
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ICreateEditModalState,
  IMessageGroup, createMessageGroup, deleteMessageGroup, editMessageGroup, msgGroupFormFields
} from '../Chatbot.service';
import ChatbotSidebarItem from './ChatbotSidebarItem.tsx/ChatbotSidebarItem.style';
import Sidebar from '../../../../Components/Sidebar/Sidebar.style';
import Icon from '../../../../Components/Icon/Icon';
import BDButton from '../../../../Components/BDButton/BDButton';
import { SidebarBody } from '../../../../Components/Sidebar/Sidebar';
import { IUserContext } from '../../../../Types';
import BDSearchInput from '../../../../Components/BDTextInput/BDSearchInput';
import './ChatbotSidebar.scss';
import OverflowContainer from '../../../../Components/OverflowContainer/OverflowContainer';
import BDText from '../../../../Components/BDText/BDText';
import { swal } from '../../../../Utils/swal';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import CreateEditModal from '../../../../Components/BDForm/BDCreateEditModal/BDCreateEditModal';
import { selectMsgGroups } from '../../../../Store/Reducers/MsgGroups/MsgGroupsSelector';
import useAction from '../../../../Store/utils/useAction';
import { getMsgGroupsAction, setMsgGroupIsFavorite } from '../../../../Store/Reducers/MsgGroups/MsgGroupsActions';

export interface IChatbotSidebar {
  userContext: IUserContext;
}

function ChatbotSidebarBase({ userContext }: IChatbotSidebar): JSX.Element {
  const location = useLocation();
  const params = useParams();
  const currentPath = params['*']?.split('/');
  const msgGroupId = currentPath && currentPath[currentPath.length - 1];

  const navigate = useNavigate();
  const getMessageGroups = useAction(getMsgGroupsAction);
  const submitMsgGroupIsFavorite = useAction(setMsgGroupIsFavorite);
  const msgGroups = useSelector(selectMsgGroups);
  const [ filteredGroups, setFilteredGroups ] = useState(msgGroups ?? []);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [ createEditMsgGroupModal, setCreateEditMsgGroupModal ] = useState<ICreateEditModalState>({ show: false });
  const [ createEditMsgGroupModalLoading, setCreateEditMsgGroupModalLoading ] = useState(false);

  useEffect(() => {
    getMessageGroups();
  }, []);

  useEffect(() => {
    if (!searchInputRef.current) {
      return;
    }
    filterGroups(searchInputRef.current.value);
  }, [ msgGroups ]);

  const onItemClick = (group: IMessageGroup): void => {
    navigate(`/chatbot/${group.id}`);
  };

  const filterGroups = (byValue: string): void => {
    if (!msgGroups) {
      return;
    }

    const currentMsgGroups = msgGroups;
    if (byValue === '') {
      setFilteredGroups([ ...currentMsgGroups ]);
      return;
    }

    setFilteredGroups([ ...currentMsgGroups ].filter((g) => g.name.toLocaleLowerCase().includes(byValue)));
  };

  const submitCreateMsgGroup = useCallback(callApiWrapper(async (payload: Record<string, any>) => {
    const msgGroup = await createMessageGroup(payload);
    getMessageGroups();
    navigate(`/chatbot/${msgGroup.id}`);
    return msgGroup;
  }, setCreateEditMsgGroupModalLoading, navigate), []);

  const submitEditMsgGroup = useCallback(callApiWrapper(async (payload: Record<string, any>) => {
    const msgGroup = await editMessageGroup(payload);
    getMessageGroups();
    return msgGroup;
  }, setCreateEditMsgGroupModalLoading, navigate), []);

  const submitDeleteMsgGroup = useCallback(callApiWrapper(async (deleteMsgGroupId: string) => {
    await deleteMessageGroup(deleteMsgGroupId);
    const currentMsgGroups = [ ...msgGroups ].filter((g) => g.id !== deleteMsgGroupId);
    navigate(currentMsgGroups.length ? `/chatbot/${currentMsgGroups[0].id}` : '/');
    getMessageGroups();
    return deleteMsgGroupId;
  }, setCreateEditMsgGroupModalLoading, navigate), [ msgGroups ]);

  const showCreateEditMsgGroupModal = (selectedGroup?: IMessageGroup): void => {
    setCreateEditMsgGroupModal({
      show: true,
      isEdit: !!selectedGroup,
      values: selectedGroup ?? { name: '' }
    });
  };

  const onSubmitCreateEditMsgGroup = ({ id, name }: Record<string, any>): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: async () => {
        if (createEditMsgGroupModal.isEdit) {
          submitEditMsgGroup({ id, name });
        } else {
          submitCreateMsgGroup({ name });
        }
        closeCreateEditMsgGroupModal();
      },
      onCancelCallback: closeCreateEditMsgGroupModal
    });
  };

  const closeCreateEditMsgGroupModal = (): void => setCreateEditMsgGroupModal({ show: false });

  const onDeleteMsgGroup = (selectedGroup: IMessageGroup): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: () => submitDeleteMsgGroup(selectedGroup.id),
      onCancelCallback: closeCreateEditMsgGroupModal
    });
  };

  return (
    <>
      <Sidebar userContext={userContext} defaultFooter>
        <SidebarBody>
          <div className="d-flex-col-start-start w-100 mb-2">
            <div className="d-flex-row-start-center mb-3">
              <BDButton
                className="py-2 me-3"
                variant="outline-dark"
                size="sm"
                onClick={() => navigate('/')}
                disabled={!!matchPath('/', location.pathname)}
              >
                <Icon icon={Home} />
              </BDButton>
              <BDText textsemibold>Overview</BDText>
            </div>
            <div className="d-flex-row-start-center mb-3">
              <BDButton
                className="py-2 me-3"
                variant="outline-dark"
                size="sm"
              >
                <Icon icon={TrendingUp} />
              </BDButton>
              <BDText textsemibold>Weekly Digest</BDText>
            </div>
            <div className="d-flex-row-start-center mb-3">
              <BDButton
                className="py-2 me-3"
                variant="outline-dark"
                size="sm"
                disabled={!!matchPath('/bookmarks', location.pathname)}
                onClick={() => navigate('/explore')}
              >
                <Icon icon={BookmarkBorder} />
              </BDButton>
              <BDText textsemibold>Saved Conversations</BDText>
            </div>
            <BDButton
              className="d-flex-row-center-center me-2 py-2 w-100"
              variant="primary"
              size="sm"
              onClick={() => showCreateEditMsgGroupModal()}
            >
              <Icon icon={Add} className="me-1" />
              New chat
            </BDButton>
          </div>

          <BDSearchInput
            className="mb-2"
            ref={searchInputRef}
            submitsearch={filterGroups}
            clearBtn
            submitOnClear
          />

          <OverflowContainer containerClassName="p-0">
            {filteredGroups.sort((a, b) => (a.isFavorite ? -1 : b.isFavorite ? 1 : 0)).map((g) => (
              <ChatbotSidebarItem
                key={g.id}
                msgGroup={g}
                active={g.id === msgGroupId}
                onSetFavorite={submitMsgGroupIsFavorite}
                onEditClick={showCreateEditMsgGroupModal}
                onDeleteClick={onDeleteMsgGroup}
                onClick={onItemClick}
                disable={g.id === msgGroupId}
              />
            ))}

          </OverflowContainer>
        </SidebarBody>
      </Sidebar>

      <CreateEditModal
        active={createEditMsgGroupModal.show}
        loading={createEditMsgGroupModalLoading}
        onClose={closeCreateEditMsgGroupModal}
        title={createEditMsgGroupModal.isEdit ? 'Edit section' : 'Create section'}
        fields={msgGroupFormFields}
        initialValues={{ ...createEditMsgGroupModal.values }}
        isEditing={createEditMsgGroupModal.isEdit}
        onSubmit={onSubmitCreateEditMsgGroup}
      />
    </>
  );
}

export default ChatbotSidebarBase;
