import classNames from 'classnames';
import { Bookmark, BookmarkBorder, FitScreen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IChatbotMessage } from '../Chatbot.service';
import Icon from '../../../../Components/Icon/Icon';
import BDButton from '../../../../Components/BDButton/BDButton';
import BDText from '../../../../Components/BDText/BDText';
import ChatbotDataVisualization, { CHATBOT_PREVIEW_MODE_LIMIT } from '../ChatbotDataVisualization/ChatbotDataVisualization';

export interface IChatbotMessageComponent {
  msg: IChatbotMessage;
  onSuggestionClick?(question: string): void;
  onSaveInsights(userPromptMsgId: string): void;
  onUnsaveInsights(userPromptMsgId: string): void;
  className?: string;
}

function ChatbotMessageBase({
  msg,
  className,
  onSuggestionClick,
  onSaveInsights,
  onUnsaveInsights
}: IChatbotMessageComponent): JSX.Element {
  const navigate = useNavigate();
  const { message: text, insight, suggestions } = msg;

  const dataElement = insight?.data && insight.visualization ? (
    <div className="msg-insight">
      <ChatbotDataVisualization data={insight.data} config={insight.visualization} previewMode />
      {(insight.data.length > CHATBOT_PREVIEW_MODE_LIMIT) && (
        <div className="preview-mode">
          <BDText textcolor="danger" textbold>PREVIEW MODE</BDText>
        </div>
      )}
    </div>
  ) : null;

  const suggestionsElement = suggestions && (
    <div className="suggestions-container bot-msg">
      <div className="ms-1">{suggestions.text ?? null}</div>
      <div className="suggestions mt-2">
        {suggestions.suggestions && suggestions.suggestions.map((suggestion, i) => (
          <div
            key={i}
            className="suggestion-item px-3 py-1 me-2 mb-2 rounded-pill"
            onClick={() => onSuggestionClick?.(suggestion)}
          >
            {suggestion}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={classNames(className, 'mb-3')}>
      {(text || dataElement) && (
        <div className={classNames('msg-container', msg.sender === 'bot' ? 'bot-msg' : undefined)}>
          <div className="msg-body">
            {text}

            {/* TODO if no visualization, show data table */}
            {dataElement}
          </div>

          {(msg.sender === 'bot' && msg.userPromptMsgId && insight?.data?.length) ? (
            <div className="msg-footer">
              <div className="d-flex-row-start-center">
                <BDButton
                  variant="secondary"
                  transparent
                  onClick={() => (msg.bookmarkId ? onUnsaveInsights(msg.bookmarkId as string)
                    : onSaveInsights(msg.userPromptMsgId as string))}
                >
                  <Icon icon={msg.bookmarkId ? Bookmark : BookmarkBorder} />
                  Save
                </BDButton>
              </div>

              <BDText textsize="xs">{moment(msg.createdAt).format('HH:MM:SS').toString()}</BDText>
              <BDButton variant="secondary" transparent onClick={() => navigate(`/explore/preview/${msg.userPromptMsgId}`)}>
                <Icon icon={FitScreen} />
              </BDButton>
            </div>
          ) : undefined}
        </div>
      )}

      {suggestionsElement}
    </div>
  );
}

export default ChatbotMessageBase;
