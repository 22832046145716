import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDHFxgYaa7EwV-O4YBZuW6MXlWQaJOfiPk',
  authDomain: 'butterdata.firebaseapp.com',
  projectId: 'butterdata',
  storageBucket: 'butterdata.appspot.com',
  messagingSenderId: '319750705897',
  appId: '1:319750705897:web:6e70d52f10fab161a4d678'
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account'});

export const signInWithGoogle = (): Promise<any> => signInWithPopup(auth, provider);

export default app;
