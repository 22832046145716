/* eslint-disable react/button-has-type */
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import validateFormValues from '../../../Utils/validate-schema';
import Loader from '../../../Components/Loader/Loader.style';
import { createNewTenant } from '../../../Store/Reducers/UserContext/SessionActions';

import { selectUserContextLoading } from '../../../Store/Reducers/UserContext/SessionSelector';
import useAction from '../../../Store/utils/useAction';

const createTenantFormSchema = yup.object({
  name: yup.string().required(),
  shortName: yup.string().required(),
  profile: yup.string().optional(),
});

function TenantForm(): JSX.Element {
  const submitCreateNewTenant = useAction(createNewTenant);
  const navigate = useNavigate();
  const [ credentialData, setCredentialData ] = useState<any>(null);
  const [ credentialError, setCredentialError ] = useState<string | null>(null);
  const loading = useSelector(selectUserContextLoading);

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    if (!credentialData) {
      toast.error('Please upload credential data (JSON)');
      return;
    }

    submitCreateNewTenant({
      payload: {
        ...values,
        credentialData
      },
      navigate
    });
  };

  const onFileChange = (event: any): void => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (e: any): void => {
      setCredentialError(null);
      try {
        const data = JSON.parse(e.target.result);
        setCredentialData(data);
        setCredentialError(null);
      } catch (err) {
        setCredentialError('Please choose valid json file');
      }
    };
  };

  return (
    <Formik
      initialValues={{}}
      validate={validateFormValues(createTenantFormSchema)}
      onSubmit={onSubmit}
    >
      <Form className="auth-form-container">
        {loading && <Loader overlay="#FFFFFF55" />}

        <h4>Tenant data</h4>

        <div className="auth-form-inputs">
          <label className="mt-1">Tenant name</label>
          <Field className="tenant-form" type="text" placeholder="name" name="name" />
          <label className="validation-error mt-0"><ErrorMessage name="name" /></label>

          <label className="mt-1">Short name</label>
          <Field className="tenant-form" type="text" placeholder="short name" name="shortName" />
          <label className="validation-error mt-0"><ErrorMessage name="shortName" /></label>

          <label className="mt-1">Tenant profile</label>
          <Field className="tenant-form" type="text" placeholder="tenant profile" name="profile" />
          <label className="validation-error mt-0"><ErrorMessage name="profile" /></label>

          <label className="mt-1">Tenant credential file</label>
          <input className="form-control" type="file" onChange={onFileChange} accept=".json" />
          <label className="validation-error mt-0">
            {credentialError}
          </label>
        </div>

        <button className="submit-button mt-2 py-2" type="submit">CREATE TENANT</button>
      </Form>
    </Formik>
  );
}

export default TenantForm;
