import Spinner, { ISpinner } from '../Spinner/Spinner';

export interface ILoaderProps extends Omit<ISpinner, 'ref'> {
  overlay?: string;
  className?: string
}

function LoaderBase({
  size, className, ...props
}: ILoaderProps): JSX.Element {
  return (
    <div className={className}>
      <Spinner {...props} size={size ?? 20} />
    </div>
  );
}

export default LoaderBase;
