import styled from 'styled-components';
import { useMemo } from 'react';
import { IMetadataField } from '../../../Utils/metadata';

export interface ILegendRenderer {
  payload?: any[];
  fields: IMetadataField[];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 1rem;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color ?? 'white'};
  
  span {
    width: 1.1rem;
    height: 1.1rem;
    background-color: ${(props) => props.color};
  }
`;

const LegendRenderer = (props: ILegendRenderer): JSX.Element | null => {
  const { payload, fields } = props;

  const texts = useMemo(() => payload?.map((entry: Record<string, any>, index) => {
    const field = fields.find((f) => f.id === entry.dataKey);
    if (!field) {
      return null;
    }

    return (
      <ItemContainer className="me-3 " key={`item-${index}`} color={entry.color}>
        <span className="rounded-circle border border-2 me-1" />
        {field.label}
      </ItemContainer>
    ); }), [ payload ]);

  if (!payload) {
    return null;
  }

  return (
    <Container>
      {texts}
    </Container>
  );
};

export default LegendRenderer;
