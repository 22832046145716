import { useEffect, useRef } from 'react';
import { IUserContext } from '../../Types';

interface ISlackVerified {
  userContext: IUserContext;
  slackUserId: string;
  channel: string;
  onSubmit: (values: Record<string, any>, redirectToHome?: boolean) => void;
}

function SlackVerified({
  userContext, slackUserId, channel, onSubmit
}: ISlackVerified): JSX.Element {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      return;
    }
    didMount.current = true;

    onSubmit({
      slackUserId,
      channel,
      tenant: userContext.tenant.id,
      accessToken: userContext.accessToken
    }, false);
  }, []);

  return (
    <div className="auth-form-container pb-4 text-white">
      <h3 className="mb-4">
        Thanks you
        {' '}
        {userContext.user.fullname}
        !
      </h3>
      Your slack account has successfully verified
      you should received a message from our bot app for the confirmation
    </div>
  );
}

export default SlackVerified;
