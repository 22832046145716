import {
  useState, useRef, useMemo, useEffect
} from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import BDButton from '../../../../Components/BDButton/BDButton';
import Loader from '../../../../Components/Loader/Loader.style';
import BDText from '../../../../Components/BDText/BDText';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import { IRole, IUser, IUserContext } from '../../../../Types';
import { IAddExistingUserPayload, addExistingUser, getAllUsers } from '../../../../Services/users';
import { swal } from '../../../../Utils/swal';

export interface IAddExistingUser {
  userContext: IUserContext;
  tenantUsers: IUser[];
  roles: IRole[];
  active: boolean;
  onClose(): void;
  onSubmitSuccess(): void;
}

function AddExistingUser({
  userContext,
  tenantUsers,
  roles,
  active,
  onClose,
  onSubmitSuccess
}: IAddExistingUser): JSX.Element {
  const tenantUserIds = useMemo(() => tenantUsers.map((u) => u.id), [ tenantUsers ]);
  const navigate = useNavigate();
  const userInputRef = useRef<any>(null);
  const roleInputRef = useRef<any>(null);
  const [ loading, setLoading ] = useState(false);
  const [ users, setUsers ] = useState<IUser[]>([]);

  useEffect(() => {
    getUsers();
  }, [ tenantUserIds ]);

  const getUsers = callApiWrapper(async (): Promise<void> => {
    const userList = await getAllUsers();
    setUsers(userList.filter((u) => !tenantUserIds.includes(u.id)));
  }, setLoading, navigate);

  const submitAddUser = callApiWrapper(async (payload: IAddExistingUserPayload): Promise<void> => {
    await addExistingUser({ ...payload });
  }, setLoading, navigate);

  const submit = async (): Promise<void> => {
    if (!userInputRef.current || !roleInputRef.current) {
      return;
    }

    const userValue = userInputRef.current.getValue();
    const roleValue = roleInputRef.current.getValue();
    if (!userValue.length || !roleValue.length) {
      return;
    }

    const selectedUserId = userValue[0].value;
    const selectedRoleId = roleValue[0].value;
    swal({
      title: 'Are you sure?',
      onConfirmCallback: async () => {
        await submitAddUser({
          user: selectedUserId,
          role: selectedRoleId,
          tenant: userContext.tenant.id
        });
        onSubmitSuccess();
        onClose();
      }
    });
  };

  return (
    <Modal
      show={active}
      onHide={onClose}
      backdrop="static"
      centered
    >
      <Modal.Header className="py-2">
        <BDText textbold textsize="md">Add existing user</BDText>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader className="rounded-3" overlay="#FFFFFF99" size={16} />}

        <BDText className="mb-1" textsemibold>User</BDText>
        <Select
          ref={userInputRef}
          className="mb-4"
          options={users.map((u) => ({ value: u.id, label: u.fullname }))}
        />

        <BDText className="mb-1" textsemibold>Role</BDText>
        <Select
          ref={roleInputRef}
          options={roles.map((r) => ({ value: r.id, label: r.name }))}
        />
      </Modal.Body>
      <Modal.Footer className="py-2">
        <BDButton variant="success" onClick={submit} disabled={loading}>
          Save
        </BDButton>
        <BDButton variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </BDButton>
      </Modal.Footer>
    </Modal>
  );
}

export default AddExistingUser;
