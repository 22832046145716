import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IFormFieldProps } from '../BDFormField';
import {
  ITimePreset, TimePresetEnum, TimePresetOptions, getTimePresetValueByTag
} from './BDTimePreset.service';
import BDText from '../../BDText/BDText';
import BDTextInput from '../../BDTextInput/BDTextInput';

export interface IBDFormTimePreset extends IFormFieldProps {
  className?: string;
}

const Select = styled.select`
  width: 100%;
  min-width: 120px;
  padding: 0.6rem 1rem 0.6rem 1rem;

  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.muted}EE;
  outline: none;
  box-shadow: 0 0 0 ${(props) => props.theme.colors.primary}EE;
  transition: all ease 0.15s;

  :focus {
    border-color: ${(props) => props.theme.colors.primary}EE;
    box-shadow: 0 0 1px 4px ${(props) => props.theme.colors.primary}99;
  }
`;

function BDFormTimePresetInputBase({
  field,
  form,
  label,
  values,
  loading,
  disabled,
  className
}: IBDFormTimePreset): JSX.Element {
  const { name, value } = field;
  const { setValues } = form;

  useEffect(() => {
    setTimePresetValue({ ...value });
  }, [ value ]);

  const [ timePresetValue, setTimePresetValue ] = useState<ITimePreset>(value ?? getTimePresetValueByTag(TimePresetEnum.CUSTOM));

  const onPresetChange = (e: any): void => {
    const preset = getTimePresetValueByTag(e.target.value);
    setTimePresetValue(preset);

    form.setValues({
      ...form.values,
      [field.name]: { ...preset }
    });
  };

  const onDateChange = (d: 'start' | 'end', v: string): void => {
    const preset = {
      ...timePresetValue,
      tag: TimePresetEnum.CUSTOM,
      [d]: v
    };

    setTimePresetValue(preset);
    setValues({
      ...values,
      [name]: preset
    });
  };

  return (
    <div className={className}>
      <div className="bd-time-preset mb-1 me-2">
        <BDText className="mb-1" wrap="nowrap" textsemibold>{label}</BDText>
        <Select
          className="form-control w-100"
          value={timePresetValue.tag}
          onChange={onPresetChange}
          disabled={disabled || loading}
        >
          {TimePresetOptions.map((o) => (
            <option key={o.id} value={o.id}>{o.label}</option>
          ))}
        </Select>
      </div>
      <BDTextInput
        className="mb-1 me-2"
        type="DATE"
        value={timePresetValue.start}
        onChange={(e: any) => onDateChange('start', e.target.value)}
        disabled={disabled || loading}
      />
      <BDTextInput
        className="mb-1 me-2"
        type="DATE"
        value={timePresetValue.end}
        onChange={(e: any) => onDateChange('end', e.target.value)}
        disabled={disabled || loading}
      />
    </div>
  );
}

const BDFormTimePresetInput = styled(BDFormTimePresetInputBase)<IBDFormTimePreset>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  
  width: 100%;

  .bd-time-preset {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    
    width: 100%;
    min-width: 180px;
  }
`;

export default BDFormTimePresetInput;
