import { useMemo } from 'react';
import styled from 'styled-components';
import DataVisualization from '../../../../Components/DataVisualization/DataVisualization';
import {
  getColumnType, getFieldLabel
} from '../../../../Components/DataVisualization/DataVisualization.service';
import { IMetadataField } from '../../../../Utils/metadata';
import { IChatbotDataVisualization } from './ChatbotDataVisualization';

interface IContainerProps {
  width?: number;
  height?: number;
}
const Container = styled.div<IContainerProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '240px')};
  margin-bottom: -1rem;
`;

function ChatbotCommonChart({
  data, config, width, height
}: IChatbotDataVisualization): JSX.Element | null {
  const xField: IMetadataField | undefined = useMemo(() => {
    if (config.xdatakey) {
      return config.xdatakey;
    }

    const datakeys = data.length && Object.keys(data[0]);
    if (!datakeys || !datakeys.length) {
      return undefined;
    }

    return {
      id: datakeys[0],
      label: getFieldLabel(datakeys[0]),
      type: getColumnType(datakeys[0])
    };
  }, [ config.xdatakey ]);

  const yFields = useMemo(() => {
    if (config.ydatakeys) {
      return [ ...config.ydatakeys ];
    }
    if (!data.length) {
      return undefined;
    }

    const keys = Object.keys(data[0]);
    if (keys.length < 2) {
      return undefined;
    }

    keys.shift();
    return keys.map((key) => ({
      id: key,
      label: getFieldLabel(key),
      type: getColumnType(key)
    }));
  }, [ data ]);

  if (!xField || !yFields || !yFields.length) {
    return null;
  }
  const vizConfig = {
    ...config,
    xField,
    yFields
  };

  return (
    <Container width={width} height={height}>
      <DataVisualization
        data={data}
        config={vizConfig}
      />
    </Container>
  );
}

export default ChatbotCommonChart;
