import { PropsWithChildren, useEffect } from 'react';
import { Form } from 'formik';
import { IFormProps } from './BDForm';

export interface IFormContainer extends IFormProps {
  onChange?(values: Record<string, any>): void;
  className?: string;
}

function FormContainer({
  onChange, values, className, children
}: PropsWithChildren<IFormContainer>): JSX.Element {
  useEffect(() => {
    onChange?.({ ...values });
  }, [ values ]);

  return (
    <Form className={className}>
      {children}
    </Form>
  );
}

export default FormContainer;
