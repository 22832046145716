/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  takeLatest,
  select,
  put,
  all,
  fork
} from 'redux-saga/effects';
import { clone } from 'ramda';
import {
  IDataExploration, createDataExploration, deleteDataExploration, getDataExplorations, updateDataExploration,
} from '../../../Pages/Explore/Explore.service';
import { selectExplorations } from './ExplorationsSelector';
import { sagaWrapper, sagaWrapperWithLoading } from '../../utils/saga-utils';
import {
  getExplorations,
  setExplorations,
  setExplorationsLoading,
  createExploration,
  updateExploration,
  deleteExploration,
  ISetExplorationIsFavoriteAction,
  setExplorationIsFavorite,
  ICreateExplorationAction,
  IUpdateExplorationAction
} from './Explorations';
import { IReducerAction } from '../../../Types';

function* setLoading(value: boolean) {
  yield put(setExplorationsLoading(value));
}

function* fetchExplorations() {
  const explorations: IDataExploration[] = yield getDataExplorations();
  yield put(setExplorations(explorations));
}

function* submitCreateExploration(action: ICreateExplorationAction) {
  yield createDataExploration({ ...action.payload });
  yield put(getExplorations());
}

function* submitUpdateExploration(action: IUpdateExplorationAction) {
  yield updateDataExploration({ ...action.payload });
  yield put(getExplorations());
}

function* submitDeleteExploration(action: IReducerAction<string>) {
  yield deleteDataExploration(action.payload);
  yield put(getExplorations());
}

function* SubmitSetExplorationIsFavorite(action: ISetExplorationIsFavoriteAction) {
  const exploration: IDataExploration = yield updateDataExploration({
    id: action.payload.explorationsId,
    isFavorite: action.payload.isFavorite
  });
  const currentExplorations: IDataExploration[] = yield select(selectExplorations);
  const explorationsClone = clone(currentExplorations);
  const currentExploration = explorationsClone.find((g) => g.id === exploration.id);
  if (currentExploration) {
    currentExploration.isFavorite = exploration.isFavorite;
  }
  yield put(setExplorations(explorationsClone));
}

function* getExplorationsSaga() {
  yield takeLatest(
    getExplorations.toString(),
    function* () { yield sagaWrapper(fetchExplorations); }
  );
}

function* createExplorationSaga() {
  yield takeLatest(
    createExploration.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitCreateExploration, setLoading, action); }
  );
}

function* updateExplorationSaga() {
  yield takeLatest(
    updateExploration.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitUpdateExploration, setLoading, action); }
  );
}

function* deleteExplorationSaga() {
  yield takeLatest(
    deleteExploration.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitDeleteExploration, setLoading, action); }
  );
}

function* setExplorationIsFavoriteSaga() {
  yield takeLatest(
    setExplorationIsFavorite.toString(),
    function* (action: any) { yield sagaWrapper(SubmitSetExplorationIsFavorite, action); }
  );
}

function* dataSaga() {
  yield all([
    fork(getExplorationsSaga),
    fork(createExplorationSaga),
    fork(updateExplorationSaga),
    fork(deleteExplorationSaga),
    fork(setExplorationIsFavoriteSaga)
  ]);
}

export default dataSaga;
