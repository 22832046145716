import {
  Delete, Edit, MoreHoriz, Star
} from '@mui/icons-material';
import { Button, Dropdown } from 'react-bootstrap';
import { MouseEvent } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import BDText from '../../../Components/BDText/BDText';
import Icon from '../../../Components/Icon/Icon';
import BDDropdownContainer from '../../../Components/BDDropdown/BDDropdownContainer';
import { IDataExplorationItem } from '../ExploreSidebar';

export interface IExploreSidebarItem {
  exploration: IDataExplorationItem;
  active: boolean;
  onSetFavorite?: (explorationId: string, isFavorite: boolean) => void;
  onEditClick?: (exploration: IDataExplorationItem) => void;
  onDeleteClick?: (exploration: IDataExplorationItem) => void;
  onClick: (exploration: IDataExplorationItem) => void;
  className?: string;
}

const Text = styled(BDText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

function ExploreSidebarItemBase({
  exploration,
  active,
  onSetFavorite, onEditClick, onDeleteClick,
  onClick,
  className
}: IExploreSidebarItem): JSX.Element {
  const { id, name } = exploration;

  const click = (e: MouseEvent<HTMLDivElement>): void => {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    onClick(exploration);
  };

  return (
    <div className={classNames(className, active ? 'active' : '')} onClick={click}>
      {exploration.isFavorite && <Icon icon={Star} className="me-2" color="primary" />}

      <Text textsemibold textsize="xs">{name}</Text>

      {active && (
        <div className="explore-sidebar-item-actions">
          <BDDropdownContainer noarrow transparent>
            <Dropdown.Toggle as={Button} className="py-0 px-1 rounded-2" id="chatbot-sidebar-item-action-dropdown">
              <Icon icon={MoreHoriz} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onSetFavorite?.(id, !exploration.isFavorite)}>
                <Icon icon={Star} className="me-2" color={exploration.isFavorite ? 'primary' : 'default'} />
                {exploration.isFavorite ? 'Unstar' : 'Star'}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onEditClick?.(exploration)}>
                <Icon className="me-2" icon={Edit} />
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onDeleteClick?.(exploration)}>
                <Icon className="me-2" icon={Delete} color="danger" />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </BDDropdownContainer>
        </div>
      )}
    </div>
  );
}

export default ExploreSidebarItemBase;
