import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';
import { getDefaultColor } from '../DataVisualization.service';
import { ILineChartComponent } from './LineChart';
import ChartTickRenderer from '../ChartComponents/ChartTickRenderer';

export type IBarChartComponent = ILineChartComponent

function BarChartComponent({
  data,
  config,
  width,
  height,
  xTickFormatter,
  yTickFormatter,
  legend,
  tooltip
}: IBarChartComponent): JSX.Element {
  const xAxis = (
    <XAxis
      dataKey={config.xField.id}
      tickFormatter={xTickFormatter}
      tick={<ChartTickRenderer formatter={xTickFormatter} />}
      color="white"
    />
  );

  const ydatakeysWithColor = useMemo(() => config.yFields.reduce((acc: Record<string, any>, curr, currIndex) => {
    acc[curr.id] = getDefaultColor(currIndex);
    return acc;
  }, {}), [ config.yFields ]);

  const bars = Object.keys(ydatakeysWithColor).map((datakey, index) => (
    <Bar key={index} type="monotone" dataKey={datakey} fill={ydatakeysWithColor[datakey]} />
  ));

  return (
    <ResponsiveContainer width={width ?? '100%'} height={height ?? '100%'}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {xAxis}
        <YAxis
          color="white"
          tick={<ChartTickRenderer formatter={yTickFormatter} />}
        />
        {legend}
        {tooltip}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartComponent;
