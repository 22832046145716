import styled from 'styled-components';
import HomeBase from './Home';

const Home = styled(HomeBase)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  background-color: ${(props) => props.theme.main.primaryBg};
  color: ${(props) => props.theme.main.primaryColor}
`;

export default Home;
