import { PropsWithChildren, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  Settings, MoreHoriz, Nightlight, Person, Logout, SupportAgent
} from '@mui/icons-material';
import { Button, Dropdown } from 'react-bootstrap';
import withScreenSize, { IWithScreenSize, SizeBreakpoints } from '../HOC/WithScreenSize';
import { HideChatbotSidebar, HideChatbotSidebarToggler, ShowChatbotSidebarToggler } from '../../Store/Reducers/Layout/LayoutActions';
import { selectSidebarState } from '../../Store/Reducers/Layout/LayoutSelector';
import BDButton from '../BDButton/BDButton';
import Icon from '../Icon/Icon';
import UserAvatar from '../UserAvatar/UserAvatar.style';
import BDDropdownContainer from '../BDDropdown/BDDropdownContainer';
import { ITenant, IUserContext } from '../../Types';
import { switchTenant as sTenant, getUserTenants } from '../../Store/Reducers/UserContext/SessionActions';
import { selectTenant, selectUserTenants } from '../../Store/Reducers/UserContext/SessionSelector';
import BDText from '../BDText/BDText';
import useAction from '../../Store/utils/useAction';
import { deleteSession as dSession } from '../../Store/Reducers/UserContext/Session';

export interface ISidebar extends IWithScreenSize {
  userContext: IUserContext;
  defaultFooter?: boolean;
  className?: string
}

function SidebarBase({
  userContext, defaultFooter, screenSize, className, children
}: PropsWithChildren<ISidebar>): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getTenants = useAction(getUserTenants);
  const switchTenant = useAction(sTenant);
  const deleteSession = useAction(dSession);
  const userTenants = useSelector(selectUserTenants);
  const currentTenant = useSelector(selectTenant);

  useEffect(() => {
    getTenants();
  }, []);

  // 'show' or 'hidden' only effect on MD breakpoint
  // when the screen size is higher than MD it will not use 'show' classname, but still shows up in the UI
  // because 'show' classname only affect if the breakpoint is MD or lower
  const sidebarState = useSelector(selectSidebarState());

  useEffect(() => {
    // hide sidebar toggler if screen size breapoint is greater or equal than MD
    if (screenSize.breakpoint >= SizeBreakpoints.MD) {
      dispatch(HideChatbotSidebar());
      dispatch(HideChatbotSidebarToggler());
      return;
    }

    // if sidebar is hidden show toggler in navbar, else hide the toggler
    if (sidebarState.hidden) {
      dispatch(ShowChatbotSidebarToggler());
    } else {
      dispatch(HideChatbotSidebarToggler());
    }
  }, [ screenSize.breakpoint, sidebarState.hidden ]);

  const onOverlayClick = (): void => { dispatch(HideChatbotSidebar()); };

  async function onSwitchTenant(t: ITenant): Promise<void> {
    if (t.id === userContext.tenant.id) {
      return;
    }

    switchTenant({ tenantId: t.id, navigate });
  }

  async function onLogoutClick(): Promise<void> {
    deleteSession();
    navigate('/auth/signin');
  }

  const sidebarVisibility = screenSize.breakpoint <= SizeBreakpoints.SM && sidebarState.hidden ? 'collapse' : 'visible';

  return (
    <div className={className} style={{ visibility: sidebarVisibility }}>
      <div className="sidebar-container">
        <div className="sidebar-header px-3" style={{ zIndex: 100 }}>
          <div className="px-5 py-3">
            <div
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            >
              <img
                src="/images/butter-data-banner.png"
                alt="/images/butter-data-banner.png"
                style={{
                  width: '100%',
                  height: '48px',
                }}
              />
            </div>
          </div>
          {userTenants.length < 1 ? (
            <BDButton className="w-100 px-3 mt-2 mb-4 d-flex-row-between-center" transparent id="chatbot-sidebar-tenants-dropdown">
              <BDText textcolor="dark" textsemibold className="me-auto">{currentTenant?.name}</BDText>
            </BDButton>
          ) : (
            <BDDropdownContainer className="d-flex-row-center-center" drop="down">
              <Dropdown.Toggle
                className="w-100 px-3 mt-2 mb-3 d-flex-row-between-center text-dark"
                as={Button}
                variant="outline-light"
                transparent
                id="chatbot-sidebar-tenants-dropdown"
              >
                <BDText textcolor="dark" textsemibold className="me-auto">{currentTenant?.name}</BDText>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userTenants.map((t) => (
                  <Dropdown.Item key={t.id} onClick={() => onSwitchTenant(t)}>
                    {t.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </BDDropdownContainer>
          )}
        </div>

        {children}

        {defaultFooter && (
          <SidebarFooter className="px-3 pb-4">
            {!matchPath('/settings/*', location.pathname) && (
              <>
                <SidebarSeparator />
                <BDButton className="w-100 rounded-0 py-3" variant="default" transparent onClick={() => navigate('/settings/users')}>
                  <Icon icon={Settings} className="me-2" />
                  Settings
                </BDButton>
              </>
            )}

            <SidebarSeparator />
            <div className="d-flex flex-row justify-content-around align-items-center py-3">
              <UserAvatar user={userContext.user} showUsername showEmail size="xl" />
              <BDDropdownContainer noarrow drop="up">
                <Dropdown.Toggle as={BDButton} transparent id="chatbot-sidebar-item-action-dropdown">
                  <Icon icon={MoreHoriz} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Icon icon={Person} className="me-2" color="default" />
                    View Profile
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon icon={Nightlight} className="me-2" color="default" />
                    Dark Mode
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon icon={SupportAgent} className="me-2" color="default" />
                    Help Center
                  </Dropdown.Item>
                  <Dropdown.Item className="text-danger" onClick={onLogoutClick}>
                    <Icon icon={Logout} className="me-2" />
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </BDDropdownContainer>
            </div>
          </SidebarFooter>
        )}
      </div>
      {screenSize.breakpoint <= SizeBreakpoints.MD && !sidebarState.hidden && (
        <div className="sidebar-overlay" onClick={onOverlayClick} />
      )}
    </div>
  );
}

interface ISidebarChild {
  className?: string;
}

export function SidebarBody({ children, className }: PropsWithChildren<ISidebarChild>): JSX.Element {
  return (
    <div className={classNames('sidebar-body', className)}>
      {children}
    </div>
  );
}

export function SidebarFooter({ children, className }: PropsWithChildren<ISidebarChild>): JSX.Element {
  return (
    <div className={classNames('sidebar-footer ', className)}>
      {children}
    </div>
  );
}

export function SidebarSeparator(): JSX.Element {
  return (
    <div className="sidebar-separator" />
  );
}

export default withScreenSize(SidebarBase);
