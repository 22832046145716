import styled from 'styled-components';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../Utils/common';
import SidebarBase from './Sidebar';

const Sidebar = styled(SidebarBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  z-index: 10;
  overflow: hidden;
  
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 100%;

  @media screen and (max-width: ${MD_BREAKPOINT}px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 0.8rem !important;
    }

    .sidebar-header {
      width: 100%;

      #chatbot-sidebar-tenants-dropdown {
        box-shadow: 0 0 4px 2px #00000009;
      }
    }
    .sidebar-body {
      width: 100%;
      height: 100%;
      max-height: 100%;
      flex-grow: 100;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      > * {
        padding: 0 1rem;
      }
    }
    .sidebar-footer {
      width: 100%;
    }

    .sidebar-separator {
      border-bottom: 1px solid ${(props) => props.theme.colors.dark};
    }
  }

  .sidebar-overlay {
    width: 100%;
    height: 100%;
    background-color: #00000040;
  }
`;
Sidebar.displayName = 'Sidebar';

export default Sidebar;
