/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  takeLatest,
  select,
  put,
  all,
  fork
} from 'redux-saga/effects';
import { clone } from 'ramda';
import {
  IMessageGroup,
  createMessageGroup,
  deleteMessageGroup,
  editMessageGroup,
  getUserMessageGroups,
} from '../../../Pages/Home/Chatbot/Chatbot.service';
import { selectMsgGroups } from './MsgGroupsSelector';
import {
  ICreateMsgGroupAction,
  IDeleteMsgGroupAction,
  IEditMsgGroupAction,
  ISetMsgGroupIsFavoriteAction,
  getMsgGroupsAction,
  createMsgGroup,
  editMsgGroupAction,
  deleteMsgGroupAction,
  setMsgGroupIsFavorite
} from './MsgGroupsActions';
import { sagaWrapper, sagaWrapperWithLoading } from '../../utils/saga-utils';
import { setMsgGroups, setMsgGroupsLoading } from './MsgGroups';

function* setLoading(value: boolean) {
  yield put(setMsgGroupsLoading(value));
}

function* fetchMsgGroups() {
  const msgGroups: IMessageGroup[] = yield getUserMessageGroups();
  yield put(setMsgGroups(msgGroups));
}

function* submitCreateMsgGroup(action: ICreateMsgGroupAction) {
  yield createMessageGroup({ ...action.payload });
  yield put(getMsgGroupsAction());
}

function* submitEditMsgGroup(action: IEditMsgGroupAction) {
  yield editMessageGroup({ ...action.payload });
  yield put(getMsgGroupsAction());
}

function* submitDeleteMsgGroup(action: IDeleteMsgGroupAction) {
  yield deleteMessageGroup(action.payload);
  yield put(getMsgGroupsAction());
}

function* SubmitSetMsgGroupIsFavorite(action: ISetMsgGroupIsFavoriteAction) {
  const group: IMessageGroup = yield editMessageGroup({ ...action.payload });
  const currentGroups: IMessageGroup[] = yield select(selectMsgGroups);
  const groups = clone(currentGroups);
  const groupInList = groups.find((g) => g.id === group.id);
  if (groupInList) {
    groupInList.isFavorite = group.isFavorite;
  }
  yield put(setMsgGroups(groups));
}

function* getMsgGroupsSaga() {
  yield takeLatest(
    getMsgGroupsAction.toString(),
    function* () { yield sagaWrapper(fetchMsgGroups); }
  );
}

function* createMsgGroupSaga() {
  yield takeLatest(
    createMsgGroup.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitCreateMsgGroup, setLoading, action); }
  );
}

function* editMsgGroupSaga() {
  yield takeLatest(
    editMsgGroupAction.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitEditMsgGroup, setLoading, action); }
  );
}

function* deleteMsgGroupSaga() {
  yield takeLatest(
    deleteMsgGroupAction.toString(),
    function* (action: any) { yield sagaWrapperWithLoading(submitDeleteMsgGroup, setLoading, action); }
  );
}

function* setMsgGroupIsFavoriteSaga() {
  yield takeLatest(
    setMsgGroupIsFavorite.toString(),
    function* (action: any) { yield sagaWrapper(SubmitSetMsgGroupIsFavorite, action); }
  );
}

function* dataSaga() {
  yield all([
    fork(getMsgGroupsSaga),
    fork(createMsgGroupSaga),
    fork(editMsgGroupSaga),
    fork(deleteMsgGroupSaga),
    fork(setMsgGroupIsFavoriteSaga)
  ]);
}

export default dataSaga;
