import { useMemo } from 'react';
import { Legend, Tooltip } from 'recharts';
import BarChartComponent from './Charts/BarChart';
import LineChartComponent from './Charts/LineChart';
import {
  CommonChartTypes,
  IDataVizConfig,
  dateFormatter, getFieldFormatter, isAllFieldSameType, isDateFormat
} from './DataVisualization.service';
import LegendRenderer from './ChartComponents/LegendRenderer';
import TooltipRenderer from './ChartComponents/TooltipRenderer';
import { IDataVisualization } from './DataVisualization';
import { IMetadataField } from '../../Utils/metadata';

export interface ICommonChartConfig extends IDataVizConfig {
  type: CommonChartTypes;
  xtitle?: string;
  xField: IMetadataField;
  ytitle?: string;
  yFields: IMetadataField[];
}

export interface ICommonChart extends IDataVisualization {
  config: ICommonChartConfig;
}

function CommonChart({
  data, config, width, height
}: ICommonChart): JSX.Element | null {
  let xTickFormatter = useMemo(() => getFieldFormatter(config.xField), [ config.xField ]);
  if (!xTickFormatter && data.length && isDateFormat(data[0][config.xField.id])) {
    xTickFormatter = dateFormatter;
  }

  const yTickFormatter = useMemo(() => {
    const isSameType = isAllFieldSameType(config.yFields);
    if (!isSameType) {
      return undefined;
    }

    return getFieldFormatter(isSameType);
  }, [ config.yFields ]);

  const legend = config.legend ? (
    <Legend content={<LegendRenderer fields={config.yFields} />} />
  ) : undefined;

  const tooltip = (
    <Tooltip
      wrapperStyle={{ outline: 'none' }}
      content={(
        <TooltipRenderer
          labelFormatter={xTickFormatter}
          fields={[ config.xField, ...config.yFields ]}
        />
      )}
    />
  );

  if (config.type === 'line') {
    return (
      <LineChartComponent
        width={width}
        height={height}
        data={data}
        config={config}
        xTickFormatter={xTickFormatter}
        yTickFormatter={yTickFormatter}
        legend={legend}
        tooltip={tooltip}
      />
    );
  }

  return (
    <BarChartComponent
      data={data}
      config={config}
      width={width}
      height={height}
      xTickFormatter={xTickFormatter}
      yTickFormatter={yTickFormatter}
      legend={legend}
      tooltip={tooltip}
    />
  );
}

export default CommonChart;
