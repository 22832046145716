import { useParams } from 'react-router-dom';
import { ICreateExplorationPayload } from './Explore.service';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../Components/HOC/withProtectedPageWrapper';
import ExploreInsights from './ExploreInsights';

export interface IExplorer extends IProtectedPageWrapper {
  previewMode?: boolean;
  onSaveClick?(payload: ICreateExplorationPayload): void;
}

function Explorer({
  userContext,
  previewMode,
  loading,
  setLoading,
  onSaveClick
}: IExplorer): JSX.Element {
  const { explorationId, messageId } = useParams();

  return (
    <ExploreInsights
      userContext={userContext}
      loading={loading}
      setLoading={setLoading}
      previewMode={previewMode}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      explorationId={(explorationId ?? messageId)!}
      onSaveClick={onSaveClick}
    />
  );
}

export default withProtectedPageWrapper(Explorer);
