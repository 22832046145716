import { HTMLProps, PropsWithChildren } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../Utils/common';

export interface IBDHeader extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'> {
  withpadding?: boolean;
}

const Container = styled.div<IBDHeader>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
    position: relative;

  width: 100%;

  ${(props) => (props.withpadding ? `
    padding: 1rem 2rem;
    
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 0.9rem 1.5rem;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 0.8rem 1rem;
    }
  ` : '')}

  .bd-header-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  .bd-header-left {
    justify-content: flex-start;
  }
  .bd-header-center {
    justify-content: center;
    width: 100%;
  }
  .bd-header-right {
    justify-content: flex-end;
  }
`;

function BDHeader({ children, ...props }: PropsWithChildren<IBDHeader>): JSX.Element {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}

export interface IBDHeaderChild {
  className?: string;
  style?: Record<string, any>
}

export function BDHeaderLeft({ children, className, ...props }: PropsWithChildren<IBDHeaderChild>): JSX.Element {
  return (
    <div className={classNames('bd-header-component bd-header-left', className)} {...props}>
      {children}
    </div>
  );
}

export function BDHeaderCenter({ children, className, ...props }: PropsWithChildren<IBDHeaderChild>): JSX.Element {
  return (
    <div className={classNames('bd-header-component bd-header-center', className)} {...props}>
      {children}
    </div>
  );
}

export function BDHeaderRight({ children, className, ...props }: PropsWithChildren<IBDHeaderChild>): JSX.Element {
  return (
    <div className={classNames('bd-header-component bd-header-right', className)} {...props}>
      {children}
    </div>
  );
}
export default BDHeader;
