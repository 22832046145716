import styled from 'styled-components';
import SavedInsightsCardBase, { ISavedInsightsCard } from './SavedInsightsCard';

const SavedInsightsCard = styled(SavedInsightsCardBase)<ISavedInsightsCard>`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  border-radius: 10px;
  box-shadow: 0 0 3px 0 #00000027;
  word-wrap: break-word;

  padding: 0.8rem 1rem 1.4rem 1rem;

  background-color: ${(props) => props.theme.colors.white}EE;

  ${(props) => {
    if (props.width) {
      return `
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: ${props.width}px;
      `;
    }
    return 'width: 100%;';
  }}
  height: ${(props) => (props.collapsedHeight ? props.collapsedHeight : 400)}px;
  &.fullview {
    height: auto;
  }

  &.unexpandable {
    padding-bottom: 0.8rem !important;
  }

  .unbookmark-btn {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
  }

  .showmore-btn {
    position: absolute !important;
    left: 1rem;
    bottom: 0.8rem;
  }

  .unbookmark-btn {
    position: absolute !important;
    right: 1rem;
    top: 0.8rem;
  }
`;
SavedInsightsCard.displayName = 'BookmarkedMessage';

export default SavedInsightsCard;
