import { callProtectedMainAPI, getMainEndpoint } from '../../Utils/call-api';
import { IExplorePayload, IExplore } from '../../Utils/exploration';
import { IMessage, IInsight, IMessageGroup } from '../Home/Chatbot/Chatbot.service';

export interface IPromptProcessConfig {
  insightId?: string;
  processId: string;
  queryId?: string;
  dataDependency?: string;
  subProcesses?: IPromptProcessConfig[];
}

export interface IPrompt {
  id: string;
  text: string;
  queryId: string
  processes?: IPromptProcessConfig[];
}

export interface IDataExploration {
  _id: string;
  id: string;
  name: string;
  user: string;
  userPromptId: string;
  prompt: IPrompt;
  variables?: Record<string, any>;
  insights: IInsight[]
  isFavorite?: boolean;
  archived?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ICreateExplorationPayload {
  name: string;
  userPromptId: string;
  prompt: IPrompt;
  variables?: Record<string, any>;
  insights: IInsight[];
}

export interface IPromptInsights extends IMessage {
  messageGroupName: string;
  messageGroupDetails: IMessageGroup;
  insights: IMessage[];
}

export interface IInsightsResponse {
  insights: IInsight[];
}

export const getDataExplorations = (): Promise<IDataExploration[]> => callProtectedMainAPI<IDataExploration[]>(getMainEndpoint('/data-explorations'));

export const exploreDataExplorations = (payload: IExplorePayload): Promise<IExplore<IDataExploration>> => callProtectedMainAPI(
  getMainEndpoint('/data-explorations/explore', 'POST'),
  { ...payload }
);

export const createDataExploration = (payload: Record<string, any>): Promise<IDataExploration> => callProtectedMainAPI(
  getMainEndpoint('/data-explorations', 'POST'),
  { ...payload }
);

export const quckSaveInsights = (userPromptId: string): Promise<string> => callProtectedMainAPI(getMainEndpoint(
  `/data-explorations/insights/save/${userPromptId}`,
  'POST'
));

export const updateDataExploration = (payload: Record<string, any>): Promise<IDataExploration> => callProtectedMainAPI(
  getMainEndpoint('/data-explorations', 'PATCH'),
  { ...payload }
);

export const deleteDataExploration = (dataExplorationId: string): Promise<string> => callProtectedMainAPI(getMainEndpoint(
  `/data-explorations/${dataExplorationId}`,
  'DELETE'
));
