import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface IRCChildProps {
  children(size: { width: number, height: number }): JSX.Element;
  className?: string;
}

function ResponsiveContainerBase({ children, className }: IRCChildProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ size, setSize ] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

  useEffect(() => {
    const onResize = (): void => {
      if (!containerRef.current) {
        return;
      }

      const containersize = containerRef.current.getBoundingClientRect();
      setSize({
        width: containersize.width,
        height: containersize.height
      });
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => { window.removeEventListener('resize', onResize); };
  }, []);

  return (
    <div className={className} ref={containerRef}>
      {children(size)}
    </div>
  );
}

const ResponsiveContainer = styled(ResponsiveContainerBase)`
  width: 100%;
  height: 100%;
  text-align: center;
`;

export default ResponsiveContainer;
