import {
  Delete, Edit, MoreHoriz, Star
} from '@mui/icons-material';
import { Button, Dropdown } from 'react-bootstrap';
import { MouseEvent } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { IMessageGroup } from '../../Chatbot.service';
import Icon from '../../../../../Components/Icon/Icon';
import BDDropdownContainer from '../../../../../Components/BDDropdown/BDDropdownContainer';
import BDText from '../../../../../Components/BDText/BDText';

export interface IChatbotSidebarItem {
  msgGroup: IMessageGroup;
  active: boolean;
  onSetFavorite: (payload: {id: string, isFavorite: boolean}) => void;
  onEditClick: (group: IMessageGroup) => void;
  onDeleteClick: (group: IMessageGroup) => void;
  onClick: (group: IMessageGroup) => void;
  disable?: boolean;
  className?: string;
}

const Text = styled(BDText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

function ChatbotSidebarItemBase({
  msgGroup,
  active,
  onSetFavorite, onEditClick, onDeleteClick,
  onClick,
  disable,
  className
}: IChatbotSidebarItem): JSX.Element {
  const { id, name, isFavorite } = msgGroup;

  const click = (e: MouseEvent<HTMLDivElement>): void => {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (disable) {
      return;
    }

    onClick(msgGroup);
  };

  return (
    <div className={classNames(className, active ? 'active' : '')} onClick={click}>
      {isFavorite && <Icon icon={Star} className="me-2" color="primary" />}

      <Text textsemibold textsize="xs">{name}</Text>

      {active && (
        <div className="chatbot-sidebar-item-actions">
          <BDDropdownContainer noarrow transparent>
            <Dropdown.Toggle as={Button} className="py-0 px-1 rounded-2" id="chatbot-sidebar-item-action-dropdown">
              <Icon icon={MoreHoriz} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onSetFavorite({ id, isFavorite: !isFavorite })}>
                <Icon icon={Star} className="me-2" color={isFavorite ? 'primary' : 'default'} />
                {isFavorite ? 'Unstar' : 'Star'}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onEditClick(msgGroup)}>
                <Icon className="me-2" icon={Edit} />
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onDeleteClick(msgGroup)}>
                <Icon className="me-2" icon={Delete} color="danger" />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </BDDropdownContainer>
        </div>
      )}
    </div>
  );
}

export default ChatbotSidebarItemBase;
