import { ITenant, IUserContext } from '../Types';
import { callProtectedMainAPI, getAuthEndpoint, getMainEndpoint } from '../Utils/call-api';

export const getTenants = async (): Promise<ITenant[]> => {
  const endpoint = getMainEndpoint('/tenants/users/tenants');
  const response = await callProtectedMainAPI<ITenant[]>(endpoint);
  return response;
};

export const createTenant = async (values: Record<string, any>): Promise<IUserContext> => {
  const endpoint = getAuthEndpoint('/create-tenant', 'POST');
  const response = await callProtectedMainAPI<IUserContext>(endpoint, { ...values });
  return response;
};

export const switchTenant = async (tenantId: string): Promise<IUserContext> => {
  const endpoint = getAuthEndpoint('/switch', 'PATCH');
  const response = await callProtectedMainAPI<IUserContext>(endpoint, { tenantId });
  return response;
};
