import { getFieldLabel } from '../DataVisualization.service';
import ResponsiveContainer from '../../ResponsiveContainer/ResponsiveContainer';
import DataTable, { IDataTableColumn } from '../../DataTable/DataTable';

export interface IDataVizDataTable {
  data: Record<string, any>[];
}

function DataVizDataTable({ data }:IDataVizDataTable): JSX.Element | null {
  const fields: IDataTableColumn[] | undefined = data.length ? Object.keys(data[0]).map((k) => ({
    id: k,
    label: getFieldLabel(k),
  })) : undefined;

  if (!fields) {
    return null;
  }

  return (
    <ResponsiveContainer>
      {({ width, height }) => (
        <DataTable
          width={width}
          height={height}
          columns={fields}
          data={data}
        />
      )}
    </ResponsiveContainer>
  );
}

export default DataVizDataTable;
