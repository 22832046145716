import * as yup from 'yup';
import { IMetadataField, IMetadataFieldType } from '../../Utils/metadata';
import BDFormInput from './BDFormInput/BDFormInput';
import BDFormSelect from './BDFormSelect/BDFormSelect';
import BDFormField from './BDFormField';
import BDTimePresetInput from './BDTimePresetInput/BDTimePresetInput';
import { TimePresetEnum, getTimePresetValueByTag } from './BDTimePresetInput/BDTimePreset.service';

const getDefaultValue = (fieldType: IMetadataFieldType): any => {
  if (fieldType.default) {
    return fieldType.default;
  }

  switch (fieldType.value) {
    case 'NUMBER':
      return 0;
    case 'ENUM':
      return fieldType.options?.[0].id ?? '';
    case 'TIME_PRESET':
      return getTimePresetValueByTag(TimePresetEnum.CUSTOM);
    default:
      return '';
  }
};

export const generateInitialValues = (fields: IMetadataField[]): Record<string, any> => fields.reduce((acc: Record<string, any>, curr) => {
  acc[curr.id] = getDefaultValue(curr.type);
  return acc;
}, {});

const getCommonInputType = (type: IMetadataFieldType): string => {
  switch (type.value) {
    case 'EMAIL':
      return 'email';
    case 'PASSWORD':
      return 'password';
    case 'NUMBER':
      return 'number';
    case 'DATE':
      return 'date';
    case 'DATETIME':
      return 'datetime-local';
    default:
      return 'text';
  }
};

export const generateFormFields = (
  {
    fields, values, errors, isEditing, loading
  }: {
  fields: IMetadataField[],
  values: Record<string, any>,
  errors?: Record<string, any>,
  isEditing?: boolean,
  loading?: boolean,
  inputsWidthAuto?: boolean
}
): any => fields.map((field) => {
  if (
    field.type.value === 'ID'
    || (field.noneditable === 'hide' && isEditing)
    || (field.noneditable === 'static' && isEditing)
  ) {
    return null;
  }

  const { type } = field;
  // special types
  if (type.value === 'ENUM' && type.options) {
    return (
      <BDFormSelect
        selectClassName="py-2"
        key={field.id}
        name={field.id}
        label={field.label}
        value={values[field.id]}
        options={type.options.map((r) => ({ id: r.id, label: r.label }))}
        required={field.required}
        error={errors?.[field.id]}
        hideError={!errors}
        disabled={field.noneditable === 'static' ? true : (isEditing && !!field.noneditable) || loading}
      />
    );
  } if (type.value === 'TIME_PRESET') {
    return (
      <BDFormField
        key={field.id}
        name={field.id}
        label={field.label}
        value={values[field.id]}
        required={field.required}
        error={errors?.[field.id]}
        hideError={!errors}
        component={BDTimePresetInput}
        disabled={field.noneditable === 'static' ? true : (isEditing && !!field.noneditable) || loading}
      />
    );
  }

  // common types
  return (
    <BDFormInput
      inputClassName="py-2"
      key={field.id}
      name={field.id}
      label={field.label}
      type={getCommonInputType(type)}
      required={field.required}
      error={errors?.[field.id]}
      hideError={!errors}
      disabled={field.noneditable === 'static' ? true : (isEditing && !!field.noneditable) || loading}
    />
  );
});

const getFieldSchema = (field: IMetadataField, isEditing?: boolean): any => {
  const { required, noneditable, type } = field;

  let schema;
  if (type.value === 'EMAIL') {
    schema = yup.string().email();
  }
  else if (type.value === 'DATE') {
    schema = yup.date();
  }
  else if (type.value === 'TIME_PRESET') {
    schema = yup.object({
      tag: yup.string().required(),
      start: yup.string().required(),
      end: yup.string().required()
    });
  }
  else if (type.value === 'NUMBER') {
    schema = yup.number();
  }
  else { // 'STRING' || 'PASSWORD' || 'ENUM'
    schema = yup.string();
  }

  return noneditable === 'static' && isEditing ? schema : required ? schema?.required() : schema;
};

export const generateFormSchema = (
  fields: IMetadataField[],
  isEditing?: boolean
): any => yup.object(fields.reduce((acc: Record<string, any>, curr) => {
  acc[curr.id] = getFieldSchema(curr, isEditing);
  return acc;
}, {}));
