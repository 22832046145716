import styled from 'styled-components';

const ChatbotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  overflow: hidden;
  
  width: 100%;
  height: 100%;
`;
ChatbotContainer.displayName = 'ChatbotForm';

export default ChatbotContainer;
