import { Suspense } from 'react';
import {
  Route, Routes, Navigate
} from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader.style';
import Users from './Users/Users';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../../Components/HOC/withProtectedPageWrapper';
import SettingsSidebar from './SettingsSidebar';
import ErrorPanel from '../../../Components/ErrorPanel/ErrorPanel.style';
import BDContainer from '../../../Components/BDContainer/BDContainer';
import Billings from './Billings/Billings';

export interface ISettingsPage extends IProtectedPageWrapper {
  className?: string;
}

function Settings({ userContext }: ISettingsPage): JSX.Element {
  return (
    <BDContainer direction="row">
      <SettingsSidebar userContext={userContext} />
      <BDContainer>
        <Suspense fallback={Loader}>
          <Routes>
            <Route key="users" path="users" element={<Users />} />
            <Route key="roles" path="roles" element={<ErrorPanel message="COMING SOON" />} />
            <Route key="billings" path="billings" element={<Billings />} />
            <Route key="*" path="*" element={<Navigate to="/settings/users" />} />
          </Routes>
        </Suspense>
      </BDContainer>
    </BDContainer>
  );
}

export default withProtectedPageWrapper(Settings);
