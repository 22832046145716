import { Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  useNavigate, useLocation, Routes, Route
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { IAppState } from '../Store';
import storageService from '../Utils/storage-service';
import { selectTheme } from '../Store/Reducers/Layout/ThemeSelector';
import Loader from '../Components/Loader/Loader.style';
import Auth from '../Pages/Auth/Auth.style';
import Page404 from '../Pages/404/Page404.style';
import Home from '../Pages/Home/Home.style';
import { selectUserContext } from '../Store/Reducers/UserContext/SessionSelector';
import useAction from '../Store/utils/useAction';
import { setSession } from '../Store/Reducers/UserContext/Session';

function App(): JSX.Element {
  const didmount = useRef(false);
  const sessionChecked = useRef(false);
  const theme = useSelector(selectTheme);
  const userContext = useSelector<IAppState>(selectUserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const setSessionState = useAction(setSession);
  const storage = storageService('local');

  useEffect(() => {
    // ------------------------------------------------------------------------------
    // to make sure this effect only triggered once
    // react strict mode trigger effect with empty deps twice, but only on dev mode
    if (didmount.current) {
      return;
    }
    didmount.current = true;
    // ------------------------------------------------------------------------------

    if (!userContext) {
      try {
        const sessionRaw = storage.getItem('session');
        const session = sessionRaw && JSON.parse(sessionRaw);
        if (!session && !location.pathname.includes('auth')) {
          navigate('/auth/signin');
        } else if (session) {
          setSessionState(session);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log(err.message);
        toast.error(err.message);
      }
    }

    sessionChecked.current = true;
  }, [ ]);

  if (!didmount || !sessionChecked) {
    return (<Loader />);
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Suspense fallback={Loader}>
          <Routes>
            <Route key="auth" path="auth/*" element={<Auth />} />
            <Route key="404" path="404" element={<Page404 fullscreen />} />
            <Route key="home" path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;
