import { useEffect, useState } from 'react';
import { IUseApiResponse, IUseFetchArgs } from './useFetch';
import { HTTPStatusCodes, callProtectedMainAPI } from '../Utils/call-api';

export function useFetchMainAPI<T>({ endpoint, body, navigate }: IUseFetchArgs, dontCallOnStart?: boolean): IUseApiResponse<T> {
  const [ data, setData ] = useState<T | null>(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  const fetchData = (newReqBody?: any): void => {
    setLoading(true);
    setError(null);

    callProtectedMainAPI<T>({ ...endpoint }, newReqBody ?? body)
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        setError(err);
        if (err.status === HTTPStatusCodes.Unauthorized) {
          navigate?.('/auth/signin');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dontCallOnStart) {
      return;
    }

    fetchData();
  }, [ endpoint ]);

  return {
    data, setData, loading, setLoading, error, refetch: fetchData,
  };
}
