/* eslint-disable react/button-has-type */
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { IUserContext } from '../../Types';
import SlackVerified from './SlackVerified';
import SlackVerifyForm from './SlackVerifyForm';
import SlackVerifyButton from './SlackVerifyButton';

interface ISlackVerify {
  userContext?: IUserContext | null;
  loading: boolean;
  onSubmit: (values: Record<string, any>, redirectToHome?: boolean) => void;
}

function SlackVerify({ userContext, loading, onSubmit, }: ISlackVerify): JSX.Element {
  const location = useLocation();
  const queryString = qs.parse(decodeURIComponent(location.search), { ignoreQueryPrefix: true });
  const slackUserId = queryString.u as string;
  const channel = queryString.c as string;

  if (userContext && userContext.user.slackId && userContext.user.slackId === slackUserId) {
    return (
      <SlackVerified
        userContext={userContext}
        slackUserId={slackUserId}
        channel={channel}
        onSubmit={onSubmit}
      />
    );
  }

  if (!slackUserId || !channel) {
    return (
      <Navigate to="/auth/signin" />
    );
  }

  if (!userContext) {
    return (
      <SlackVerifyForm
        slackUserId={slackUserId}
        channel={channel}
        loading={loading}
        onSubmit={onSubmit}
      />
    );
  }

  if (!userContext.user.slackId) {
    return (
      <SlackVerifyButton
        userContext={userContext}
        slackUserId={slackUserId}
        channel={channel}
        loading={loading}
        onSubmit={onSubmit}
      />
    );
  }

  if (userContext.user.slackId !== slackUserId) {
    return (
      <div className="auth-form-container pb-4 text-white">
        <h3 className="mb-4">
          Error
        </h3>
        Unexpected error occured. Looks like your slack account already verified but in different workspace.
        Please contact the admin.
      </div>
    );
  }

  return (
    <Navigate to="auth/slack/verified" />
  );
}

export default SlackVerify;
