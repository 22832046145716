import { ReferenceLineProps } from 'recharts';

interface IReferenceLineLabel extends ReferenceLineProps {
  value: any;
  bg: any;
  content?: Record<string, any>
}

function ReferenceLineLabel(args: IReferenceLineLabel): JSX.Element | null {
  const {
    value, bg, content, viewBox
  } = args;
  if (!content || !viewBox || viewBox.width === undefined || viewBox.x === undefined || viewBox.y === undefined) {
    return null;
  }

  const valLength = value.toString().length;
  const rectWidth = 24 + ((valLength <= 2 ? 0 : valLength - 2) * 8);

  return (
    <>
      <rect
        x={viewBox.x - (rectWidth / 2)}
        y={viewBox.y - 26}
        rx="4"
        ry="4"
        width={rectWidth}
        height={20}
        fill={bg}
      />
      <text
        fill="white"
        x={viewBox.x - (valLength * 2.5) - valLength}
        y={viewBox.y - 12}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    </>
  );
}

export default ReferenceLineLabel;
