import {
  Delete, Edit, MoreHoriz, Search, Star
} from '@mui/icons-material';
import { Dropdown } from 'react-bootstrap';
import { withTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ICreateEditModalState, IMessageGroup, deleteMessageGroup, msgGroupFormFields
} from '../Chatbot.service';
import { ITheme } from '../../../../Themes/Themes';
import Icon from '../../../../Components/Icon/Icon';
import BDButton from '../../../../Components/BDButton/BDButton';
import BDDropdownContainer from '../../../../Components/BDDropdown/BDDropdownContainer';
import BDHeader, { BDHeaderLeft, BDHeaderRight } from '../../../../Components/BDHeader/BDHeader';
import useAction from '../../../../Store/utils/useAction';
import {
  getMsgGroupsAction, editMsgGroupAction, setMsgGroupIsFavorite
} from '../../../../Store/Reducers/MsgGroups/MsgGroupsActions';
import { swal } from '../../../../Utils/swal';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import CreateEditModal from '../../../../Components/BDForm/BDCreateEditModal/BDCreateEditModal';
import { selectMsgGroupLoading, selectMsgGroups } from '../../../../Store/Reducers/MsgGroups/MsgGroupsSelector';

export interface IChatbotHeader {
  msgGroup: IMessageGroup;
  theme: ITheme;
}

function ChatbotHeaderBase({ msgGroup }: IChatbotHeader): JSX.Element {
  const navigate = useNavigate();
  const [ group, setGroup ] = useState({ ...msgGroup });
  const msgGroups = useSelector(selectMsgGroups);
  const getMsgGroups = useAction(getMsgGroupsAction);
  const setFavorite = useAction(setMsgGroupIsFavorite);
  const submitEdit = useAction(editMsgGroupAction);
  const msgGroupLoading = useSelector(selectMsgGroupLoading);
  const [ createEditMsgGroupModal, setCreateEditMsgGroupModal ] = useState<ICreateEditModalState>({ show: false });

  useEffect(() => {
    const currentMsgGroup = msgGroups.find((g) => g.id === group.id);
    if (!currentMsgGroup) {
      return;
    }
    setGroup(currentMsgGroup);
  }, [ msgGroups ]);

  useEffect(() => {
    setGroup(msgGroup);
  }, [ msgGroup ]);

  const submitEditMsgGroup = useCallback((values: Record<string, any>) => {
    submitEdit({ ...values });
    closeCreateEditMsgGroupModal();
  }, [ group ]);

  const submitDeleteMsgGroup = useCallback(callApiWrapper(async (msgGroupId: string): Promise<string> => {
    await deleteMessageGroup(msgGroupId);
    navigate('/');
    getMsgGroups();
    return msgGroupId;
  }, undefined, navigate), [ group ]);

  const showCreateEditModal = (): void => {
    setCreateEditMsgGroupModal({
      show: true,
      isEdit: !!group,
      values: {
        id: group.id,
        name: group.name
      }
    });
  };

  const closeCreateEditMsgGroupModal = (): void => setCreateEditMsgGroupModal({ show: false });

  const preDeleteMsgGroup = (): void => {
    swal({
      title: 'Are you sure?',
      onConfirmCallback: () => submitDeleteMsgGroup(group.id)
    });
  };

  return (
    <>
      <BDHeader className="px-5 py-3">
        <BDHeaderLeft>
          {group.isFavorite && <Icon icon={Star} className="me-2" color="primary" />}
          <h4 className="my-0 me-2">{group.name}</h4>
          <BDButton transparent onClick={showCreateEditModal}>
            <Icon icon={Edit} />
          </BDButton>
        </BDHeaderLeft>
        <BDHeaderRight>
          <BDButton
            className="me-3"
            variant={group.isFavorite ? 'primary' : 'outline-primary'}
            size="sm"
            onClick={(): void => setFavorite({
              id: group.id,
              isFavorite: !group.isFavorite
            })}
          >
            <Icon icon={Star} className="me-1" />
            {' '}
            {group.isFavorite ? 'Unstar' : 'Star'}
          </BDButton>

          <BDDropdownContainer noarrow>
            <Dropdown.Toggle as={BDButton} transparent id="acc-dropdown">
              <Icon icon={MoreHoriz} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <Icon icon={Search} className="me-2" />
                Search
              </Dropdown.Item>
              <Dropdown.Item onClick={preDeleteMsgGroup}>
                <Icon className="me-2" icon={Delete} color="danger" />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </BDDropdownContainer>
        </BDHeaderRight>
      </BDHeader>

      <CreateEditModal
        active={createEditMsgGroupModal.show}
        loading={msgGroupLoading}
        onClose={closeCreateEditMsgGroupModal}
        title={createEditMsgGroupModal.isEdit ? 'Edit section' : 'Create section'}
        fields={msgGroupFormFields}
        initialValues={{ ...createEditMsgGroupModal.values }}
        isEditing={createEditMsgGroupModal.isEdit}
        onSubmit={submitEditMsgGroup}
      />
    </>
  );
}

export default withTheme(ChatbotHeaderBase);
