import styled from 'styled-components';

interface SeparatorProps {
  className?: string;
}

const Container = styled.div`
  width: 100%;
`;

function Separator({ className }: SeparatorProps): JSX.Element {
  return (
    <Container className={className}>
      <div style={{ borderBottom: '1px solid #00000022' }} />
    </Container>
  );
}

export default Separator;
