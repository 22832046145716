import { ArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import BDHeader, { BDHeaderCenter, BDHeaderLeft, BDHeaderRight } from '../../Components/BDHeader/BDHeader';
import BDButton from '../../Components/BDButton/BDButton';
import Icon from '../../Components/Icon/Icon';
import BDText from '../../Components/BDText/BDText';

export interface IExplorationHeader {
  explorationName: string;
  previewMode?: boolean;
  onSaveClick(): void;
}

function ExplorationHeader({
  explorationName,
  previewMode,
  onSaveClick,
}: IExplorationHeader): JSX.Element {
  const navigate = useNavigate();

  return (
    <BDHeader className="px-4 py-3">
      <BDHeaderLeft>
        <BDButton
          className="d-flex-row-center-center ps-1 pe-0  me-2"
          variant="outline-primary"
          size="sm"
          onClick={() => navigate('/')}
        >
          <Icon icon={ArrowLeft} className="me-1" />
        </BDButton>
      </BDHeaderLeft>
      <BDHeaderCenter>
        <BDText textbold textsize="md">
          {explorationName}
          {previewMode && (
            <>
              {' '}
              <span className="text-danger">[PREVIEW]</span>
            </>
          )}
        </BDText>
      </BDHeaderCenter>
      <BDHeaderRight>
        <BDButton variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </BDButton>
      </BDHeaderRight>
    </BDHeader>
  );
}

export default ExplorationHeader;
