import { createSlice } from '@reduxjs/toolkit';
import { IMessageGroup } from '../../../Pages/Home/Chatbot/Chatbot.service';

export interface IMsgGroupsState {
  msgGroups: IMessageGroup[];
  loading: boolean;
  error?: string;
}
const initialState: IMsgGroupsState = {
  msgGroups: [],
  loading: false
};

const msgGroupsSlice = createSlice({
  name: 'msgGroups',
  initialState,
  reducers: {
    setMsgGroups(state, action) {
      state.msgGroups = action.payload;
    },
    setMsgGroupsLoading(state, action) {
      state.loading = action.payload;
    },
    setMsgGroupsError(state, action) {
      state.error = action.payload;
    }
  }
});

export const { setMsgGroups, setMsgGroupsLoading, setMsgGroupsError } = msgGroupsSlice.actions;

export default msgGroupsSlice.reducer;
