import {
  useEffect, useState, useCallback, useRef
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUserContext } from '../../Types';
import Sidebar from '../../Components/Sidebar/Sidebar.style';
import { SidebarBody } from '../../Components/Sidebar/Sidebar';
import BDSearchInput from '../../Components/BDTextInput/BDSearchInput';
import OverflowContainer from '../../Components/OverflowContainer/OverflowContainer';
import EmptyPanel from '../../Components/EmptyPanel/EmptyPanel.style';
import ExploreSidebarItem from './ExploraSidebarItem/ExploreSidebarItem.style';
import { IDataExploration, updateDataExploration } from './Explore.service';
import callApiWrapper from '../../Utils/call-api-wrapper';

export type IDataExplorationItem = Omit<IDataExploration, 'data' | 'prompt' | 'variables'>

export interface IExploreSidebar {
  userContext: IUserContext;
  explorations: IDataExplorationItem[];
  refreshExplorations(): void;
  onEditExplorationNameClick(exploration: IDataExplorationItem): void;
  onDeleteExplorationClick(exploration: IDataExplorationItem): void;
}

function ExploreSidebar({
  userContext,
  explorations,
  refreshExplorations,
  onEditExplorationNameClick,
  onDeleteExplorationClick,
}: IExploreSidebar): JSX.Element {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const [ filteredExplorations, setFilteredExploration ] = useState<IDataExplorationItem[]>([]);

  const params = useParams();
  const paths = params['*']?.split('/');
  const explorationId = paths?.[paths.length - 1];

  useEffect(() => {
    if (!searchRef.current) {
      return;
    }

    filterSavedExploration(searchRef.current.value);
  }, [ explorations ]);

  const setFavorite = useCallback(callApiWrapper<IDataExplorationItem>(async (expId: string, favorite: boolean) => {
    await updateDataExploration({
      id: expId,
      isFavorite: favorite,
    });
    refreshExplorations();
  }, undefined, navigate), [ explorations ]);

  const filterSavedExploration = (byValue: string): void => {
    if (!explorations) {
      return;
    }
    if (byValue === '') {
      setFilteredExploration([ ...explorations ]);
      return;
    }

    const currentGroups = explorations.filter((g) => g.name.toLocaleLowerCase().includes(byValue));
    setFilteredExploration(currentGroups);
  };

  return (
    <Sidebar userContext={userContext} defaultFooter>
      <SidebarBody>
        <BDSearchInput
          ref={searchRef}
          className="mb-4"
          label="Saved explorations"
          submitsearch={filterSavedExploration}
          clearBtn
          submitOnClear
        />

        <OverflowContainer containerClassName="p-0">
          {!explorations || !explorations.length ? (
            <EmptyPanel message="No saved explorations" textsize="sm" />
          ) : filteredExplorations.sort((e) => (e.isFavorite ? -1 : 1)).map((exploration) => (
            <ExploreSidebarItem
              key={exploration.id}
              exploration={exploration}
              active={exploration.id === explorationId}
              onClick={(e) => navigate(`/explore/exploration/${e.id}`)}
              onSetFavorite={setFavorite}
              onEditClick={onEditExplorationNameClick}
              onDeleteClick={onDeleteExplorationClick}
            />
          ))}
        </OverflowContainer>
      </SidebarBody>
    </Sidebar>
  );
}

export default ExploreSidebar;
