import { createAction, createSlice } from '@reduxjs/toolkit';
import { ICreateExplorationPayload, IDataExploration } from '../../../Pages/Explore/Explore.service';
import { IReducerAction } from '../../../Types';

export interface IExplorationsState {
  explorations: IDataExploration[];
  loading: false;
  error?: string;
}

const initialState: IExplorationsState = {
  explorations: [],
  loading: false
};

const explorationsSlice = createSlice({
  name: 'msgGroups',
  initialState,
  reducers: {
    setExplorations(state, action) {
      state.explorations = action.payload;
    },
    setExplorationsLoading(state, action) {
      state.loading = action.payload;
    },
    setExplorationsError(state, action) {
      state.error = action.payload;
    }
  }
});

export const {
  setExplorations,
  setExplorationsLoading,
  setExplorationsError,
} = explorationsSlice.actions;

export const getExplorations = createAction('getExplorations');

export type ICreateExplorationAction = IReducerAction<ICreateExplorationPayload>;
export const createExploration = createAction('saveExploration');

export type IUpdateExplorationAction = IReducerAction<Record<string, any>>;
export const updateExploration = createAction('updateExploration');

export const saveInsightToExploration = createAction('saveInsightToExploration');

export const deleteExploration = createAction('deleteExplorations');

export type ISetExplorationIsFavoriteAction = IReducerAction<{ explorationsId: string, isFavorite: boolean}>;
export const setExplorationIsFavorite = createAction('setExplorationIsFavorite');

export default explorationsSlice.reducer;
