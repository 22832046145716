import BDButton from '../../../../Components/BDButton/BDButton';
import BDContainer from '../../../../Components/BDContainer/BDContainer';
import BDText from '../../../../Components/BDText/BDText';
import withProtectedPageWrapper, { IProtectedPageWrapper } from '../../../../Components/HOC/withProtectedPageWrapper';

export type IBillings = IProtectedPageWrapper

function Billings({ navigate }: IBillings): JSX.Element {
  return (
    <BDContainer justify="center" align="center">
      <BDButton size="lg" onClick={() => navigate('/create-tenant')}><BDText textbold>Create new tenant</BDText></BDButton>
    </BDContainer>
  );
}

export default withProtectedPageWrapper(Billings);
