import { ResponsiveSunburst } from '@nivo/sunburst';
import { memo, useState } from 'react';
import styled from 'styled-components';
import SunburstChartLegend from './SunburstLegend';
import { IDataVisualization } from '../../DataVisualization';
import { ISunburstChartData } from './SunburstChart.service';
import SunburstChartTooltip from './SunburstChartTooltip';
import BDButton from '../../../BDButton/BDButton';
import BDText from '../../../BDText/BDText';

export type ISunburstChartDataValue = [ISunburstChartData, Record<string, any>]

export interface ISunburstChart extends IDataVisualization {
  data: ISunburstChartDataValue;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;

  margin-bottom: 1rem;
  padding: 0.6rem;

  .chart-container {
    width: 100%;
    height: 100%;
    position: relative;

    .root-text {
      z-index: 100;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .reset-btn-container {
      z-index: 100;
      position: absolute;
      left: 24px;
      top: 24px;
    }
  }
`;

function SunburstChart({ data: [ sunburstData, sunburstLegend ], config }: ISunburstChart): JSX.Element {
  const [ data, setData ] = useState({ ...sunburstData });
  const [ onDetails, setOnDetails ] = useState(false);

  const onResetClick = (): void => {
    setData({
      id: 'mock-empty',
      name: 'mock-empty',
      color: '#000000',
      childrenTotalValue: 0,
      value: 0
    });
    setTimeout(() => {
      setData({ ...sunburstData });
    }, 1500);
    setOnDetails(false);
  };

  const onClick = (clickedData: any): void => {
    if (!clickedData.data.children) {
      return;
    }
    setData({
      id: 'mock-empty',
      name: 'mock-empty',
      color: '#000000',
      childrenTotalValue: 0,
      value: 0
    });
    setOnDetails(false);
    setTimeout(() => {
      setData(clickedData.data);
      setOnDetails(true);
    }, 1500);
  };

  return (
    <Container>
      <div className="chart-container">
        {onDetails && (
          <div className="root-text" style={{ color: data.color }}>
            <BDText className="m-0" textbold>
              {data.name}
            </BDText>
            <BDText className="m-0" textbold textsize="md">
              {data.value}
            </BDText>
          </div>
        )}

        {onDetails && (
          <BDButton className="reset-btn-container" variant="secondary" size="sm" onClick={onResetClick}>
            Reset
          </BDButton>
        )}

        <ResponsiveSunburst
          id="name"
          value="valueRef"
          data={data}
          arcLabel={(e: any) => e.data.value}
          enableArcLabels={true}
          arcLabelsSkipAngle={4}
          inheritColorFromParent={false}
          colors={(e) => e.data.color}
          cornerRadius={2}
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip={(props: any) => <SunburstChartTooltip {...props} />}
          animate={true}
          motionConfig="gentle"
          transitionMode="pushIn"
          onClick={onClick}
        />
      </div>
      {config.legend && <SunburstChartLegend fields={sunburstLegend} />}
    </Container>
  );
}

export default memo(SunburstChart);
