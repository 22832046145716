import BDText, { IBDText } from '../BDText/BDText';

interface ErrorPanelProps extends IBDText {
  message?: string;
  className?: string;
}

export function EmptyPanelBase({ message, className, ...props }: ErrorPanelProps): JSX.Element {
  return (
    <div className={className}>
      <BDText textsize="md" textbold {...props as any}>{message ?? 'No data'}</BDText>
    </div>
  );
}
