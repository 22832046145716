/* eslint-disable react/button-has-type */
import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';
import * as yup from 'yup';
import validateFormValues from '../../Utils/validate-schema';
import Loader from '../../Components/Loader/Loader.style';

const loginValidationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

interface ISignIn {
  loading: boolean;
  onSubmit: (values: Record<string, any>) => void;
  onSigninGoogle: () => void;
}

function SignIn({ loading, onSubmit, onSigninGoogle }: ISignIn): JSX.Element {
  return (
    <Formik
      initialValues={{}}
      validate={validateFormValues(loginValidationSchema)}
      onSubmit={onSubmit}
    >
      <Form className="auth-form-container">
        <h3>Sign In Here</h3>

        <div className="auth-form-inputs">
          <label className="mt-4">Email</label>
          <Field type="text" placeholder="Email" name="email" />
          <label className="validation-error mt-0"><ErrorMessage name="email" /></label>

          <label className="mt-3">Password</label>
          <Field type="password" placeholder="Password" name="password" />
          <label className="validation-error mt-0"><ErrorMessage name="password" /></label>
        </div>

        <button className="submit-button mt-3" type="submit">Sign in</button>

        {/* <label className="auth-form-footer mt-4">
          Don&apos;t have an account?
          {' '}
          <Link to="/auth/signup"><span>Sign Up</span></Link>
        </label> */}

        <p className="mt-4 text-center">Or</p>

        <div className="alternate-signin-container mt-2">
          <button onClick={onSigninGoogle}>
            <img src="/images/google.png" alt="/images/google.png" />
            {' '}
            Sign in with Google
          </button>
        </div>

        <label className="auth-form-footer mt-3">
          {/* <Link to="/register-tenant"><span>Create company account</span></Link> */}
        </label>

        {loading && <Loader />}
      </Form>
    </Formik>
  );
}

export default SignIn;
