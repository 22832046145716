import styled from 'styled-components';
import BDText from '../../../BDText/BDText';
import { ISunburstChartData } from './SunburstChart.service';

export interface ISunburstChartTooltip {
    id: string | number;
    name: string;
    value: number;
    depth: number;
    color: string;
    loc: number;
    percentage: number;
    ancestor: object;
    data: ISunburstChartData;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  background-color: #FFFFFFEE;
  padding: 0.6rem 0.8rem;
  border-radius: 12px;
`;

const Shape = styled.div<{bg: string}>`
  background-color: ${(props) => props.bg}AA;
  height: 20px;
  width: 20px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export default function SunburstChartTooltip({ color, data, value }: ISunburstChartTooltip): JSX.Element {
  return (
    <Container>
      <Shape bg={color} />
      <BDText textcolor="secondary" textsize="xs" textsemibold>
        {data.name}
        :
        {' '}
        {value}
      </BDText>
    </Container>
  );
}
