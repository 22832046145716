import { useMemo } from 'react';
import OverflowContainer from '../../Components/OverflowContainer/OverflowContainer';
import { IInsight } from '../Home/Chatbot/Chatbot.service';
import ExplorerInsightsCard from './ExploreDataViz';

export interface IExplorerContentContainer {
  insights: IInsight[];
  insightLoadings: { [insightId: string]: boolean };
}

function ExploreContent({ insights, insightLoadings }:IExplorerContentContainer): JSX.Element {
  const insightsElement = useMemo(() => insights.map((insight, i) => (
    <ExplorerInsightsCard
      key={i}
      text={insight.text}
      data={insight}
      fullsize={insights.length === 1}
      loading={!!insightLoadings[insight.insightId]}
    />
  )), [ insights ]);

  if (insights.length === 1) {
    return (
      <div className="w-100 h-100 px-4 pb-2">
        {insightsElement}
      </div>
    );
  }
  return (
    <OverflowContainer className="px-4 pb-2">
      {insightsElement}
    </OverflowContainer>
  );
}

export default ExploreContent;
