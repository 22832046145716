import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IUserContext } from '../../../../Types';
import { IUserSummary, getPopularMsgs } from '../Chatbot.service';
import BDButton from '../../../../Components/BDButton/BDButton';
import Loader from '../../../../Components/Loader/Loader.style';
import callApiWrapper from '../../../../Utils/call-api-wrapper';
import { exploreDataExplorations } from '../../../Explore/Explore.service';
import BDText from '../../../../Components/BDText/BDText';
import SavedInsightsCard from './SavedInsightsCard/SavedInsightsCard.style';

export interface IChatbotWelcome {
  userContext: IUserContext;
  onSuggestionClick?(question: string): void;
  className?: string;
}

function ChatbotWelcomeBase({
  userContext, onSuggestionClick, className
}: IChatbotWelcome): JSX.Element {
  const navigate = useNavigate();
  const { user } = userContext;
  const [ summary, setSummary ] = useState<IUserSummary>();
  const [ summaryLoading, setSummaryLoading ] = useState(true);

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = useCallback(callApiWrapper(async () => {
    const [ popularQuestions, lastBookmarks ] = await Promise.all([
      getPopularMsgs(),
      exploreDataExplorations({ pagination: { page: 1, limit: 5 } })
    ]);
    setSummary({
      popularQuestions,
      lastBookmarks: lastBookmarks.data
    });
  }, setSummaryLoading, navigate), []);

  return (
    <div className={classNames(className, 'px-5 py-3')}>
      {(summaryLoading || !summary) && <Loader />}

      <div className="d-flex-row-center-center mb-5">
        <img
          className="me-4"
          src="/images/analyze.png"
          alt="/images/analyze.png"
          style={{
            width: '124px',
            height: '104px',
          }}
        />
        <div className="d-flex-col-center-start">
          <BDText textsize="lg" textbold>
            Hello
            {' '}
            {user.fullname}
            !
          </BDText>
          <BDText>
            Welcome to Butter Data. Feel free to ask me any question about
            your data.
          </BDText>
        </div>
      </div>

      {summary?.popularQuestions?.length ? (
        <div className="suggestions">
          <BDText textbold>Most popular questions:</BDText>
          <div className="suggestions-items">
            {summary.popularQuestions.map((q, i) => (
              <BDButton key={i} className="mb-2 me-2" variant="light" onClick={() => onSuggestionClick?.(q)}>{q}</BDButton>
            ))}
          </div>
        </div>
      ) : null}

      {summary?.lastBookmarks && summary.lastBookmarks.length ? (
        <div className="suggestions">
          <BDText textbold>Your Recently Saved Conversation:</BDText>
          <div className="suggestions-items recent-bookmarks pt-2 pb-3">
            {summary.lastBookmarks.map((m, i) => (
              <SavedInsightsCard key={i} containerClassName="me-3" savedInsights={m} width={600} />
            ))}
          </div>
        </div>
      ) : null}

      <div className="suggestions">
        <BDText textbold>Data that might help you:</BDText>
        <div className="suggestions-items">
          {[
            'How many users added chartlet?',
            'What is the registration rate?'
          ].map((q, i) => (
            <BDButton key={i} className="mb-2 me-2" variant="light" onClick={() => onSuggestionClick?.(q)}>{q}</BDButton>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ChatbotWelcomeBase;
