import styled from 'styled-components';
import ChatbotBase from './Chatbot';

const Chatbot = styled(ChatbotBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;
`;
Chatbot.displayName = 'Chatbot';

export default Chatbot;
