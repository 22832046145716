import { HTMLProps, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../Themes/Themes';
import { SizeBreakpoints } from '../../Types';

export interface IBDText extends HTMLProps<HTMLLabelElement> {
  textcolor?: ThemeColors;
  textsize?: SizeBreakpoints;
  textlight?: boolean;
  textbold?: boolean;
  textsemibold?: boolean;
}

function BDTextBase({ className, children, ...props }: PropsWithChildren<IBDText>): JSX.Element {
  return (
    <label className={className} {...props}>{children}</label>
  );
}

export const TextSizeBreakpointValues: Record<SizeBreakpoints, number> = {
  xxs: 10,
  xs: 14,
  sm: 16,
  md: 22,
  lg: 30,
  xl: 40,
  xxl: 52,
  xxxl: 66
};

const BDText = styled(BDTextBase)<IBDText>`
  cursor: inherit;
  color: ${(props) => (props.textcolor ? props.theme.colors[props.textcolor as ThemeColors] : 'inheret')};
  font-size: ${(props) => (props.textsize ? TextSizeBreakpointValues[props.textsize] : TextSizeBreakpointValues.sm)}px;
  font-weight: ${(props) => (props.textlight ? '300' : props.textsemibold ? '500' : props.textbold ? '700' : '400')};
`;
BDText.displayName = 'BDTextStyled';

export default BDText;
