import styled from 'styled-components';
import { PuffLoader } from 'react-spinners';
import { IInsight } from '../Home/Chatbot/Chatbot.service';
import { getFieldLabel } from '../../Components/DataVisualization/DataVisualization.service';
import ResponsiveContainer from '../../Components/ResponsiveContainer/ResponsiveContainer';
import DataTable, { IDataTableColumn } from '../../Components/DataTable/DataTable';
import BDText from '../../Components/BDText/BDText';
import Loader from '../../Components/Loader/Loader.style';
import ChatbotDataVisualization from '../Home/Chatbot/ChatbotDataVisualization/ChatbotDataVisualization';

export interface IExplorerInsightsCard {
  loading?: boolean;
  text?: string;
  fullsize?: boolean;
  data: IInsight;
}

const Container = styled.div<{fullsize?: boolean}>`
  background-color: #FFFFFFDD;
  width: 100%;
  ${(props) => (props.fullsize ? 'height: 98%;' : '')}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 #00000027;
  position: relative;
`;

function ExplorerInsightsCard({
  loading, text, fullsize, data
}:IExplorerInsightsCard): JSX.Element | null {
  const fields: IDataTableColumn[] | undefined = data.data.length ? Object.keys(data.data[0]).map((k) => ({
    id: k,
    label: getFieldLabel(k),
  })) : undefined;

  if (!fields) {
    return null;
  }

  return (
    <Container className="d-flex-col-start-start p-2 mb-3" fullsize={fullsize}>
      {loading && (
        <Loader
          Component={PuffLoader}
          className="rounded-4 "
          overlay="#FFFFFF66"
          size={70}
        />
      )}

      {text && (
        <BDText className="m-2" textsemibold>{text}</BDText>
      )}

      <ResponsiveContainer>
        {({ width, height }) => {
          if (!data.visualization) {
            return (
              <DataTable
                width={width}
                height={height}
                columns={fields}
                data={data.data}
              />
            );
          }
          return (
            <ChatbotDataVisualization data={data.data} config={data.visualization} height={fullsize ? height : 302} />
          );
        }}
      </ResponsiveContainer>
    </Container>
  );
}

export default ExplorerInsightsCard;
