import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Explore from '../Explore/Explore';
import Chatbot from './Chatbot/Chatbot.style';
import Settings from './Settings/Settings';
import BDContainer from '../../Components/BDContainer/BDContainer';
import Loader from '../../Components/Loader/Loader.style';
import CreateTenantPage from './CreateTenant/CreateTenant.style';

export interface IHomeBase {
  className?: string;
}

function HomeBase({ className }: IHomeBase): JSX.Element {
  return (
    <div className={className}>
      <BDContainer>
        <Suspense fallback={Loader}>
          <Routes>
            <Route key="settings" path="settings/*" element={<Settings />} />
            <Route key="explore" path="explore/*" element={<Explore />} />
            <Route key="create-tenant" path="create-tenant/*" element={<CreateTenantPage />} />
            <Route key="home" path="*" element={<Chatbot />} />
          </Routes>
        </Suspense>
      </BDContainer>
    </div>
  );
}

export default HomeBase;
