import { forwardRef, LegacyRef } from 'react';
import { Field } from 'formik';
import BDTextInput, { IBDTextInput } from '../../BDTextInput/BDTextInput';

export interface IBDFormInput extends Omit<IBDTextInput, 'ref'> {
  hideError?: boolean;
}

function BDFormInput({
  name, error, hideError, ...props
}: IBDFormInput, ref?: LegacyRef<HTMLInputElement>): JSX.Element {
  return (
    <Field name={name}>
      {({ field }: any) => (
        <BDTextInput {...props} {...field} ref={ref} className="mb-1 me-2" error={!hideError ? error ?? ' ' : undefined} />
      )}
    </Field>
  );
}

export default forwardRef(BDFormInput);
